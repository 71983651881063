import SendBird from 'sendbird';
import { QUERY_LIMIT, getSendbirdAppId } from './Credentials';
import { isNull } from './utils';

let instance = null;

class SendBirdAction {
  userQuery: any;

  openChannelQuery: any;

  previousMessageQuery: any;

  participantQuery: any;

  blockedQuery: any;

  userDetailQuery: any;

  sb: SendBird.SendBirdInstance;

  groupChannelQuery: any;

  hidePreviousMessages: boolean;

  constructor() {
    if (instance) {
      return instance;
    }
    const appId = getSendbirdAppId();
    this.sb = new SendBird({
      appId,
    });
    this.userQuery = null;
    this.openChannelQuery = null;
    this.openChannelQuery = null;
    this.previousMessageQuery = null;
    this.participantQuery = null;
    this.blockedQuery = null;
    this.userDetailQuery = null;
    instance = this;

    this.hidePreviousMessages = false;
  }

  /**
   * Connect
   */
  connect = (userId: string, nickname: string, profileUrl: string) => {
    return new Promise((resolve, reject) => {
      const sb = SendBird.getInstance();
      sb.connect(userId, (user, error) => {
        if (error) {
          reject(error);
        } else {
          sb.updateCurrentUserInfo(
            decodeURIComponent(nickname),
            profileUrl === undefined ? '' : profileUrl,
            (_user, _error) => {
              error ? reject(_error) : resolve(_user);
            },
          )
            .then(() => console.log('Sendbird updateUserInfo response fetched'))
            .catch(e => console.log(e));
        }
      })
        .then(r => console.log('Sendbird connect response', r))
        .catch(e => console.log(e));
    });
  };

  disconnect = () => {
    return new Promise<void>((resolve, reject) => {
      this.sb
        .disconnect((response, error) => {
          error ? reject(error) : resolve();
        })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  };

  /**
   * User
   */
  getCurrentUser = () => {
    return this.sb.currentUser;
  };

  getUsersListByMetaData = (key: any, value: any) => {
    // if (isInit || isNull(this.userQuery)) {
    this.userQuery = this.sb.createApplicationUserListQuery();
    this.userQuery.metaDataKeyFilter = key;
    this.userQuery.metaDataValuesFilter = [value];
    this.userQuery.limit = QUERY_LIMIT;
    // }

    return new Promise((resolve, reject) => {
      if (this.userQuery.hasNext && !this.userQuery.isLoading) {
        this.userQuery.next((list, error) => {
          error ? reject(error) : resolve(list);
        });
      } else {
        resolve([]);
      }
    });
  };

  getUserDetails = (userIds = [], isInit = false) => {
    if (isInit || isNull(this.userDetailQuery)) {
      this.userDetailQuery = this.sb.createApplicationUserListQuery();
      this.userDetailQuery.userIdsFilter = [userIds];
      this.userDetailQuery.limit = QUERY_LIMIT;
    }
    return new Promise((resolve, reject) => {
      if (this.userDetailQuery.hasNext && !this.userDetailQuery.isLoading) {
        this.userDetailQuery.next((list: unknown, error: any) => {
          error ? reject(error) : resolve(list);
        });
      } else {
        resolve([]);
      }
    });
  };

  getUserList = (isInit = false) => {
    if (isInit || isNull(this.userQuery)) {
      this.userQuery = this.sb.createApplicationUserListQuery();
      this.userQuery.limit = QUERY_LIMIT;
    }
    return new Promise((resolve, reject) => {
      if (this.userQuery.hasNext && !this.userQuery.isLoading) {
        this.userQuery.next((list, error) => {
          error ? reject(error) : resolve(list);
        });
      } else {
        resolve([]);
      }
    });
  };

  isCurrentUser = user => {
    return user.userId === this.sb.currentUser.userId;
  };

  getBlockedList = (isInit = false) => {
    if (isInit || isNull(this.blockedQuery)) {
      this.blockedQuery = this.sb.createBlockedUserListQuery();
      this.blockedQuery.limit = 30;
    }
    return new Promise((resolve, reject) => {
      if (this.blockedQuery.hasNext && !this.blockedQuery.isLoading) {
        this.blockedQuery.next((blockedList, error) => {
          error ? reject(error) : resolve(blockedList);
        });
      } else {
        resolve([]);
      }
    });
  };

  blockUser = (user: SendBird.User, isBlock = true) => {
    return new Promise<void>((resolve, reject) => {
      if (isBlock) {
        this.sb
          .blockUser(user, (response, error) => {
            error ? reject(error) : resolve();
          })
          .then(r => console.log(r))
          .catch(e => console.log(e));
      } else {
        this.sb
          .unblockUser(user, (response, error) => {
            error ? reject(error) : resolve();
          })
          .then(r => console.log(r))
          .catch(e => console.log(e));
      }
    });
  };

  /**
   * Channel
   */
  getChannel = (channelUrl: string, isOpenChannel = true) => {
    return new Promise((resolve, reject) => {
      if (isOpenChannel) {
        SendBirdAction.getInstance()
          .sb.OpenChannel.getChannel(channelUrl, (openChannel, error) => {
            error ? reject(error) : resolve(openChannel);
          })
          .then(r => console.log(r))
          .catch(e => console.log(e));
      } else {
        SendBirdAction.getInstance()
          .sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
            error ? reject(error) : resolve(groupChannel);
          })
          .catch(e => console.log(e));
      }
    });
  };

  /**
   *
   * Open Channel
   */
  getOpenChannelList = (isInit = false, urlKeyword = '') => {
    if (isInit || isNull(this.openChannelQuery)) {
      this.openChannelQuery = this.sb.OpenChannel.createOpenChannelListQuery();
      this.openChannelQuery.limit = 20;
      this.openChannelQuery.urlKeyword = urlKeyword;
    }

    return new Promise((resolve, reject) => {
      if (this.openChannelQuery.hasNext && !this.openChannelQuery.isLoading) {
        this.openChannelQuery
          .next((list, error) => {
            error ? reject(error) : resolve(list);
          })
          .then(r => console.log(r))
          .catch(e => console.log(e));
      } else {
        resolve([]);
      }
    });
  };

  createOpenChannel = (channelName: string) => {
    return new Promise((resolve, reject) => {
      channelName
        ? this.sb.OpenChannel.createChannel(channelName, null, null, (openChannel, error) => {
            error ? reject(error) : resolve(openChannel);
          })
            .then(r => console.log(r))
            .catch(e => console.log(e))
        : this.sb.OpenChannel.createChannel((openChannel, error) => {
            error ? reject(error) : resolve(openChannel);
          })
            .then(r => console.log(r))
            .catch(e => console.log(e));
    });
  };

  enter = (channelUrl: string) => {
    return new Promise<void>((resolve, reject) => {
      this.sb.OpenChannel.getChannel(channelUrl, (openChannel, error) => {
        if (error) {
          reject(error);
        } else {
          openChannel
            .enter((response, _error) => {
              _error ? reject(_error) : resolve();
            })
            .then(r => console.log(r))
            .catch(e => console.log(e));
        }
      })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  };

  exit = (channelUrl: string) => {
    return new Promise<void>((resolve, reject) => {
      this.sb.OpenChannel.getChannel(channelUrl, (openChannel, error) => {
        if (error) {
          reject(error);
        } else {
          openChannel
            .exit((response, _error) => {
              _error ? reject(_error) : resolve();
            })
            .then(r => console.log(r))
            .catch(e => console.log(e));
        }
      })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  };

  getParticipantList = (channelUrl: string, isInit = false) => {
    return new Promise((resolve, reject) => {
      this.sb.OpenChannel.getChannel(channelUrl, (openChannel, error) => {
        if (error) {
          reject(error);
        } else {
          if (isInit || isNull(this.participantQuery)) {
            this.participantQuery = openChannel.createParticipantListQuery();
            this.participantQuery.limit = 30;
          }
          if (this.participantQuery.hasNext && !this.participantQuery.isLoading) {
            this.participantQuery.next((participantList, _error) => {
              _error ? reject(_error) : resolve(participantList);
            });
          } else {
            resolve([]);
          }
        }
      })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  };

  /**
   * Group Channel
   */
  getGroupChannelList = () => {
    // if (isInit || isNull(this.groupChannelQuery)) {
    // this.groupChannelQuery = new instance.sb.GroupChannel.createMyGroupChannelListQuery();
    this.groupChannelQuery = instance.sb.GroupChannel.createMyGroupChannelListQuery();
    this.groupChannelQuery.limit = QUERY_LIMIT;
    this.groupChannelQuery.includeEmpty = false;
    this.groupChannelQuery.order = 'latest_last_message';
    // }
    return new Promise((resolve, reject) => {
      if (this.groupChannelQuery.hasNext && !this.groupChannelQuery.isLoading) {
        this.groupChannelQuery.next((list, error) => {
          error ? reject(error) : resolve(list);
        });
      } else {
        resolve([]);
      }
    });
  };

  createGroupChannel = (
    channelName: string,
    channelUrl: string,
    connectionId: string,
    currentUserId: string,
  ) => {
    return new Promise((resolve, reject) => {
      // set params for the channel
      const sendBirdAction = SendBirdAction.getInstance();
      const params = new sendBirdAction.sb.GroupChannelParams();

      params.addUserIds([connectionId, currentUserId]);
      params.isDistinct = true;
      params.name = channelName;
      params.channelUrl = channelUrl; // In case of a group channel, you can create a new channel by specifying its unique channel URL in a 'GroupChannelParams' object.

      sendBirdAction.sb.GroupChannel.createChannel(params, (groupChannel, error) => {
        if (error) {
          reject(error);
        } else {
          // set channel preference for channel to auto accept invite
          const autoAccept = true;
          sendBirdAction.sb
            // eslint-disable-next-line func-names
            .setChannelInvitationPreference(autoAccept, function (response, _error) {
              if (_error) {
                console.log(_error);
              }
            })
            .then(r => console.log(r))
            .catch(e => console.log(e));
          resolve(groupChannel);
        }
      })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  };

  inviteGroupChannel = (channelUrl: string, userIds: string[]) => {
    return new Promise((resolve, reject) => {
      this.sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
        if (error) {
          reject(error);
        } else {
          groupChannel
            .inviteWithUserIds(userIds, (_groupChannel, _error) => {
              _error ? reject(_error) : resolve(_groupChannel);
            })
            .then(r => console.log(r))
            .catch(e => console.log(e));
        }
      })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  };

  leave = (channelUrl: string) => {
    return new Promise<void>((resolve, reject) => {
      this.sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
        if (error) {
          reject(error);
        } else {
          groupChannel
            .leave((response, _error) => {
              _error ? reject(_error) : resolve();
            })
            .then(r => console.log(r))
            .catch(e => console.log(e));
        }
      })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  };

  hide = (channelUrl: string) => {
    return new Promise<void>((resolve, reject) => {
      this.sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
        if (error) {
          reject(error);
        } else {
          groupChannel
            .hide(this.hidePreviousMessages, (response, _error) => {
              _error ? reject(_error) : resolve();
            })
            .then(r => console.log(r))
            .catch(e => console.log(e));
        }
      })
        .then(r => console.log(r))
        .catch(e => console.log(e));
    });
  };

  markAsRead = channel => {
    channel?.markAsRead();
  };

  /**
   * Message
   */
  getMessageList = (channel: { createPreviousMessageListQuery: () => any }, isInit = false, limit = 50) => {
    if (isInit || isNull(this.previousMessageQuery)) {
      this.previousMessageQuery = channel.createPreviousMessageListQuery();
      this.previousMessageQuery.includeParentMessageInfo = true;
      this.previousMessageQuery.includeThreadInfo = true;
      // this.previousMessageQuery.includeReplies = true;
    }
    return new Promise((resolve, reject) => {
      if (this.previousMessageQuery.hasMore && !this.previousMessageQuery.isLoading) {
        this.previousMessageQuery.load(limit, true, (messageList, error) => {
          error ? reject(error) : resolve(messageList);
        });
      } else {
        resolve([]);
      }
    });
  };

  getMemberInChannel = async channel => {
    const { GroupChannel } = this.sb;
    try {
      const groupChannel = await GroupChannel.getChannel(channel);
      const { members } = groupChannel;
      return members;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  getThreadMessageList = async filteredChat => {
    const TIMESTAMP = Date.now();
    const params = new this.sb.ThreadedMessageListParams();
    params.includeParentMessageInfo = true;
    params.prevResultSize = 100;
    const threads = filteredChat.map(async (chat, index) => {
      // const { threadedReplies } = await chat.getThreadedMessagesByTimestamp(TIMESTAMP, params);
      // return threadedReplies;
      const { threadedReplies } = await chat.getThreadedMessagesByTimestamp(TIMESTAMP, params);
      if ((Number(index) + 1) % 9 === 0) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      return threadedReplies;
    });
    const results = await Promise.all(threads);
    return results.flatMap(thread => thread);
  };

  getReadReceipt = (channel: { getReadReceipt: (arg0: any) => any }, message: { sender: any }) => {
    if (this.isCurrentUser(message.sender)) {
      return channel.getReadReceipt(message);
    }
    return 0;
  };

  sendUserMessage = ({ channel, message, parentMessageId, mentionedUserIds, data, handler }) => {
    const params = new this.sb.UserMessageParams();
    params.parentMessageId = parentMessageId;
    params.message = message;
    params.mentionedUserIds = mentionedUserIds;
    params.data = JSON.stringify(data);
    return channel.sendUserMessage(params, (_message, error) => {
      if (handler) handler(_message, error);
    });
  };

  sendFileMessage = ({ channel, file, handler }) => {
    return channel.sendFileMessage(file, (message, error) => {
      if (handler) handler(message, error);
    });
  };

  deleteMessage = ({ channel, message }) => {
    return new Promise((resolve, reject) => {
      if (!this.isCurrentUser(message.sender)) {
        const error = { message: 'You have not ownership in this message.' } as any;
        reject(error);
      }
      channel.deleteMessage(message, (response: unknown, error: any) => {
        error ? reject(error) : resolve(response);
      });
    });
  };

  static getInstance = () => {
    return new SendBirdAction();
  };
}

export { SendBirdAction };
