import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import { IconButton, iconBtnType } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { showSnackbar } from 'redux/modules/snackbar';
import { getAuth } from 'redux/modules/auth/selectors';

import { useStyles } from './ScheduleLink.styles';
import GenerateLinkDrawer from './GenerateLinkDrawer';

const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'staging';
console.log(ENV);
const HOST_URL =
  ENV === 'prod'
    ? `https://member.confidanthealth.com/public/`
    : ENV === 'qa' || ENV === 'development'
    ? `https://qa.member.confidanthealth.com/public/`
    : `https://sit1.member.confidanthealth.com/public/`;

type Props = {
  cardTitle?: string;
  generateLinkText?: string;
  copyLinkText?: string;
  mainLink?: string;
  isSchedule?: boolean;
  onOpen?: () => void;
};
const ScheduleLink: FC<Props> = ({
  cardTitle = 'Scheduling link',
  generateLinkText = 'Generate link',
  copyLinkText = 'Copy link',
  mainLink,
  isSchedule = true,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);

  const [showGenerateLink, setShowGenerateLink] = useState(false);

  const defaultScheduleLink = mainLink
    ? HOST_URL + mainLink
    : `${HOST_URL}book-appointment?selectedProvider=${meta.userId}`;

  return (
    <Box className={classes.scheduling}>
      <Box className={classes.schedulingLink}>
        <Box className="label">{cardTitle}:</Box>
        <IconButton icon="plus" className="generateBtn" onClick={() => setShowGenerateLink(true)}>
          {generateLinkText}
        </IconButton>
      </Box>
      <Box className={classes.copyLink}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <Icons glyph="copy-link" color={colors.neutral400} />
            </InputAdornment>
          }
          disabled
          value={defaultScheduleLink}
        />
        <IconButton
          icon="copy-outlined"
          variant={iconBtnType.PRIMARY}
          className="btn"
          onClick={async () => {
            await navigator.clipboard.writeText(defaultScheduleLink);
            dispatch(
              showSnackbar({
                snackType: 'success',
                snackMessage: 'Scheduling link copied successfully',
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }}
        >
          {copyLinkText}
        </IconButton>
      </Box>
      {showGenerateLink && isSchedule && (
        <GenerateLinkDrawer
          open={showGenerateLink}
          onClose={() => setShowGenerateLink(false)}
          providerId={meta?.userId}
          hostURL={HOST_URL}
        />
      )}
    </Box>
  );
};

export default ScheduleLink;
