import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { unstable_HistoryRouter as HistoryRouter, Route, Routes } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// mui
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-date-pickers-pro';

// theme
import { theme } from '@confidant-health/lib/theme';

import OneSignal from 'react-onesignal';
// redux
import createStore from 'redux/store';
import { getAuth } from 'redux/modules/auth/selectors';
import history from 'utils/history';
import { adminRoutes, providerRoutes } from 'routes';

// pages
import Login from 'pages/login';
import AdminDashboard from 'pages/admin/dashboard';
import Schedule from 'pages/provider/schedule';
import SnackContainer from 'layouts/snackbar';
import TwoFactorAuthentication from 'pages/provider/two-factor-authentication';
import { ForgotPassword } from 'pages/provider/forgot-password/ForgotPassword';

// One Signal
import SocketListener from 'utils/SocketListener';
import { getSocketState } from 'redux/modules/socket/selectors';
import getConfig from 'config';
import SocketScreens from 'utils/SocketScreens';
import { InAppNotification } from 'utils/InAppNotifications';
import ErrorPage from 'pages/admin/Error-Page';
import { registerPlayerId } from 'services/auth/auth.service';

import { socketActionCreators } from './redux/modules/socket';
import { SUPPORTED_AUTHORITIES } from './constants/CommonConstants';
import ConfidantErrorBoundary from './components/error-boundary/ConfidantErrorBoundary';

import { authActionCreators } from './redux/modules/auth';

export const { store, persistor } = createStore();

const initOneSignal = async () => {
  try {
    await OneSignal.init({
      appId: getConfig.api.oneSignalAppId,
      allowLocalhostAsSecureOrigin: true,
    });
    await OneSignal.getUserId(function (userId) {
      registerPlayerId({ playerId: userId })
        .then(() => console.log('PlayerId registered'))
        .catch(() => console.log('Error player registered'));
    });
    console.log('One Signal Enabled', await OneSignal.isPushNotificationsEnabled());
    console.log('OneSignal initialized!');
  } catch (error) {
    console.log('One Signal Init Error', error);
  }
};

const App = () => {
  const { isAuthenticated, isAdmin, meta } = useSelector(getAuth);
  const { navigate, navigateData } = useSelector(getSocketState);
  const { pathname, search } = window.location;
  const dispatch = useDispatch();

  useEffect(() => {
    if (getConfig.muiLicenseKey) {
      LicenseInfo.setLicenseKey(getConfig.muiLicenseKey);
    }
  }, []);

  useEffect(() => {
    if (navigate) {
      switch (navigateData.screen) {
        case SocketScreens.CHAT:
          if (history.location.pathname !== '/provider/chats') {
            history.push(`/provider/chats`, {
              tabType: navigateData.payload.tabType,
              chatId: navigateData.payload.senderId,
            });
          }
          break;
        case SocketScreens.ADMINCHAT:
          if (history.location.pathname !== '/admin/chats') {
            history.push(`/admin/chats`, {
              tabType: navigateData.payload.tabType,
              chatId: navigateData.payload.senderId,
            });
          }
          break;
        case SocketScreens.APPOINTMENT:
          history.push(`/provider/appointments/${navigateData.payload.appointmentType}`, {
            appointmentId: navigateData.payload.appointmentId,
          });
          break;
        case SocketScreens.ADMIN_APPOINTMENT:
          history.push(`/admin/appointments/${navigateData.payload.appointmentType}`, {
            appointmentId: navigateData.payload.appointmentId,
          });
          break;
        default:
          break;
      }
      store.dispatch(socketActionCreators.navigationComplete());
    }
  }, [navigate]);
  useEffect(() => {
    if (isAuthenticated) {
      SocketListener.initSocket(meta, store);
      void initOneSignal();
    } else {
      if (!pathname.includes('login')) {
        dispatch(authActionCreators.authenticatedLinkNavigation(pathname + search));
      }
      history.push(`/login`);
    }
  }, [isAuthenticated]);

  let routes;
  if (isAdmin) {
    routes = adminRoutes;
    if (
      meta.authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR ||
      meta.authority === SUPPORTED_AUTHORITIES.BILLING_SUPPORT
    ) {
      routes = adminRoutes.filter(item => {
        return item.authority.includes(meta.authority);
      });
    }
  } else {
    routes = providerRoutes;
  }

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/twofactorauthentication" element={<TwoFactorAuthentication />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route  */}
        {isAuthenticated && (
          <>
            {routes.map(({ path, component: Component }) => (
              <Route path={path} element={<Component />} key={path} />
            ))}
          </>
        )}
        <Route path="/" element={isAdmin ? <AdminDashboard /> : <Schedule />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </HistoryRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ConfidantErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <InAppNotification />
              <App />
              <SnackContainer />
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    </ConfidantErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
