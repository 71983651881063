import { Box } from '@mui/system';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Input, inputSize } from '@confidant-health/lib/ui/atoms/input';

import { useEffect, useState } from 'react';
import cx from 'clsx';
import { useDispatch } from 'react-redux';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { showSnackbar } from 'redux/modules/snackbar';
import { updateEvaluationDCT } from 'services/conversation/conversation.service';
import { useStyles } from './EvaluationStates.styles';

type AddDctProps = {
  isOpen: boolean;
  onClose: () => void;
  dct?: any;
  onDelete?: () => void;
};

const EditDCT = ({ isOpen, onClose, dct, onDelete }: AddDctProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState({
    dctName: '',
    canReuse: false,
  });

  useEffect(() => {
    setData({
      dctName: dct.text,
      canReuse: dct?.usePreviousResponses,
    });
  }, [dct]);
  console.log(dct);

  const updateDct = async () => {
    const request = {
      contentBlocks: [],
      description: dct?.description,
      name: data?.dctName,
      prompt: dct?.prompt,
      reorder: false,
      responderType: dct?.responderType,
      scorable: dct?.isDCTScoreable,
      usePreviousResponses: data?.canReuse,
    };
    try {
      const response = await updateEvaluationDCT(dct.dctId, request);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'DCTs updated successfully',
          }),
        );
        // void fetchEvaluationContext();
      }
    } catch (err) {
      const msg = err.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: msg,
        }),
      );
    }
  };

  return (
    <Drawer
      open={isOpen}
      variant={drawerType.FORM}
      className={classes.drawer2}
      onClose={onClose}
      header={
        <div className={classes.header2}>
          <Box>
            <Box className={classes.headerTitle}>Edit DCT</Box>
            <Box className={classes.headerSubTitle}>{dct?.text}</Box>
          </Box>
          <IconButton icon="close" onClick={onClose} className={cx(classes.icon, classes.editIcon)} />
        </div>
      }
      footer={
        <Box className={classes.footer}>
          <Button variant={btnType.TEXT} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              void updateDct();
              onClose();
            }}
          >
            Save
          </Button>
        </Box>
      }
    >
      <Box
        padding="60px 40px 40px"
        display="flex"
        gap="40px"
        flexDirection="column"
        borderBottom="1px solid #EDF1F5"
      >
        <Box className={classes.editDCTHeading}>General</Box>
        <Box className={classes.section2}>
          <Box>
            <Box className={classes.DCTLabel}>DCT name</Box>
            <Input
              value={data.dctName}
              placeholder="DCT section name"
              onChange={e => setData({ ...data, dctName: e.target.value })}
              size={inputSize.M}
              fullWidth
            />
          </Box>
          <Box>
            <Checkbox
              checked={data.canReuse}
              onChange={() => setData({ ...data, canReuse: !data.canReuse })}
              className={classes.DCTCheckBox}
            />
            <span className={classes.DCTCheckBoxLabel}>Can reuse all typical answers</span>
          </Box>
        </Box>
      </Box>
      <Box padding="40px" display="flex" gap="16px" flexDirection="column">
        <Box className={classes.editDCTHeading}>Danger zone</Box>
        <Box>
          <IconButton
            icon="delete-outlined-2"
            className={cx(classes.icon, classes.dctDangerBtn)}
            onClick={onDelete}
          >
            Remove DCT
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default EditDCT;
