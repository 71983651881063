import { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Icons } from '@confidant-health/lib/icons';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Text } from '@confidant-health/lib/ui/atoms/typography';
import { ProgressBar } from '@confidant-health/lib/ui/atoms/ProgressBar';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import { convertByteToString } from 'utils';
import { S3MediaManager } from 'services/conversation/s3MediaManager';

import { useStyles } from './UploadFileProgress.styles';

type Props = {
  file: File | string;
  fileName: string;
  fileSize: number;
  s3Folder?: string;
  onRemove: () => void;
  onUploaded: (url: string, file: File) => void;
};

export const UploadFileProgress: FC<Props> = ({
  file,
  fileSize: size,
  fileName,
  s3Folder = 'idCardImages/',
  onRemove,
  onUploaded,
}) => {
  const classes = useStyles();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [fileUrl, setFileUrl] = useState('');
  const [isFailed, setIsFailed] = useState(false);

  const onPercentUpdate = (e: { percent: number }) => {
    setProgress(e.percent * 100);
  };

  const uploadFile = useCallback(async item => {
    setProgress(0);
    setFileSize(item.size);
    setIsUploading(true);
    const res = await S3MediaManager.uploadToS3(item, s3Folder, onPercentUpdate);

    if (res.success) {
      onUploaded(decodeURIComponent(res.response.location), item);
    } else {
      setIsFailed(true);
    }
    setIsUploading(false);
  }, []);

  useEffect(() => {
    if (typeof file !== 'string') {
      void uploadFile(file);
    } else {
      setFileUrl(file);
      setFileSize(size);
    }
  }, [file]);

  const progressVal = Math.round((progress / (fileSize || 1)) * 100);
  const iconFailed = isFailed ? 'rotate' : 'delete-outlined-2';
  const failedText = isFailed ? 'Oops! Upload failed' : convertByteToString(fileSize);

  return (
    <Box className={classes.card}>
      <Box
        className={clsx(classes.imageWrap, {
          [classes.imageWrapFailed]: isFailed,
        })}
      >
        {isUploading || isFailed ? (
          <Icons glyph="image" />
        ) : (
          <Avatar src={fileUrl} variant={avatarType.SQUARE} size={64} />
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap="8px" flex={1}>
        <Text className={classes.filename}>{typeof file === 'string' ? fileName : file.name}</Text>
        {isUploading && <ProgressBar value={progressVal} />}
        <Text className={clsx(classes.progressText, { [classes.failedText]: isFailed })}>
          {isUploading ? `${convertByteToString(progress)} • ${progressVal}% uploaded` : failedText}
        </Text>
      </Box>
      <IconButton
        icon={isUploading ? 'close' : iconFailed}
        onClick={onRemove}
        className={clsx(classes.removeBtn, {
          [classes.recycleBtn]: !isUploading,
          [classes.reloadBtn]: isFailed,
        })}
      />
    </Box>
  );
};
