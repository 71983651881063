/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { colors } from '@confidant-health/lib/colors';
import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { sentenceCase } from 'sentence-case';
import { toDateString } from 'utils/CommonUtils';
import { IProfileElementDetailReport } from './ProfileElementDetail.types';

export const tableColumns: TableGrid.TableColumn[] = [
  {
    id: 'member',
    label: 'Memeber',
    sortable: false,
  },
  {
    id: 'name',
    label: 'Profile element value',
    sortable: false,
  },
  {
    id: 'lastUpdated',
    label: 'Date assigned',
    sortable: false,
  },
  {
    id: 'actions',
    label: '',
    sortable: false,
  },
];

export const getLast30DaysData = (profileElementReport: IProfileElementDetailReport) =>
  profileElementReport
    ? profileElementReport.aggregations.last_30_days.count.buckets.map(bucket => bucket.doc_count)
    : [];

export const getPast30DaysData = (profileElementReport: IProfileElementDetailReport) =>
  profileElementReport
    ? profileElementReport.aggregations.past_30_days.counts.buckets.map(bucket => bucket.doc_count)
    : [];

export const getRecentAssignedData = (
  profileElementReport: IProfileElementDetailReport,
  searchKeyword: string,
) => {
  console.log();

  return profileElementReport
    ? searchKeyword
      ? profileElementReport.aggregations.recent_assignment.buckets
          .map(bucket => ({
            name: bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.dataElementValue,
            member: {
              memberId:
                (bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.userAccountId ??
                  bucket.membername.buckets[0].profile_element_value.hits.hits[0].fields.memberId?.[0]) ||
                '',
              name:
                bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.firstName &&
                bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.lastName
                  ? `${bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.firstName} ${bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.lastName}`
                  : bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.fullName,
            },
            lastUpdated: toDateString(bucket.key),
          }))
          .filter(item => {
            return (
              item.member.name?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
              item.name?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
              item.lastUpdated?.toLowerCase().includes(searchKeyword.toLowerCase())
            );
          })
      : profileElementReport.aggregations.recent_assignment.buckets.map(bucket => ({
          name: bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.dataElementValue,
          member: {
            memberId:
              (bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.userAccountId ??
                bucket.membername.buckets[0].profile_element_value.hits.hits[0].fields.memberId?.[0]) ||
              '',
            name:
              bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.firstName &&
              bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.lastName
                ? `${bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.firstName} ${bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.lastName}`
                : bucket.membername.buckets[0].profile_element_value.hits.hits[0]._source.fullName,
          },
          lastUpdated: toDateString(bucket.key),
        }))
    : [];
};

const chartColors = [
  colors.success500,
  colors.orange400,
  colors.neutral500,
  colors.secondary500,
  colors.primary500,
  colors.cyan500,
  colors.indigo500,
  colors.purple500,
  colors.rose500,
  colors.destructive500,
];

export const getChartInfo = (report: IProfileElementDetailReport) =>
  report
    ? {
        title: '',
        subtitle: '',
        chartData: report.aggregations.total_assignments_by_value.buckets.map((item, i) => ({
          name: sentenceCase(item.key || ''),
          color: chartColors[i],
          data: [item.doc_count],
        })),
        donutOptTitle: 'Assignments',
        donutOptTotal: report.aggregations.total_asignments.value,
      }
    : null;

export const getProfileElementDetailQuery = (name: string) => ({
  size: 0,
  query: {
    term: {
      'dataElementKey.keyword': name,
    },
  },
  aggs: {
    total_asignments: {
      cardinality: {
        field: 'userAccountId.keyword',
        precision_threshold: 100,
      },
    },
    total_assignments_by_value: {
      terms: {
        field: 'dataElementValue.keyword',
        size: 10,
      },
    },
    last_30_days: {
      filter: {
        range: {
          dataElementTimestamp: {
            gte: 'now-30d/d',
            lte: 'now/d',
          },
        },
      },
      aggs: {
        count: {
          date_histogram: {
            field: 'dataElementTimestamp',
            calendar_interval: '1d',
          },
        },
      },
    },
    past_30_days: {
      filter: {
        range: {
          dataElementTimestamp: {
            gte: 'now-60d/d',
            lt: 'now-30d/d',
          },
        },
      },
      aggs: {
        counts: {
          date_histogram: {
            field: 'dataElementTimestamp',
            calendar_interval: '1d',
          },
        },
      },
    },
    recent_assignment: {
      terms: {
        field: 'dataElementTimestamp.keyword',
        size: 1000,
        order: {
          _key: 'desc',
        },
      },
      aggs: {
        membername: {
          terms: {
            field: 'fullName.keyword',
          },
          aggs: {
            profile_element_value: {
              top_hits: {
                _source: [
                  'dataElementValue',
                  'fullName',
                  'firstName',
                  'lastName',
                  'lastModified',
                  'userAccountId',
                ],
                size: 1,
                script_fields: {
                  lastModifiedDate: {
                    script: {
                      source: "params['_source']['lastModified']",
                      lang: 'painless',
                    },
                  },
                  memberId: {
                    script: {
                      source: "params['_source']['userAccountId']",
                      lang: 'painless',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
});
