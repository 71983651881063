import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';

// components
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { Link } from '@confidant-health/lib/ui/atoms/link';
import { Pill, pillType } from '@confidant-health/lib/ui/atoms/pill';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';

import { BaseLayout } from 'layouts/base';
import { profileActionCreators } from 'redux/modules/profile';
import { getProfile } from 'redux/modules/profile/selectors';
import history from 'utils/history';

import { providerColumns, filterOptionsMock, multiSelectFilterOptionsMock } from './ProviderList.mock';
import { useStyles } from './ProviderList.styles';
import AddProviderDrawer from './add-provider/index';
import { getTopProviders } from '../../../services/profile/profile.service';

const ProviderList: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { providers, totalProviders } = useSelector(getProfile);
  const [value, setValue] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [topProviders, setTopProviders] = useState({ providersByConnections: [], providersBySessions: [] });

  const dataSource = useCallback(
    list => {
      return list.map(item => {
        const nextSlot = item.schedule && item.schedule.slots?.length > 0 && item.schedule.slots[0];
        let availability = '--';
        if (nextSlot) {
          const { start } = nextSlot;
          availability = dayjs(start).format('ddd MMM DD, h:mm A');
        }
        return {
          ...item,
          provider: {
            id: item?.providerId,
            name: item?.fullName,
            role: item?.designation,
            thumbnail: item?.profileImage,
          },
          isActive: item?.active,
          connections: `${item?.connectionsList?.length || 0} active`,
          phoneNumber: item?.phoneNumber,
          email: item?.emailAddress,
          availability,
          actions: {
            id: item.providerId,
          },
        };
      });
      // return items;
    },
    [value],
  );

  const handleAddProviderClick = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const getProviderListRequestBody = () => {
    const appliedFilters = value?.search?.filter as string[];
    const states = [];
    const designation = [];
    const levelOfEngagements = [];
    let activeInChat = true;
    let session = '';
    appliedFilters?.forEach(filter => {
      switch (filter) {
        case 'Active in chat':
          activeInChat = true;
          break;
        case 'Inactive in chat':
          activeInChat = false;
          break;
        case '2 Sessions':
          session = 'AT_LEAST_TWO_SESSION';
          break;
        case 'No Sessions':
          session = 'NO_SESSION';
          break;
        case 'Texas':
          states.push('Texas');
          break;
        case 'Florida':
          states.push('Florida');
          break;
        case 'Connecticut':
          states.push('Connecticut');
          break;
        case 'Virginia':
          states.push('Virginia');
          break;
        case 'New Hampshire':
          states.push('New Hampshire');
          break;
        case 'Nurse Practitioner':
          designation.push('Nurse Practitioner');
          break;
        case 'Licensed Professional Counselor':
          designation.push('Licensed Professional Counselor');
          break;
        case 'Social Worker':
          designation.push('Social Worker');
          break;
        case 'Psychiatrist':
          designation.push('Psychiatrist');
          break;
        case 'Care Manager':
          designation.push('Care Manager');
          break;
        case 'Coach':
          designation.push('Coach');
          break;
        case 'Matchmaker':
          designation.push('Matchmaker');
          break;
        case 'Prescriber':
          designation.push('Prescriber');
          break;
        case 'Supervising Prescriber':
          designation.push('Supervising Prescriber');
          break;
        case 'Drug and Alcohol Counselor':
          designation.push('Drug and Alcohol Counselor');
          break;
        case 'Psychiatric Nurse Practitioner':
          designation.push('Psychiatric Nurse Practitioner');
          break;
        default:
          break;
      }
    });
    if (value?.search?.dateFilter?.startDate && value?.search?.dateFilter?.endDate) {
      return {
        activeInChat,
        designation,
        levelOfEngagements,
        searchQuery: value?.search?.searchKey || '',
        session,
        states,
        startDate: value?.search?.dateFilter?.startDate
          ? dayjs(value?.search?.dateFilter?.startDate).format('YYYY-MM-DD')
          : '',
        endDate: value?.search?.dateFilter?.endDate
          ? dayjs(value?.search?.dateFilter?.endDate).format('YYYY-MM-DD')
          : '',
      };
    }
    return {
      activeInChat,
      designation,
      levelOfEngagements,
      searchQuery: value?.search?.searchKey || '',
      session,
      states,
      startDate: '',
      endDate: '',
    };
  };
  const getSessionValue = (valueArray: string[]) => {
    switch (valueArray[0]) {
      case '2 Sessions':
        return 'AT_LEAST_TWO_SESSION';
      case 'No Sessions':
        return 'NO_SESSION';
      default:
        return '';
    }
  };
  const getProviderListMultiSelectRequestBody = () => {
    const multiselectFilters = value.search?.multiSelectFilter;
    const states = multiselectFilters?.State ?? [];
    const designation = multiselectFilters?.Role ?? [];
    const levelOfEngagements = [];

    const activeInChat = !(
      multiselectFilters?.Active && multiselectFilters?.Active?.[0] === 'Inactive in chat'
    );
    const session = multiselectFilters?.['Sessions Attended']
      ? getSessionValue(multiselectFilters?.['Sessions Attended'])
      : [];

    if (value?.search?.dateFilter?.startDate && value?.search?.dateFilter?.endDate) {
      return {
        activeInChat,
        designation,
        levelOfEngagements,
        searchQuery: value?.search?.searchKey || '',
        session,
        states,
        startDate: value?.search?.dateFilter?.startDate
          ? dayjs(value?.search?.dateFilter?.startDate).format('YYYY-MM-DD')
          : '',
        endDate: value?.search?.dateFilter?.endDate
          ? dayjs(value?.search?.dateFilter?.endDate).format('YYYY-MM-DD')
          : '',
      };
    }
    return {
      activeInChat,
      designation,
      levelOfEngagements,
      searchQuery: value?.search?.searchKey || '',
      session,
      states,
      startDate: '',
      endDate: '',
    };
  };
  const handleFetchProvidersParamsFormat = ({ search, pagination, sorter }: tableParamsType) => {
    const sortBy = sorter?.column === 'provider' ? 'fullname' : sorter?.column || '';
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter?.direction,
      sortBy,
    };

    return params;
  };

  const getTopProviderList = async () => {
    try {
      const params = { days: 30, limit: 3 };
      const response = await getTopProviders(params);

      response.data
        ? setTopProviders(response.data)
        : setTopProviders({
            providersByConnections: [],
            providersBySessions: [],
          });
    } catch (error) {
      console.log('Error ', error);
    }
  };

  useEffect(() => {
    void getTopProviderList();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      profileActionCreators.fetchProviders({
        ...handleFetchProvidersParamsFormat(value),
        requestBody: value.search?.multiSelectFilter
          ? getProviderListMultiSelectRequestBody()
          : getProviderListRequestBody(),
      }),
    );
  }, [value]);

  const onRowClick = item => {
    history.push(`/admin/providers/${item.id}?name=general-information`);
  };

  const onViewProfile = (pId: string) => () => {
    history.push(`/admin/providers/${pId}?name=general-information`);
  };
  // const onViewChat = (pId: string) => () => {
  //   history.push(`/admin/chats?userId=${pId}&type=providers`);
  // };
  const renderColumns = providerColumns.map(column => {
    if (column.id === 'provider') {
      return {
        ...column,
        renderCell: ({ thumbnail, id, role, name }: any) => (
          <Link className={classes.memberColumn} to={`/admin/providers/${id}?name=general-information`}>
            <div className={classes.member}>
              <Avatar size={40} variant={avatarType.CIRCLE} src={thumbnail} name={name} />
              <div className={classes.info}>
                {name}
                <Badge className={clsx(classes.badge, role?.split(' ').join(''))} variant={badgeType.FILLED}>
                  {role}
                </Badge>
              </div>
            </div>
          </Link>
        ),
      };
    }
    if (column.id === 'sessions') {
      return {
        ...column,
        renderCell: (completed: number) => `${completed || 0} session${completed > 1 ? 's' : ''}`,
      };
    }
    if (column.id === 'phoneNumber') {
      return {
        ...column,
        renderCell: (phoneNumber: number) => `${phoneNumber || 'N/A'}`,
      };
    }
    if (column.id === 'email') {
      return {
        ...column,
        renderCell: (email: string) => `${email || 'N/A'}`,
      };
    }
    if (column.id === 'isActive') {
      return {
        ...column,
        renderCell: (isActive = false) => {
          return (
            <Badge
              variant={badgeType.OUTLINED}
              style={isActive ? badgeStyle.RESOLVED : badgeStyle.UNRELATED}
              className={classes.status}
            >
              <span className={clsx(classes.dot, { [classes.dotActive]: isActive })} />{' '}
              {isActive ? 'Active' : 'Inactive'}
            </Badge>
          );
        },
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[
              { label: 'View Profile', onClick: onViewProfile(id) },
              // { label: 'Go to chat', onClick: onViewChat(id) },
            ]}
          />
        ),
      };
    }
    return column;
  });

  const onProviderAddSuccess = () => {
    handleCloseDrawer();
    dispatch(
      profileActionCreators.fetchProviders({
        ...handleFetchProvidersParamsFormat(value),
        requestBody: value.search?.multiSelectFilter
          ? getProviderListMultiSelectRequestBody()
          : getProviderListRequestBody(),
      }),
    );
  };

  const renderProviderRanks = (providerList, type) => {
    return (
      <Box className={classes.provider}>
        {providerList
          ? providerList?.map((provider: any) => (
              <ProfileInfo
                key={provider.providerId}
                type="member"
                photo={provider.profileImage}
                fullName={`${provider.count || ''} ${type}`}
                nickName={provider.fullName}
              />
            ))
          : ''}
      </Box>
    );
  };

  const data = dataSource(providers);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 100);
  }, [providers]);
  return (
    <BaseLayout>
      <AddProviderDrawer open={isDrawerOpen} onClose={handleCloseDrawer} onSuccess={onProviderAddSuccess} />
      <div className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Providers
            </Heading>
            <Badge
              className={classes.totalMemberBadge}
              variant={badgeType.OUTLINED}
              style={badgeStyle.UNRELATED}
            >
              {totalProviders || providers.length} total
            </Badge>
          </Stack>

          <IconButton
            className={classes.addBtn}
            icon="plus"
            variant={iconBtnType.PRIMARY}
            onClick={handleAddProviderClick}
          >
            Add provider
          </IconButton>
        </Stack>

        <Box
          className={classes.cardContentSection}
          gridTemplateColumns="2fr 3fr"
          rowGap="32px"
          display="grid"
        >
          <Box className={classes.ranksList}>
            <Box className={classes.rank}>
              <Pill className={classes.rankPill} variant={pillType.WARNING}>
                1
              </Pill>
              <Text className={classes.rankFont}>1st place</Text>
            </Box>
            <Box className={classes.rank}>
              <Pill className={classes.rankPill} variant={pillType.NEUTRAL}>
                2
              </Pill>
              <Text className={classes.rankFont}>2nd place</Text>
            </Box>
            <Box className={classes.rank}>
              <Pill className={classes.rankPill} variant={pillType.DANGER}>
                3
              </Pill>
              <Text className={classes.rankFont}>3rd place</Text>
            </Box>
          </Box>
          <Box className={classes.sessionList}>
            <Box>
              {/* List heading */}
              <Text className={classes.providerHeadingFont}> Most sessions last 30 days </Text>
            </Box>
            <Box className={classes.providersList}>
              {topProviders ? renderProviderRanks(topProviders?.providersBySessions, 'sessions') : ''}
            </Box>
          </Box>
          <Box className={classes.sessionList}>
            <Box>
              {/* List heading */}
              <Text className={classes.providerHeadingFont}> Most new connections last 30 days </Text>
            </Box>
            <Box className={classes.providersList}>
              <Box className={classes.providersList}>
                {topProviders ? renderProviderRanks(topProviders?.providersByConnections, 'connections') : ''}
              </Box>
            </Box>
          </Box>
        </Box>

        <Table
          searchProps={{
            placeholder: 'Search provider by name, phone, or email',
            filterProps: {
              variant: Filter.tableFilterType.MULTIPLE,
              options: filterOptionsMock,
              multiSelectOptions: multiSelectFilterOptionsMock,
              allOptionLabel: 'All provider',
              dateFilter: {
                startDate: '',
                endDate: '',
              },
            },
          }}
          gridProps={{
            columns: renderColumns,
            data,
            isLoading,
            onRowClick,
          }}
          paginationProps={{
            currentRows: data.length,
            totalCount: totalProviders || providers.length,
          }}
          value={value}
          onChange={setValue}
          // onChange={(newValue: any) => {
          //   const newVal = newValue.search.filter?.filter(
          //     fil =>
          //       fil !== 'active_chat' &&
          //       fil !== 'inactive_chat' &&
          //       fil !== '2_sessions' &&
          //       fil !== 'no_sessions',
          //   );
          //   const oldVal = value.search.filter;
          //   if (
          //     newValue.search.filter?.includes('active_chat') &&
          //     newValue.search.filter?.includes('inactive_chat')
          //   ) {
          //     if (oldVal?.includes('active_chat')) {
          //       newVal.push('inactive_chat');
          //     } else if (oldVal?.includes('inactive_chat')) {
          //       newVal.push('active_chat');
          //     }
          //   } else if (
          //     newValue.search.filter?.includes('active_chat') ||
          //     newValue.search.filter?.includes('inactive_chat')
          //   ) {
          //     newVal.push(newValue.search.filter?.find(i => i === 'active_chat' || i === 'inactive_chat'));
          //   }
          //   if (
          //     newValue.search.filter?.includes('2_sessions') &&
          //     newValue.search.filter?.includes('no_sessions')
          //   ) {
          //     if (oldVal?.includes('2_sessions')) {
          //       newVal.push('no_sessions');
          //     } else if (oldVal?.includes('no_sessions')) {
          //       newVal.push('2_sessions');
          //     }
          //   } else if (
          //     newValue.search.filter?.includes('2_sessions') ||
          //     newValue.search.filter?.includes('no_sessions')
          //   ) {
          //     newVal.push(newValue.search.filter?.find(i => i === '2_sessions' || i === 'no_sessions'));
          //   }
          //   newValue.search.filter = newVal;
          //   setValue({
          //     ...newValue,
          //     search: {
          //       ...newValue.search,
          //       filter: newVal?.filter((item, pos) => newVal?.indexOf(item) === pos),
          //     },
          //   });
          // }}
          className={classes.table}
        />
      </div>
    </BaseLayout>
  );
};

export { ProviderList };
