import { call, put, select, takeLatest } from 'redux-saga/effects';

import { IAction, IPayloadAction } from 'redux/store/types';
import { IScheduleUpdateRequest } from 'redux/modules/schedule/types';
import * as scheduleServices from 'services/schedule/schedule.service';
import * as ProxyService from 'services/proxy/proxy.service';
import getConfig from 'config';
import {
  FETCH_PROVIDER_SCHEDULE,
  FETCH_PROVIDER_SCHEDULE_FAILED,
  FETCH_PROVIDER_SCHEDULE_SUCCESSFUL,
  UPDATE_PROVIDER_SCHEDULE,
  UPDATE_PROVIDER_SCHEDULE_FAILED,
  UPDATE_PROVIDER_SCHEDULE_SUCCESSFUL,
  FETCH_PROVIDER_SERVICES1,
  FETCH_PROVIDER_SERVICES1_SUCCESSFUL,
  FETCH_PROVIDER_SERVICES1_FAILED,
  FETCH_SYSTEM_SERVICES,
  FETCH_SYSTEM_SERVICES_FAILED,
  FETCH_SYSTEM_SERVICES_SUCCESSFUL,
  FETCH_APPOINTMENT_REPORT,
  FETCH_APPOINTMENT_REPORT_SUCCESSFUL,
  FETCH_APPOINTMENT_REPORT_FAILED,
  FETCH_LEVEL_OF_ENGAGEMENTS,
  FETCH_LEVEL_OF_ENGAGEMENTS_SUCCESSFUL,
  FETCH_LEVEL_OF_ENGAGEMENTS_FAILED,
  FETCH_LOE_DETAILS,
  FETCH_LOE_DETAILS_SUCCESSFUL,
  FETCH_LOE_DETAILS_FAILED,
  FETCH_LOE_STATUS_DETAILS,
  FETCH_LOE_STATUS_DETAILS_SUCCESSFUL,
  FETCH_LOE_STATUS_DETAILS_FAILED,
  FETCH_PROVIDER_SCHEDULE_PUBLIC,
  FETCH_PROVIDER_SCHEDULE_SUCCESSFUL_PUBLIC,
  FETCH_PROVIDER_SCHEDULE_FAILED_PUBLIC,
  FETCH_DAILY_SCHEDULE,
  FETCH_DAILY_SCHEDULE_SUCCESSFUL,
  FETCH_DAILY_SCHEDULE_FAILED,
} from './actions';
import { showSnackbar } from '../snackbar';

function* fetchProviderScheduleHandler(action: IAction) {
  const { userId, queryParams = null } = action?.payload || {};
  try {
    const meta = yield select(state => state.profile);
    const { providerId } = action.payload || meta.profile;
    const { data } = yield call(
      scheduleServices.getProviderSchedule,
      userId || providerId, // admin user must provide providerId
      queryParams,
    );

    yield put({
      type: FETCH_PROVIDER_SCHEDULE_SUCCESSFUL,
      payload: {
        schedule: data,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_SCHEDULE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}
function* fetchDailyScheduleHandler(action: IAction) {
  const { userId, queryParams = null } = action?.payload || {};
  try {
    const meta = yield select(state => state.profile);
    const { providerId } = action.payload || meta.profile;
    const { data } = yield call(
      scheduleServices.getProviderSchedule,
      userId || providerId, // admin user must provide providerId
      queryParams,
    );

    yield put({
      type: FETCH_DAILY_SCHEDULE_SUCCESSFUL,
      payload: {
        schedule: data,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_DAILY_SCHEDULE_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchProviderSchedulePublicHandler(action: IAction) {
  const { userId, queryParams = null } = action?.payload || {};
  const request = queryParams
    ? {
        method: 'GET',
        url: `${getConfig.api.baseUrl}/v2/scheduling/schedule/${userId}`,
        memberId: userId,
        params: queryParams,
      }
    : {
        method: 'GET',
        url: `${getConfig.api.baseUrl}/v2/scheduling/schedule/${userId}`,
        memberId: userId,
      };
  try {
    const { data } = yield call(ProxyService.bypassProxy, request);

    yield put({
      type: FETCH_PROVIDER_SCHEDULE_SUCCESSFUL_PUBLIC,
      payload: {
        schedule: data,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_SCHEDULE_FAILED_PUBLIC,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* updateProviderScheduleHandler(
  action: IPayloadAction<{
    providerId: string;
    isProfile?: boolean;
    data: IScheduleUpdateRequest;
    callback?: (isSucceed: boolean) => void;
  }>,
) {
  try {
    const { callback } = action.payload;
    const showMessage = action?.payload?.isProfile || false;
    const meta = yield select(state => state.profile);
    const providerId = action.payload?.providerId || meta.profile.providerId;
    const response = yield call(scheduleServices.updateProviderSchedule, providerId, action.payload.data);

    yield put({
      type: UPDATE_PROVIDER_SCHEDULE_SUCCESSFUL,
      payload: {
        schedule: response.data,
      },
    });
    if (response.status === 200 && !showMessage) {
      yield put(showSnackbar({ snackType: 'success', snackMessage: 'Schedule updated successfully!' }));
      if (callback) yield callback(true);
    }
    yield put({
      type: FETCH_PROVIDER_SCHEDULE,
      payload: { providerId },
    });
  } catch (e) {
    const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!';
    yield put({
      type: UPDATE_PROVIDER_SCHEDULE_FAILED,
      payload: { message },
    });
    yield put(showSnackbar({ snackType: 'error', snackMessage: message }));
  }
}

function* fetchProviderServicesHandler(action: IAction) {
  try {
    console.log('Going to fetch services for provider');
    const { data } = yield call(
      scheduleServices.getProviderServices1,
      action.payload, // admin user must provide providerId
    );

    yield put({
      type: FETCH_PROVIDER_SERVICES1_SUCCESSFUL,
      payload: {
        services: data,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_SERVICES1_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchSystemServicesHandler() {
  try {
    const { data } = yield call(scheduleServices.getAllServices);

    yield put({
      type: FETCH_SYSTEM_SERVICES_SUCCESSFUL,
      payload: {
        services: data,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_SYSTEM_SERVICES_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchAppointmentReportHandler(action: IAction) {
  try {
    const { data } = yield call(scheduleServices.getAppointmentReport, action.payload);

    yield put({
      type: FETCH_APPOINTMENT_REPORT_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_APPOINTMENT_REPORT_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchLevelOfEngagementHandler() {
  try {
    const { data } = yield call(scheduleServices.getLevelOfEngagements);

    yield put({
      type: FETCH_LEVEL_OF_ENGAGEMENTS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_LEVEL_OF_ENGAGEMENTS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchLoeDetailsHandler(action: IAction) {
  try {
    const { data } = yield call(scheduleServices.getLoeDetails, action.payload);
    yield put({
      type: FETCH_LOE_DETAILS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_LOE_DETAILS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* fetchLoeStatusDetails(action: IAction) {
  try {
    const { data } = yield call(scheduleServices.getLoeMembersList, action.payload);
    yield put({
      type: FETCH_LOE_STATUS_DETAILS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_LOE_STATUS_DETAILS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

export default function* scheduleSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_PROVIDER_SCHEDULE, fetchProviderScheduleHandler);
  yield takeLatest(FETCH_DAILY_SCHEDULE, fetchDailyScheduleHandler);
  yield takeLatest(FETCH_PROVIDER_SCHEDULE_PUBLIC, fetchProviderSchedulePublicHandler);
  yield takeLatest(UPDATE_PROVIDER_SCHEDULE, updateProviderScheduleHandler);
  yield takeLatest(FETCH_PROVIDER_SERVICES1, fetchProviderServicesHandler);
  yield takeLatest(FETCH_SYSTEM_SERVICES, fetchSystemServicesHandler);
  yield takeLatest(FETCH_APPOINTMENT_REPORT, fetchAppointmentReportHandler);
  yield takeLatest(FETCH_LEVEL_OF_ENGAGEMENTS, fetchLevelOfEngagementHandler);
  yield takeLatest(FETCH_LOE_DETAILS, fetchLoeDetailsHandler);
  yield takeLatest(FETCH_LOE_STATUS_DETAILS, fetchLoeStatusDetails);
}
