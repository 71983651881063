import GeneralAvailability from './availability';
import EducationExperience from './education-experience';
import GeneralInformation from './general-information';
import Services from './services';

type ComponentT = React.FC<{
  selectedScreen: string;
  providerId?: string;
}>;

const SelectedComponent: ComponentT = ({ selectedScreen, providerId }) => {
  switch (selectedScreen) {
    case 'general-information':
      return <GeneralInformation />;
    case 'education-and-experience':
      return <EducationExperience />;
    case 'services':
      return <Services />;
    case 'availability':
      return <GeneralAvailability providerId={providerId} />;

    default:
      return <div>In Progress...</div>;
  }
};

export default SelectedComponent;
