import { Box } from '@mui/material';
import { colors } from '@confidant-health/lib/colors';
import { Badge, badgeSize } from '@confidant-health/lib/ui/atoms/badge';

import { IPayerGroup } from 'redux/modules/state/types';

import BoxFrame from 'components/v2/BoxFrame';
import Header from 'components/v2/Header';
import { HeadingFont, HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

import { useStyles } from '../index.styles';

type Props = {
  insurances: IPayerGroup[];
};

const AvailableStates = ({ insurances }: Props) => {
  const classes = useStyles();

  return (
    <BoxFrame>
      <Box py={2.5} px={3} sx={{ '& button': { minWidth: 27 } }}>
        <Header
          label="Available states"
          fontFamily={HeadingFont.PRIMARY}
          fontVariant={HeadingLevel.SUB}
          fontWeight={HeadingWeight.SEMI_BOLD}
        />
      </Box>
      <Box p={3} borderTop={`1px solid ${colors.neutral100}`} display="flex" flexDirection="column" gap={4}>
        {insurances?.map((insurance, index) => (
          <Box display="flex" flexDirection="column" gap={2} key={index}>
            <Typography {...typography.body.m.bold}>{insurance.state}</Typography>
            <Box display="flex" gap={1}>
              {insurance?.insurances?.map((item, iindex) => (
                <Badge size={badgeSize.SMALL} className={classes.stateBadge} key={iindex}>
                  <Typography {...typography.body.s.semibold} color={colors.neutral700}>
                    {item?.name}
                  </Typography>
                </Badge>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </BoxFrame>
  );
};

export default AvailableStates;
