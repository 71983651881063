import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  apptCard: {
    background: colors.white,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: '94px',
    gap: '24px',
    alignItems: 'center',
    padding: '24px',
  },
  apptCardContainer: {
    background: colors.white,
    boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  apptCardBody: {
    display: 'flex',
    flex: 1,
    gap: '24px',
    cursor: 'pointer',
    alignItems: 'center',
    width: 'inherit',
    justifyContent: 'space-between',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 24,
    paddingRight: 16,
    paddingTop: 20,
    paddingBottom: 20,
  },
  text18: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  text16: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  text16Second: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: colors.neutral600,
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  btn: {
    color: colors.white,
    textWrap: 'nowrap',
    alignSelf: 'flex-end',
    width: '10rem',
  },
  mid: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    borderTop: `1px solid ${colors.neutral100}`,
  },
  evaluation: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.neutral200}`,
    cursor: 'pointer',
  },
  text12: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
  },
  text14: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  badge: {
    borderRadius: 100,
    height: 18,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
    textWrap: 'nowrap',
  },
  userNameText: {
    display: 'block',
    lineHeight: '20px',
    color: colors.neutral900,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '160px',
  },
  userInfoText: {
    display: 'block',
    lineHeight: '20px',
    color: colors.neutral600,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '160px',
  },
  cancelledStatusText: {
    whiteSpace: 'nowrap',
  },
  btnReschedule: {
    border: `1px solid ${colors.primary300}`,
    color: colors.primary600,
    textWrap: 'nowrap',
    alignSelf: 'flex-end',
    width: '10rem',
  },
});
