import { FC, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import cx from 'clsx';

// components
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeType, badgeStyle } from '@confidant-health/lib/ui/atoms/badge';
import { Link } from '@confidant-health/lib/ui/atoms/link';
import { ProgressBar } from '@confidant-health/lib/ui/atoms/ProgressBar';
import { Drawer, drawerPosition, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';

// icons
import { Icons } from '@confidant-health/lib/icons';

// colors
import { colors } from '@confidant-health/lib/colors';

// types
import { IDCT } from 'redux/modules/conversation/types';
import { ContentBlockResponse } from 'redux/modules/reporting/types';

// actions
import { reportingActionCreators } from 'redux/modules/reporting/actions';
import { getConversation } from 'redux/modules/conversation/selectors';
import { getContentBlockHistory } from 'redux/modules/reporting/selectors';

// styles
import { useStyles } from '../../MemberDetail.styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  userId?: string;
}

const ViewChatbot: FC<IProps> = ({ open, onClose, userId }) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { chatbotContacts, isCBContactsLoading } = useSelector(getConversation);
  const { contentBlockHistory, isLoading: isCBHistoryLoading } = useSelector(getContentBlockHistory);
  const [showHistories, setShowHistories] = useState({});

  console.log({ chatbotContacts });

  const handleShowHistories = (e: MouseEvent, cbId: string) => {
    e.preventDefault();

    if (!showHistories[cbId]) {
      dispatch(reportingActionCreators.fetchContentBlockHistory({ contentBlockId: cbId, userId }));
    }
    setShowHistories({ ...showHistories, [cbId]: !showHistories[cbId] });
  };

  const renderNonScorableDCT = (dct: IDCT) => {
    return (
      <>
        {dct.completed ? (
          <Icons glyph="checkmark" color={colors.success500} />
        ) : (
          <Icons glyph="clear-circle" color={colors.neutral600} />
        )}
        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
          {dct.dctName}
        </Text>
      </>
    );
  };

  const renderScorableDCT = (dct: IDCT) => {
    return (
      <>
        <Badge className={classes.badge} style={badgeStyle.SUCCESS} variant={badgeType.FILLED}>
          Score: {dct.finalScore}
        </Badge>
        <Text level={textLevel.S} weight={fontWeight.MEDIUM}>
          {dct.dctName}
        </Text>
      </>
    );
  };

  const renderDCTs = (dctList: IDCT[]) => {
    return (
      <>
        {dctList?.map((dct: IDCT, index: number) => (
          <div key={index}>{dct.scorable ? renderScorableDCT(dct) : renderNonScorableDCT(dct)}</div>
        ))}
      </>
    );
  };

  const renderHistories = (cbId: string) => {
    const orderedHistory = contentBlockHistory.responseHistory.sort(
      (a, b) => dayjs(b.completedAt, 'YYYYMMDDHHmmSS').unix() - dayjs(a.completedAt, 'YYYYMMDDHHmmSS').unix(),
    );

    return (
      <Collapsable open={!!showHistories[cbId]} headerHidden iconHidden>
        {!isCBHistoryLoading ? (
          orderedHistory.map((resp: ContentBlockResponse, indexInner: number) => (
            <Box key={`content-block-history-${indexInner}`} className={classes.chatBotAnswerWrapper}>
              <Text
                className={cx(classes.chatBotPrevHistory, 'collapsableInfoCardSubTitle')}
                level={textLevel.M}
                weight={fontWeight.MEDIUM}
              >
                {resp.responses ? resp.responses : 'USER DID NOT COMPLETE'}
              </Text>
              <Text
                className={cx(classes.chatBotPrevHistory, 'collapsableInfoCardSubTitle')}
                level={textLevel.M}
                weight={fontWeight.MEDIUM}
              >
                {dayjs(resp.completedAt).format('MMMM D, YYYY - hh:mm a')}
              </Text>
            </Box>
          ))
        ) : (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        )}
      </Collapsable>
    );
  };

  return (
    <Drawer
      variant={drawerType.FORM}
      position={drawerPosition.RIGHT}
      open={open}
      onClose={onClose}
      title={!isCBContactsLoading && chatbotContacts?.name}
      subTitle={chatbotContacts?.version ? `Chatbot v${chatbotContacts.version}` : ''}
      submitBtnTitle="update"
      className={classes.chatBotDrawer}
      footer={
        <div className={classes.chatBotDrawerFooter}>
          <Button onClick={onClose} className={classes.drawerCancelBtn} variant={btnType.TEXT}>
            Close
          </Button>
          {!isCBContactsLoading && (
            <Text className={classes.neutral700} level={textLevel.S} weight={fontWeight.MEDIUM}>
              {chatbotContacts?.percentage}% completed
            </Text>
          )}
        </div>
      }
    >
      {isCBContactsLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <>
          {chatbotContacts?.percentage && (
            <div className={classes.progressWrapper}>
              <Text className={classes.chatBotProgressText} level={textLevel.XS} weight={fontWeight.MEDIUM}>
                {chatbotContacts?.percentage}% completed
              </Text>
              <ProgressBar value={chatbotContacts?.percentage} />
            </div>
          )}
          <div className={classes.progressDetailWrapper}>{renderDCTs(chatbotContacts?.dcts)}</div>
          <div className={classes.collapsableCardsWrapper}>
            {chatbotContacts?.dcts?.map(
              (dct, index) =>
                dct && (
                  <div key={index} className={classes.collapsableWrapper}>
                    {dct?.scorable && (
                      <Text
                        className="collapsableCardScoreText"
                        level={textLevel.S}
                        weight={fontWeight.MEDIUM}
                      >
                        Score: {dct.finalScore}
                      </Text>
                    )}

                    <Collapsable
                      className={classes.drawerCollapsableAccordion}
                      iconPosition={IiconPosition.LEFT}
                      label={dct.dctName}
                      open
                    >
                      <div className="collapsableInfoCards">
                        {dct.responses.map((info, idx) => (
                          <div key={idx} className="collapsableInfoCardWrapper">
                            <Text
                              className="collapsableInfoCardTitle"
                              level={textLevel.M}
                              weight={fontWeight.SEMI_BOLD}
                            >
                              {info.question}
                            </Text>
                            <Box className={classes.chatBotAnswerWrapper}>
                              <Text
                                className="collapsableInfoCardSubTitle"
                                level={textLevel.M}
                                weight={fontWeight.MEDIUM}
                              >
                                {info.answer}
                              </Text>
                            </Box>
                            {contentBlockHistory && renderHistories(info.cbId)}
                            {chatbotContacts.totalCompletions > 1 && (
                              <Link onClick={(e: MouseEvent) => handleShowHistories(e, info.cbId)}>
                                {!showHistories[info.cbId]
                                  ? 'Show previous completions'
                                  : 'Hide previous completions'}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    </Collapsable>
                  </div>
                ),
            )}
          </div>
        </>
      )}
    </Drawer>
  );
};

export default ViewChatbot;
