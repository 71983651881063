import { INavItemType } from '@confidant-health/lib/ui/templates/side-navigation';

export const rightSideNavList: INavItemType[] = [
  {
    text: 'General information',
    query: { type: 'name', value: 'general-information' },
  },
  /* {
    text: 'Education and experience',
    query: { type: 'name', value: 'education-and-experience' },
  }, */
  {
    text: 'Services',
    query: { type: 'name', value: 'services' },
  },
  {
    text: 'Availability',
    query: { type: 'name', value: 'availability' },
  },
];

export enum GeneralInfoSections {
  GENERAL = 'general-information',
  APPROACH = 'approach',
  AVAILABLE_STATES = 'available-states',
}

export enum EducationExperienceSections {
  CLINIC = 'clinic-affiliation',
  PAST_EMPLOYMENT = 'past-employment',
  EDUCATION = 'education',
  CREDENTIALS = 'credentials',
  CERTIFICATIONS = 'certifications',
}

export const educationExperienceSections: { id: string; title: string }[] = [
  {
    id: EducationExperienceSections.CLINIC,
    title: 'Clinic affiliation',
  },
  {
    id: EducationExperienceSections.PAST_EMPLOYMENT,
    title: 'Past employment',
  },
  {
    id: EducationExperienceSections.EDUCATION,
    title: 'Education',
  },
  {
    id: EducationExperienceSections.CREDENTIALS,
    title: 'Credentials',
  },
  {
    id: EducationExperienceSections.CERTIFICATIONS,
    title: 'Certifications',
  },
];

export const certificationsMock = [
  { label: 'PMHNP-BC', value: 'PMHNP-BC' },
  { label: 'ABC', value: 'ABC' },
  { label: 'APRN', value: 'APRN' },
  { label: 'BCD', value: 'BCD' },
  { label: 'DEF', value: 'DEF' },
  { label: 'GHK', value: 'GHK' },
  { label: 'LMN', value: 'LMN' },
  { label: 'OPR', value: 'OPR' },
  { label: 'STU', value: 'STU' },
  { label: 'VWX', value: 'VWX' },
];

export const availableStatesMock = [
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'PMHNP-BC', value: 'PMHNP-BC' },
];

export const credentialsMock = [
  { label: 'ABC', value: 'ABC' },
  { label: 'BCD', value: 'BCD' },
  { label: 'DEF', value: 'DEF' },
  { label: 'GHK', value: 'GHK' },
  { label: 'LMN', value: 'LMN' },
  { label: 'OPR', value: 'OPR' },
  { label: 'STU', value: 'STU' },
  { label: 'VWX', value: 'VWX' },
];

export const helperTabs = [
  { label: 'Related information', tabKey: 'information' },
  { label: 'General information', tabKey: 'general-information' },
  { label: 'Ask AI', tabKey: 'ask_ai' },
  { label: 'Contact us', tabKey: 'contact_us' },
];
