import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, isUndefined } from 'lodash';
import clsx from 'clsx';
import { Box, Modal, Stack } from '@mui/material';

import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { FormControlLabel } from '@confidant-health/lib/ui/atoms/form-control-label';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { Checkbox } from '@confidant-health/lib/ui/atoms/checkbox';
import { Button, btnType, btnSize } from '@confidant-health/lib/ui/atoms/button';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import Loader, { LoaderEnums } from '@confidant-health/lib/ui/templates/loader';
import { positionType, Toggle } from '@confidant-health/lib/ui/atoms/toggle';
// selectors
import { appointmentActionCreators } from 'redux/modules/appointment';
import { selectProviderRoles } from 'redux/modules/appointment/selectors';
import { getAllChoiceTemplates, saveChoiceTemplate } from 'services/conversation/conversation.service';

import { getDisplayLogic } from 'utils/CommonUtils';
import { ContentfulClient } from 'config/contentful-config';
import { showSnackbar } from 'redux/modules/snackbar';

// constants
import {
  BUILDER_CONSTANTS,
  CONTENT_BLOCK_TYPES,
  CONTENT_BLOCK_TYPES_CONVERSATION,
  CONTENT_BLOCK_TYPES_EVALUATION,
  CONVERSATION_TYPES,
  PROFILE_ELEMENT_DEF_TYPE,
  RATING_SCALE_OPTIONS,
} from 'constants/CommonConstants';

// types
import { IDCT1 } from 'redux/modules/conversation/types';
import { JoditUIEditor } from 'pages/admin/content-block-library/components/JoditUIEditor';
import { ISelectOption } from './FlowEditing.types';

// components
import FlowChoice from '../flow-choice';
import {
  flowLogicOptions,
  noAnswerOptions,
  notApplicableOptions,
  responseLengthOptions,
} from './FlowEditing.constant';
import { useStyles } from './FlowEditing.styles';
import { initializeAIConfig } from '../../../../../../utils';

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  flowItem: any;
  editLocked: boolean;
  contentBlocks: any[];
  dcts: IDCT1[];
  profileElements: any[];
  conversationType: string;
  onClose: () => void;
  onDelete: () => void;
  onSave: (payload: any) => void;
  tileLabel?: string;
};

const FlowEditing: FC<Props> = ({
  isLoading,
  isOpen,
  flowItem,
  editLocked,
  contentBlocks,
  dcts,
  profileElements,
  conversationType,
  onClose,
  onSave,
  onDelete,
  tileLabel = '',
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roles = useSelector(selectProviderRoles);
  const isDct = flowItem.basicInfo?.type.toUpperCase() === CONVERSATION_TYPES.DCT;
  const isEvaluation = conversationType === CONVERSATION_TYPES.EVALUATION;
  const isEvaluationDct = conversationType === CONVERSATION_TYPES.EVALUATION_DCT;
  const defaultSelectOption = { label: '', value: '' };
  const [updateComponent, setUpdateComponent] = useState(false);
  const [selectedProviderRole, setSelectedProviderRole] = useState<ISelectOption>(defaultSelectOption);
  const [educationalContents, setEducationalContents] = useState<ISelectOption[]>([]);
  const [selectedEducationalContent, setSelectedEducationalContent] =
    useState<ISelectOption>(defaultSelectOption);
  const [choiceTemplates, setChoiceTemplates] = useState([]);
  const [selectedChoiceTemplate, setSelectedChoiceTemplate] = useState('');
  const [openChoiceTemplateSaveModal, setOpenChoiceTemplateSaveModal] = useState(false);
  const [choiceTemplateName, setChoiceTemplateName] = useState('');

  const otherBlocks = contentBlocks?.filter(
    cb =>
      cb.cbId !== flowItem.cbId &&
      (cb.basicInfo.type === CONTENT_BLOCK_TYPES.SINGLE_SELECT ||
        cb.basicInfo.type === CONTENT_BLOCK_TYPES.MULTI_SELECT ||
        cb.basicInfo.type === CONTENT_BLOCK_TYPES.RATING_SCALE ||
        cb.basicInfo.type === CONTENT_BLOCK_TYPES.TEXT_INPUT),
  );
  const providerRoleOptions = useMemo(
    () => roles.map(role => ({ label: role.designation, value: role.designation })),
    [roles],
  );

  const profileElementOptions = useMemo(
    () =>
      profileElements
        .map(element => ({
          label: element.profileElementInfo.key,
          value: element.profileElementInfo.key,
        }))
        .sort((a, b) => a.label.trim().localeCompare(b?.label?.trim())),
    [profileElements],
  );
  const defaultProfileElement = profileElementOptions.find(
    el => el.value === flowItem.basicInfo?.variableMapping?.name,
  );
  const [currentProfileElement, setCurrentProfileElement] = useState<ISelectOption>(
    defaultProfileElement || defaultSelectOption,
  );
  const [showProfileElement, setShowProfileElement] = useState<boolean>(!!defaultProfileElement);
  const [includeFormat, setIncludeFormat] = useState<boolean>(
    flowItem.aiDocumentationConfig?.includeFormat || false,
  );
  const [includeExample, setIncludeExample] = useState<boolean>(
    flowItem.aiDocumentationConfig?.includeExample || false,
  );
  const [includeRationale, setIncludeRationale] = useState<boolean>(
    flowItem.aiDocumentationConfig?.includeRationale || true,
  );
  const [includeConfidence, setIncludeConfidence] = useState<boolean>(
    flowItem.aiDocumentationConfig?.includeConfidence || false,
  );
  const [includeQuote, setIncludeQuote] = useState<boolean>(
    flowItem.aiDocumentationConfig?.includeQuote || false,
  );

  const dctOptions = useMemo(() => dcts?.map(dct => ({ label: dct.name, value: dct.dctId })), [dcts]);
  const [selectedDct, setSelectedDct] = useState<ISelectOption>(
    dctOptions.find(dct => dct.value === flowItem.dctId),
  );

  const cbType = flowItem.basicInfo?.type;
  const shouldProvideText =
    cbType !== CONTENT_BLOCK_TYPES.FILTERED_PROVIDERS &&
    cbType !== CONTENT_BLOCK_TYPES.TELEHEALTH_SERVICES &&
    cbType !== CONTENT_BLOCK_TYPES.PROVIDER_PROMPT &&
    cbType?.toLowerCase() !== 'dct';
  const showProfilieElementMapping =
    cbType !== CONTENT_BLOCK_TYPES.PROVIDER_MESSAGE &&
    cbType !== CONTENT_BLOCK_TYPES.PROVIDER_PROMPT &&
    cbType !== CONTENT_BLOCK_TYPES.EDUCATION_CONTENT &&
    cbType !== CONTENT_BLOCK_TYPES.FILTERED_PROVIDERS &&
    cbType !== CONTENT_BLOCK_TYPES.TELEHEALTH_SERVICES &&
    cbType !== CONTENT_BLOCK_TYPES.EXERCISE_CONTENT &&
    cbType?.toLowerCase() !== 'dct';

  const onSaveClick = (payload: any) => {
    if (!flowItem.aiDocumentationConfig) {
      flowItem.aiDocumentationConfig = {};
    }
    flowItem.aiDocumentationConfig.includeFormat = includeFormat;
    flowItem.aiDocumentationConfig.includeExample = includeExample;
    flowItem.aiDocumentationConfig.includeRationale = includeRationale;
    flowItem.aiDocumentationConfig.includeConfidence = includeConfidence;
    flowItem.aiDocumentationConfig.includeQuote = includeQuote;
    console.log(flowItem);
    if (flowItem?.basicInfo?.text?.trim() === '') {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Title is required.',
        }),
      );
    } else if (flowItem.basicInfo.type === 'single-select' || flowItem.basicInfo.type === 'multi-select') {
      console.log(flowItem?.basicInfo?.choices);
      if (flowItem?.basicInfo?.choices && flowItem?.basicInfo?.choices !== undefined) {
        if (flowItem.basicInfo.choices.length !== 0) {
          const choicesEmpty = flowItem.basicInfo.choices.filter(item => item.choice === '');

          if (choicesEmpty.length !== 0) {
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: 'Choices cant be empty.',
              }),
            );
          }
          if (choicesEmpty.length === 0) {
            onSave(payload);
            setUpdateComponent(!updateComponent);
          }
        } else {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Choices cant be empty.',
            }),
          );
        }
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Choices cant be empty.',
          }),
        );
      }
    } else if (flowItem.basicInfo.type === 'education') {
      if (
        flowItem?.basicInfo?.educationContentSlug &&
        flowItem?.basicInfo?.educationContentSlug !== undefined
      ) {
        if (flowItem.basicInfo.educationContentSlug === '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Education article cant be empty.',
            }),
          );
        } else {
          onSave(payload);
          setUpdateComponent(!updateComponent);
        }
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Education article cant be empty.',
          }),
        );
      }
    } else if (flowItem.basicInfo.type === 'exercise') {
      if (flowItem?.basicInfo?.exerciseContent && flowItem?.basicInfo?.exerciseContent !== undefined) {
        if (flowItem.basicInfo.exerciseContent.trim() === '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Exercise content cant be empty.',
            }),
          );
        } else {
          onSave(payload);
          setUpdateComponent(!updateComponent);
        }
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Exercise content cant be empty.',
          }),
        );
      }
    } else if (flowItem.basicInfo.type === 'rating-scale') {
      if (
        (flowItem?.basicInfo?.ratingScale?.highLabel || flowItem?.basicInfo?.ratingScale?.lowLabel) &&
        (flowItem?.basicInfo?.ratingScale?.highLabel !== undefined ||
          flowItem?.basicInfo?.ratingScale?.lowLabel.trim() !== undefined)
      ) {
        if (
          flowItem.basicInfo.ratingScale.highLabel.trim() === '' ||
          flowItem.basicInfo.ratingScale.lowLabel.trim() === ''
        ) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Rating Labels cant be empty.',
            }),
          );
        } else {
          onSave(payload);
          setUpdateComponent(!updateComponent);
        }
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Rating Labels cant be empty.',
          }),
        );
      }
    } else {
      onSave(payload);
      setUpdateComponent(!updateComponent);
    }
  };

  // fetch education contents
  const getEducationalContents = () => {
    const params = { content_type: 'educationalContent', limit: 250 };
    ContentfulClient.getEntries(params)
      .then(response => {
        setEducationalContents(
          response.items.map(item => ({ value: item.sys.id, label: item.fields.title })),
        );
      })
      .catch(err => {
        console.log('Fetch educational contents error', err);
      });
  };

  /**
   * @function getAllChoiceTemplates
   * @description This method is used to get all choice templates from db.
   */
  const getAllChoiceTemplatesCall = async () => {
    try {
      const allChoiceTemplates = await getAllChoiceTemplates();
      if (allChoiceTemplates.status !== 200) {
        // AlertUtil.showError(choiceTemplates.errors[0].endUserMessage);
        console.log('Error getting all choice templates');
      } else {
        setChoiceTemplates(allChoiceTemplates.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setUpdateComponent(!updateComponent);
  }, [flowItem?.displayLogics?.key]);

  /**
   * @function useEffect
   * @description This method used to call functions to load data for the first time .
   */
  useEffect(() => {
    dispatch(appointmentActionCreators.fetchProviderRoles());
    getAllChoiceTemplatesCall()
      .then(res => console.log(res))
      .catch(err => console.log(err));
    getEducationalContents();
  }, []);

  const allLogicsComplete = () => {
    return (
      flowItem?.displayLogics &&
      flowItem?.displayLogics.length > 0 &&
      flowItem?.displayLogics.length ===
        flowItem?.displayLogics.filter(logic => logic.type && logic.key && logic.value && logic.rule).length
    );
  };

  const initializeRatingScale = contentBlock => {
    if (!contentBlock.basicInfo.ratingScale) {
      contentBlock.basicInfo.ratingScale = {
        values: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      };
    } else if (
      !contentBlock.basicInfo.ratingScale.values ||
      contentBlock.basicInfo.ratingScale.values.length === 0
    ) {
      contentBlock.basicInfo.ratingScale.values = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    }
  };

  const changeHandlers = {
    basicInfo: {
      onTypeChanged: type => {
        flowItem.basicInfo.type = type;
        console.log('type == ', type);
        if (type === CONTENT_BLOCK_TYPES.RATING_SCALE) {
          initializeRatingScale(flowItem);
        } else if (
          type !== CONTENT_BLOCK_TYPES.EDUCATION_CONTENT &&
          type !== CONTENT_BLOCK_TYPES.EXERCISE_CONTENT
        ) {
          initializeAIConfig(flowItem);
        }
      },
      onTextChanged: text => {
        flowItem.basicInfo.text = text;
      },
      onSubtitleChanged: text => {
        flowItem.subtitle = text;
      },
      onUpdateRequired: checked => {
        flowItem.required = checked;
      },
      onPromptChanged: text => {
        flowItem.prompt = text;
      },
      onHelperTextChanged: text => {
        flowItem.helperText = text;
      },
      onVariableNameChanged: variable => {
        flowItem.basicInfo.referenceId = variable;
      },
      onChoicesChanged: choices => {
        flowItem.basicInfo.choices = choices;
      },
      variableMappingChanged: variableMapping => {
        flowItem.basicInfo.variableMapping = variableMapping;
      },
      profileElementChanged: profileElement => {
        flowItem.relatedProfileElements =
          isUndefined(flowItem.relatedProfileElements) || flowItem.relatedProfileElements?.length < 1
            ? [profileElement]
            : [...flowItem.relatedProfileElements, profileElement];
      },
      onDeleteProfileElement: profileElement => {
        flowItem.relatedProfileElements = flowItem.relatedProfileElements?.filter(
          element => element !== profileElement,
        );
      },
      dctChanged: (dctId: string) => {
        flowItem.dctId = dctId;
      },
      educationItemChanged: (entryId: string) => {
        flowItem.basicInfo.educationContentSlug = entryId;
      },
      exerciseItemChanged: (htmlData: string) => {
        flowItem.basicInfo.exerciseContent = htmlData;
      },
      popupTextChanged: popupText => {
        flowItem.basicInfo.popupText = popupText;
      },
      ratingScaleChanged: (values: string[]) => {
        if (flowItem.basicInfo.ratingScale) {
          flowItem.basicInfo.ratingScale.values = values;
        } else {
          flowItem.basicInfo.ratingScale = { values };
        }
      },
      ratingLowLabelChanged: lowEndLabel => {
        if (flowItem.basicInfo.ratingScale) {
          flowItem.basicInfo.ratingScale.lowLabel = lowEndLabel;
        } else {
          flowItem.basicInfo.ratingScale = {
            lowLabel: lowEndLabel,
          };
        }
      },
      ratingHighLabelChanged: highLabel => {
        if (flowItem.basicInfo.ratingScale) {
          flowItem.basicInfo.ratingScale.highLabel = highLabel;
        } else {
          flowItem.basicInfo.ratingScale = {
            highLabel,
          };
        }
      },
    },
    displayLogic: {
      mainTypeChanged: type => {
        flowItem.mainLogicType = type;
        if (type === 'always' || type === 'RAW') {
          flowItem.displayLogics = [];
          flowItem.ruleAggregator = null;
        } else {
          flowItem.displayLogics = [{ type }];
        }
      },
      rawExpressionChanged: expression => {
        flowItem.rawDisplayLogic = expression;
      },
      logicsUpdated: logics => {
        flowItem.displayLogics = logics.map(logic => {
          if (logic.rule === 'before-today' || logic.rule === 'after-today') {
            logic.value = 'today';
          }
          return logic;
        });
      },
      ruleAggregatorChanged: ruleAggregator => {
        flowItem.ruleAggregator = ruleAggregator;
      },
    },
    routing: {
      routingChanged: routing => {
        flowItem.routing = routing;
      },
      routingOptionChanged: routingOption => {
        flowItem.routingOption = routingOption;
      },
    },
    autoDocConfig: {
      noAnswerResponseChanged: noAnswerResponse => {
        if (flowItem.aiDocumentationConfig) {
          flowItem.aiDocumentationConfig.noAnswerResponse = noAnswerResponse;
        } else {
          flowItem.aiDocumentationConfig = { noAnswerResponse };
        }
      },
      notApplicableResponseChanged: notApplicableResponse => {
        if (flowItem.aiDocumentationConfig) {
          flowItem.aiDocumentationConfig.notApplicableResponse = notApplicableResponse;
        } else {
          flowItem.aiDocumentationConfig = { notApplicableResponse };
        }
      },
      lengthChanged: responseLength => {
        if (flowItem.aiDocumentationConfig) {
          flowItem.aiDocumentationConfig.responseLength = responseLength;
        } else {
          flowItem.aiDocumentationConfig = { responseLength };
        }
      },
      formatChanged: responseFormat => {
        if (flowItem.aiDocumentationConfig) {
          flowItem.aiDocumentationConfig.responseFormatExample = responseFormat;
        } else {
          flowItem.aiDocumentationConfig = { responseFormat };
        }
      },
      exampleChanged: responseFormatExample => {
        if (flowItem.aiDocumentationConfig) {
          flowItem.aiDocumentationConfig.responseFormatExample = responseFormatExample;
        } else {
          flowItem.aiDocumentationConfig = { responseFormatExample };
        }
      },
    },
  };

  /**
   * @function getFilteredProfileElementsBasedOnType
   * @description This method used to get profile elements based on selected type.
   */
  const getFilteredProfileElementsBasedOnType = () => {
    const type = flowItem?.basicInfo?.type || '';
    let filteredProfileElements = profileElements;
    if (type === CONTENT_BLOCK_TYPES.SINGLE_SELECT || type === CONTENT_BLOCK_TYPES.MULTI_SELECT) {
      filteredProfileElements = profileElements?.filter(
        profileElement =>
          profileElement?.profileElementInfo?.type === 'USER_DEFINED_VALUES' ||
          profileElement?.profileElementInfo?.type === 'YES_NO',
      );
    } else if (type === CONTENT_BLOCK_TYPES.RATING_SCALE) {
      filteredProfileElements = profileElements?.filter(
        profileElement =>
          profileElement.profileElementInfo.type === 'USER_DEFINED_VALUES' ||
          profileElement.profileElementInfo.type === 'RATING_SCALE',
      );
    } else if (type === CONTENT_BLOCK_TYPES.TEXT_INPUT) {
      filteredProfileElements = profileElements?.filter(
        profileElement => profileElement?.profileElementInfo?.type === 'TEXT_INPUT',
      );
    }
    filteredProfileElements = profileElements?.sort((a, b) =>
      a?.profileElementInfo?.key?.trim().localeCompare(b?.profileElementInfo?.key?.trim()),
    );
    return filteredProfileElements;
  };

  const getSelectedElementType = logic => {
    const selectedProfileElement = profileElements.filter(
      element => element.profileElementInfo.key === logic.key,
    )[0];
    return PROFILE_ELEMENT_DEF_TYPE[selectedProfileElement?.profileElementInfo?.type];
  };

  const getOperatorValues = logic => {
    let operators = [];
    if (logic.type && logic.key) {
      if (logic.type === 'P') {
        const selectedElementType = getSelectedElementType(logic);
        switch (selectedElementType) {
          case PROFILE_ELEMENT_DEF_TYPE.SCORE_BASED:
          case PROFILE_ELEMENT_DEF_TYPE.RATING_SCALE:
          case PROFILE_ELEMENT_DEF_TYPE.NUMERIC: {
            operators = [
              { value: 'equals', displayName: 'Equals' },
              { value: 'less-than', displayName: 'Less than' },
              { value: 'greater-than', displayName: 'Greater than' },
            ];
            break;
          }
          case PROFILE_ELEMENT_DEF_TYPE.DATE:
          case PROFILE_ELEMENT_DEF_TYPE.DATE_TIME: {
            operators = [
              { value: 'before-today', displayName: 'Before today’s date' },
              { value: 'after-today', displayName: 'After today’s date' },
            ];
            break;
          }
          default: {
            operators = BUILDER_CONSTANTS.DefaultOperators;
            break;
          }
        }
      } else if (logic.type === 'R') {
        operators = BUILDER_CONSTANTS.DefaultOperators;
      }
    }
    return operators;
  };

  const getValidOperatorValue = (logic, index) => {
    if (!logic.rule) {
      // Return null or undefined whatever rule was set
      return logic.rule;
    }
    const operators = getOperatorValues(logic);
    if (operators.map(op => op.value).includes(logic.rule)) {
      return logic.rule;
    }
    let logics = flowItem?.displayLogics;

    logics = logics.map((item, indexInner) => {
      if (indexInner === index) {
        item.rule = null;
      }
      return item;
    });
    changeHandlers.displayLogic.logicsUpdated(logics);
    return null;
  };

  const renderOperatorValues = logic => {
    const operators = getOperatorValues(logic);
    return operators.length === 0 ? [] : operators.map(op => ({ label: op.displayName, value: op.value }));
  };

  const getMainLogicTypeValue = () => {
    if (flowItem?.mainLogicType) {
      return flowItem?.mainLogicType as string;
    }
    if (flowItem.displayLogics && flowItem.displayLogics.length > 0) {
      return flowItem.displayLogics[0].type as string;
    }
    if (flowItem.rawDisplayLogic) {
      return 'RAW';
    }
    return 'always';
  };

  const renderProfileElementValues = logic => {
    const filteredElements = profileElements.filter(element => element.profileElementInfo.key === logic.key);
    if (
      filteredElements.length > 0 &&
      filteredElements[0].profileElementInfo &&
      filteredElements[0].profileElementInfo.values
    ) {
      return filteredElements[0].profileElementInfo.values.map(val => {
        return { label: val, value: val };
      });
    }
    return null;
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  const renderValues = (logic, index) => {
    if (
      !logic.rule ||
      !logic.key ||
      getMainLogicTypeValue() === 'RAW' ||
      getMainLogicTypeValue() === 'always'
    ) {
      return null;
    }

    if (logic.type === 'R') {
      const selectedCb = contentBlocks.find(cb => cb.cbId === logic.key);
      if (!selectedCb) {
        return null;
      }

      if (
        selectedCb.basicInfo.type === CONTENT_BLOCK_TYPES.SINGLE_SELECT ||
        selectedCb.basicInfo.type === CONTENT_BLOCK_TYPES.MULTI_SELECT
      ) {
        return (
          <Box className={classes.section}>
            {renderLabel('Select option')}
            <Select
              value={logic.value || ''}
              variant={selectType.SECONDARY}
              options={selectedCb.basicInfo?.choices?.map(choice => ({
                label: choice.choice,
                value: choice.choice,
              }))}
              emptyText="Select item"
              displayEmpty
              onChange={e => {
                let logics = flowItem.displayLogics;

                logics = logics.map((item, indexInner) => {
                  if (indexInner === index) {
                    item.value = e.target.value;
                  }
                  return item;
                });
                changeHandlers.displayLogic.logicsUpdated(logics);
                setUpdateComponent(!updateComponent);
              }}
            />
          </Box>
        );
      }

      if (
        selectedCb.basicInfo.type === CONTENT_BLOCK_TYPES.TEXT_INPUT ||
        selectedCb.basicInfo.type === CONTENT_BLOCK_TYPES.RATING_SCALE
      ) {
        return (
          <Box className={classes.section}>
            {renderLabel('Enter value')}
            <Input
              value={logic.value || ''}
              placeholder="123"
              onChange={e => {
                let logics = flowItem?.displayLogics;
                logics = logics.map((item, indexInner) => {
                  if (indexInner === index) {
                    item.value = e.target.value;
                  }
                  return item;
                });
                changeHandlers.displayLogic.logicsUpdated(logics);
                setUpdateComponent(!updateComponent);
              }}
              size={inputSize.M}
              fullWidth
            />
          </Box>
        );
      }
    }

    if (logic.type === 'P') {
      const profileElementType = getSelectedElementType(logic);
      if (profileElementType === PROFILE_ELEMENT_DEF_TYPE.SCORE_BASED) {
        return (
          <Box className={classes.section}>
            {renderLabel('Enter value')}
            <Input
              value={logic.value || ''}
              name={`raw-display-logic-${index}`}
              placeholder="123"
              onChange={e => {
                let logics = flowItem?.displayLogics;
                logics = logics.map((item, indexInner) => {
                  if (indexInner === index) {
                    item.value = e.target.value;
                  }
                  return item;
                });
                changeHandlers.displayLogic.logicsUpdated(logics);
                setUpdateComponent(!updateComponent);
              }}
              size={inputSize.M}
              fullWidth
            />
          </Box>
        );
      }
      if (
        profileElementType === PROFILE_ELEMENT_DEF_TYPE.DATE ||
        profileElementType === PROFILE_ELEMENT_DEF_TYPE.DATE_TIME
      ) {
        return null;
      }
      if (profileElementType === PROFILE_ELEMENT_DEF_TYPE.TEXT_INPUT) {
        return (
          <Box className={classes.section}>
            {renderLabel('Enter value')}
            <Input
              value={logic.value || ''}
              name={`raw-display-logic-${index}`}
              placeholder="123"
              onChange={e => {
                let logics = flowItem?.displayLogics;
                logics = logics.map((item, indexInner) => {
                  if (indexInner === index) {
                    item.value = e.target.value;
                  }
                  return item;
                });
                changeHandlers.displayLogic.logicsUpdated(logics);
                setUpdateComponent(!updateComponent);
              }}
              size={inputSize.M}
              fullWidth
            />
          </Box>
        );
      }
    }
    return (
      <div className="field-wrapper">
        <Box className={classes.section}>
          {renderLabel('Select value')}
          <Select
            value={logic.value || ''}
            variant={selectType.SECONDARY}
            options={renderProfileElementValues(logic) || []}
            emptyText="Select item"
            displayEmpty
            onChange={e => {
              let logics = flowItem?.displayLogics;

              logics = logics.map((item, indexInner) => {
                if (indexInner === index) {
                  item.value = e.target.value;
                }
                return item;
              });
              changeHandlers.displayLogic.logicsUpdated(logics);
              setUpdateComponent(!updateComponent);
            }}
            disabled={editLocked}
          />
        </Box>
      </div>
    );
  };

  // useEffect(() => {
  //   if (!isOpen) {
  //     rest.handleReset({ ...defaultValues });
  //     rest.setErrors({});
  //   } else if (flowItem) {
  //     const { items, ...remaing } = flowItem;
  //     items.forEach(async item => {
  //       if (item.type === FLOW_CARD_TYPES.DISPLAY) {
  //         await rest.setFieldValue('logic', item.title);
  //         await rest.setFieldValue('rawDisplayLogic', item.description);
  //       }
  //       if (item.type === FLOW_CARD_TYPES.CHOICES) {
  //         await rest.setFieldValue('choices', item.choices || []);
  //       }
  //       if (item.type === FLOW_CARD_TYPES.ROUTING) {
  //         await rest.setFieldValue('routing', item.title);
  //       }
  //     });
  //     Object.keys(remaing).forEach(key => {
  //       void rest.setFieldValue(key, remaing[key]);
  //     });
  //   }
  // }, [flowItem]);

  // const setTouched = (name: string) => async () => {
  //   await rest.setTouched({ ...touched, [name]: true });
  // };

  const uniqueChoicesName = choices => {
    const uniqueChoices = choices?.map(choice => choice.text);
    return uniqueChoices.length === choices.length;
  };

  const openChoiceTemplateModal = () => {
    setOpenChoiceTemplateSaveModal(true);
  };

  const closeChoiceTemplateModal = () => {
    setOpenChoiceTemplateSaveModal(false);
  };

  /**
   * @function saveChoiceTemplate
   * @description This method is used to save choice template in db.
   * @params payload ( Name , Choices ).
   */
  const onSaveChoiceTemplate = (name: string) => {
    if (name === '') {
      console.log('Please enter template name');
      return;
    }
    // this.setState({ isLoading: true });
    closeChoiceTemplateModal();
    let { choices } = flowItem?.basicInfo;
    choices = choices
      .filter(choice => choice.choice)
      .map(singleChoice => {
        const { choice, score } = singleChoice;
        return { text: choice, score };
      });
    if (choices && choices.length > 0 && uniqueChoicesName(choices)) {
      const choiceRequest = {
        name,
        choices,
      };
      try {
        saveChoiceTemplate(choiceRequest).then(response => {
          if (response.status !== 200) {
            // AlertUtil.showError(response.errors[0].endUserMessage);
            console.log('Error Saving Choice Template');
            // this.setState({ isLoading: false });
          } else {
            // AlertUtil.showSuccess('Choice template saved successfully');
            console.log('Choice template saved successfully');
            getAllChoiceTemplatesCall()
              .then(() => console.log('-'))
              .catch(() => console.log('-'));
            setSelectedChoiceTemplate(name);
            // this.setState({ selectedChoiceTemplate: name, isLoading: false });
          }
        });
      } catch (e) {
        console.log(e);
        // this.setState({ isLoading: false });
      }
    } else {
      // AlertUtil.showError('Invalid Choices');
      console.log('Invalid Choices');
      // this.setState({ isLoading: false });
    }
  };

  const getSelectedChoiceDetail = selectedName => {
    const selectedItem = choiceTemplates.find(template => template.name === selectedName);
    const { name, choices } = selectedItem;
    return { name, choices: choices?.map(choice => ({ choice: choice.text, score: choice.score })) };
  };

  const renderSectionPerType = () => {
    switch (flowItem.basicInfo?.type) {
      case CONTENT_BLOCK_TYPES.EDUCATION_CONTENT:
        return (
          <Box className={classes.section}>
            {renderLabel('Select education article')}
            <Input
              fullWidth
              name="educationArticle"
              size={inputSize.M}
              variant={inputType.AUTOCOMPLETE}
              disabled={editLocked}
              value={selectedEducationalContent}
              getOptionLabel={(option: ISelectOption) => option.label}
              options={educationalContents}
              onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
                changeHandlers.basicInfo.educationItemChanged(newValue.value);
                setSelectedEducationalContent(newValue);
              }}
            />
          </Box>
        );
      case CONTENT_BLOCK_TYPES.EXERCISE_CONTENT:
        return (
          <Box className={classes.section}>
            {renderLabel('Exercise Content')}
            <JoditUIEditor
              htmlValue={flowItem?.basicInfo?.exerciseContent}
              handleChange={changeHandlers.basicInfo.exerciseItemChanged}
            />
          </Box>
        );
      case CONTENT_BLOCK_TYPES.FILTERED_PROVIDERS:
        return (
          <Box className={classes.section}>
            {renderLabel('Designation / Provider role')}
            <Input
              fullWidth
              name="providersRole"
              size={inputSize.M}
              variant={inputType.AUTOCOMPLETE}
              disabled={editLocked}
              value={selectedProviderRole}
              getOptionLabel={(option: ISelectOption) => option.label}
              // renderOption={(props: any, option: ISelectOption) => (
              //   <Box key={option.value} {...props}>
              //     {option.label}
              //   </Box>
              // )}
              options={providerRoleOptions}
              onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
                changeHandlers.basicInfo.popupTextChanged(newValue.value);
                setSelectedProviderRole(newValue);
              }}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  const renderChoicesBlock = () => {
    switch (flowItem.basicInfo?.type) {
      case CONTENT_BLOCK_TYPES.RATING_SCALE:
        return (
          <Box className={classes.formBlock}>
            <Box className={classes.blockHeader}>
              <Text className={classes.title}>Manage scale</Text>
            </Box>
            <Box display="flex" alignItems="center" gap={3}>
              <Box className={classes.section} flex={1}>
                {renderLabel('Low end label')}
                <Input
                  fullWidth
                  id="ratingLowEndLabel"
                  name="ratingLowEndLabel"
                  value={flowItem.basicInfo?.ratingScale?.lowLabel || ''}
                  placeholder="Enter Low End Label"
                  size={inputSize.M}
                  onChange={e => {
                    changeHandlers.basicInfo.ratingLowLabelChanged(e.target.value);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
              <Box className={classes.section} flex={1}>
                {renderLabel('High end label')}
                <Input
                  fullWidth
                  id="ratingHighEndLabel"
                  name="ratingHighEndLabel"
                  value={flowItem.basicInfo?.ratingScale?.highLabel || ''}
                  placeholder="Enter High End Label"
                  size={inputSize.M}
                  onChange={e => {
                    changeHandlers.basicInfo.ratingHighLabelChanged(e.target.value);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gap={3}>
              <Box className={classes.section} flex={1}>
                {renderLabel('Options in scale')}
                <Select
                  value={flowItem.basicInfo?.ratingScale?.values?.length || 10}
                  variant={selectType.SECONDARY}
                  options={RATING_SCALE_OPTIONS.map((value: number) => ({
                    label: `${value}`,
                    value: `${value}`,
                  }))}
                  displayEmpty
                  emptyText="Select item"
                  onChange={e => {
                    let start = 1;
                    if (
                      flowItem.basicInfo?.ratingScale?.values &&
                      flowItem.basicInfo?.ratingScale?.values.length > 0
                    ) {
                      start = parseInt(flowItem.basicInfo?.ratingScale.values[0], 10);
                    }
                    let end = e.target.value;
                    if (start === 0) {
                      end -= 1;
                    }
                    const values = [];
                    for (let v = start; v <= end; v++) {
                      values.push(`${v}`);
                    }
                    changeHandlers.basicInfo.ratingScaleChanged(values);
                    setUpdateComponent(!updateComponent);
                  }}
                  disabled={editLocked}
                />
              </Box>
              <Box className={classes.section} flex={1}>
                {renderLabel('Scale starts at')}
                <Select
                  value={
                    flowItem.basicInfo?.ratingScale?.values &&
                    flowItem.basicInfo?.ratingScale?.values.length > 0
                      ? flowItem.basicInfo?.ratingScale.values[0]
                      : '1'
                  }
                  variant={selectType.SECONDARY}
                  displayEmpty
                  emptyText="Select item"
                  disabled={editLocked}
                  options={[
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                  ]}
                  onChange={e => {
                    let totalValues = 10;
                    if (
                      flowItem.basicInfo?.ratingScale?.values &&
                      flowItem.basicInfo?.ratingScale?.values.length > 0
                    ) {
                      totalValues = flowItem.basicInfo?.ratingScale.values.length;
                    }
                    if (e.target.value === 1) {
                      totalValues += 1;
                    }
                    const values = [];
                    for (let v = e.target.value; v < totalValues; v++) {
                      values.push(`${v}`);
                    }
                    changeHandlers.basicInfo.ratingScaleChanged(values);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
            </Box>
          </Box>
        );
      case CONTENT_BLOCK_TYPES.TEXT_INPUT:
        if (isEvaluation || isEvaluationDct) {
          return (
            <Box className={classes.formBlock}>
              <Box className={classes.blockHeader}>
                <Text className={classes.title}>Manage input</Text>
              </Box>
              <Box className={clsx(classes.section, classes.noMb)}>
                {renderLabel('Helper Text')}
                <Input
                  fullWidth
                  value={flowItem.helperText}
                  name="helperText"
                  placeholder="Enter Helper Text"
                  onChange={e => {
                    changeHandlers.basicInfo.onHelperTextChanged(e.target.value);
                    setUpdateComponent(!updateComponent);
                  }}
                  size={inputSize.M}
                />
              </Box>
            </Box>
          );
        }
        return null;
      case CONTENT_BLOCK_TYPES.SINGLE_SELECT:
      case CONTENT_BLOCK_TYPES.MULTI_SELECT:
        return (
          <Box className={classes.formBlock}>
            <Box className={classes.blockHeader}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                <Text className={classes.title}>Manage choices</Text>
                <Stack direction="row" alignItems="center" gap={1}>
                  <IconButton
                    icon="save-outlined"
                    className={classes.saveTempBtn}
                    onClick={() => {
                      openChoiceTemplateModal();
                    }}
                  >
                    Save as template
                  </IconButton>
                  <Select
                    name="choiceTemplate"
                    variant={selectType.PRIMARY}
                    disabled={editLocked}
                    displayEmpty
                    emptyText="Use template"
                    value={selectedChoiceTemplate}
                    options={choiceTemplates?.map(choiceTemplate => ({
                      value: choiceTemplate.name,
                      label: choiceTemplate.name,
                    }))}
                    onChange={e => {
                      const selectedItemDetail = getSelectedChoiceDetail(e.target.value);
                      if (selectedItemDetail) {
                        const { choices } = selectedItemDetail;
                        setSelectedChoiceTemplate(e.target.value);
                        changeHandlers.basicInfo.onChoicesChanged(choices);
                        setUpdateComponent(!updateComponent);
                      }
                    }}
                  />
                </Stack>
              </Stack>
            </Box>

            <Box display="flex" flexDirection="column" gap={2}>
              {flowItem?.basicInfo?.choices?.map((choice, index) => (
                <FlowChoice
                  key={index}
                  item={choice}
                  isCurrentElementExisting={showProfileElement}
                  options={profileElements
                    .find(
                      element => element.profileElementInfo.key === flowItem.basicInfo?.variableMapping?.name,
                    )
                    ?.profileElementInfo?.values?.map(val => ({ label: val, value: val }))}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>, fieldName) => {
                    const newChoices = [...flowItem.basicInfo.choices];
                    if (fieldName === 'choice') {
                      newChoices[index].choice = e.target.value;
                    } else if (fieldName === 'score') {
                      newChoices[index].score = e.target.value;
                    } else if (fieldName === 'value') {
                      newChoices[index].value = e.target.value;
                    } else if (fieldName === 'riskFactor') {
                      newChoices[index].riskFactor = e.target.checked;
                      console.log('riskFactor:', newChoices[index].riskFactor);
                    }
                    setUpdateComponent(!updateComponent);
                  }}
                  handleChangeSelect={(value: string) => {
                    changeHandlers.basicInfo.onChoicesChanged(
                      flowItem.basicInfo?.choices?.map((item, indexInner) => {
                        if (indexInner === index) {
                          item.value = value;
                        }
                        return item;
                      }),
                    );
                    setUpdateComponent(!updateComponent);
                  }}
                  onDuplicateChoice={() => {
                    changeHandlers.basicInfo.onChoicesChanged([
                      ...flowItem?.basicInfo?.choices,
                      { ...choice },
                    ]);
                    setUpdateComponent(!updateComponent);
                  }}
                  onDeleteChoice={() => {
                    const newChoices = [...flowItem?.basicInfo?.choices];
                    newChoices.splice(index, 1);
                    changeHandlers.basicInfo.onChoicesChanged(newChoices);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              ))}
              <Box>
                <IconButton
                  icon="plus"
                  disabled={editLocked}
                  className={classes.addIconBtn}
                  onClick={() => {
                    if (flowItem?.basicInfo?.choices) {
                      changeHandlers.basicInfo.onChoicesChanged([
                        ...flowItem?.basicInfo?.choices,
                        { choice: '', score: 0, value: '', riskFactor: false },
                      ]);
                    } else {
                      changeHandlers.basicInfo.onChoicesChanged([
                        { choice: '', score: 0, value: '', riskFactor: false },
                      ]);
                    }
                    setUpdateComponent(!updateComponent);
                  }}
                >
                  Add Choice
                </IconButton>
              </Box>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const renderGeneralBlock = () => (
    <Box className={clsx(classes.formBlock, classes.formBlockNoBorder)}>
      <Box className={classes.blockHeader}>
        <Text className={classes.title}>General</Text>
      </Box>
      {shouldProvideText && (
        <Box className={classes.section}>
          {renderLabel(isEvaluation || isEvaluationDct ? 'Title' : 'Text prompt')}
          <Textarea
            value={flowItem.basicInfo?.text}
            name="message"
            type="text"
            placeholder={
              isEvaluation || isEvaluationDct ? 'Enter Title' : 'Enter chatbot message for user here ...'
            }
            onChange={e => {
              changeHandlers.basicInfo.onTextChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
            minRows={isEvaluation || isEvaluationDct ? 1 : 2}
            maxRows={5}
            className={classes.textarea}
          />
        </Box>
      )}
      {(isEvaluation || isEvaluationDct) && !isDct && (
        <Box className={classes.section}>
          {renderLabel('Subtitle')}
          <Textarea
            value={flowItem.subtitle}
            name="subtitle"
            type="text"
            placeholder="Enter Subtitle"
            onChange={e => {
              changeHandlers.basicInfo.onSubtitleChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
            minRows={2}
            maxRows={5}
            className={classes.textarea}
          />
        </Box>
      )}
      <Box className={classes.section}>
        {renderLabel('Variable name')}
        <Input
          fullWidth
          name="referenceId"
          value={flowItem.variableName}
          placeholder="Enter Variable Name"
          onChange={e => {
            changeHandlers.basicInfo.onVariableNameChanged(e.target.value);
            setUpdateComponent(!updateComponent);
          }}
          size={inputSize.M}
        />
      </Box>
      {(isEvaluation || isEvaluationDct) && !isDct && (
        <Box className={clsx(classes.section)} gap="32px">
          <Toggle
            checked={flowItem.required}
            labelPosition={positionType.RIGHT}
            onChange={checked => {
              changeHandlers.basicInfo.onUpdateRequired(checked);
            }}
          >
            Required
          </Toggle>
        </Box>
      )}
      {(isEvaluation || isEvaluationDct) && !isDct && (
        <Box className={clsx(classes.section)}>
          {renderLabel('Prompt Text')}
          <Input
            fullWidth
            value={flowItem.prompt}
            name="prompt"
            placeholder="Enter Prompt Text"
            onChange={e => {
              changeHandlers.basicInfo.onPromptChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
            size={inputSize.M}
          />
        </Box>
      )}
      <Box className={classes.section}>
        {renderLabel(isDct ? 'Select a DCT' : 'Content block type')}
        {isDct ? (
          <Input
            fullWidth
            name="dct"
            size={inputSize.M}
            variant={inputType.AUTOCOMPLETE}
            value={selectedDct}
            options={dctOptions}
            getOptionLabel={(option: ISelectOption) => option.label}
            onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
              changeHandlers.basicInfo.dctChanged(newValue.value);
              setSelectedDct(newValue);
            }}
            disabled={editLocked}
          />
        ) : (
          <Select
            name="contentBlockType"
            value={flowItem.basicInfo?.type}
            variant={selectType.SECONDARY}
            options={Object.values(
              isEvaluation || isEvaluationDct
                ? CONTENT_BLOCK_TYPES_EVALUATION
                : CONTENT_BLOCK_TYPES_CONVERSATION,
            ).map((value: string) => ({
              label: capitalize(value).replaceAll('-', ' '),
              value,
            }))}
            emptyText="Select item"
            displayEmpty
            onChange={e => {
              changeHandlers.basicInfo.onTypeChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
            disabled={editLocked}
          />
        )}
      </Box>
      {showProfilieElementMapping && (
        <FormControlLabel
          control={<Checkbox />}
          checked={showProfileElement}
          name="showProfileElement"
          label="Maps to profile element"
          onChange={() => setShowProfileElement(!showProfileElement)}
        />
      )}
      {showProfilieElementMapping && showProfileElement && (
        <Box className={classes.section}>
          {renderLabel('Profile element')}
          <Input
            fullWidth
            name="profileElement"
            size={inputSize.M}
            variant={inputType.AUTOCOMPLETE}
            disabled={editLocked}
            value={currentProfileElement}
            getOptionLabel={(option: ISelectOption) => option.label}
            options={profileElementOptions}
            onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
              if (newValue) {
                changeHandlers.basicInfo?.variableMappingChanged({
                  scope: 'profile', // flowItem.basicInfo?.variableMapping.scope
                  name: newValue.value,
                });
                setCurrentProfileElement(newValue);
              } else {
                changeHandlers.basicInfo?.variableMappingChanged(null);
                setCurrentProfileElement(null);
              }
            }}
          />
        </Box>
      )}
      {/* <FormControlLabel
        control={<Checkbox />}
        checked={useProfileElement}
        name="useProfileElement"
        label="Use profile element values"
        onChange={() => setUseProfileElement(!useProfileElement)}
      /> */}
      {renderSectionPerType()}
    </Box>
  );

  const renderDisplayBlock = () => {
    const selectOptions = getFilteredProfileElementsBasedOnType().map(profileElement => ({
      label: `${profileElement.profileElementInfo.key}`,
      value: profileElement.profileElementInfo.key,
    }));
    return (
      <Box className={classes.formBlock}>
        <Box className={classes.blockHeader}>
          <Text className={classes.title}>Display logic</Text>
        </Box>
        <Box className={classes.section}>
          {renderLabel('Select logic')}
          <Select
            value={getMainLogicTypeValue()}
            name="logic"
            variant={selectType.SECONDARY}
            options={isEvaluation ? flowLogicOptions.slice(0, 2) : flowLogicOptions}
            emptyText="Select item"
            displayEmpty
            disabled={editLocked}
            onChange={e => {
              changeHandlers.displayLogic.mainTypeChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
          />
        </Box>
        {flowItem?.displayLogics?.map((logic, index) => (
          <Box key={index} display="flex" flexDirection="column" gap={3}>
            {index > 0 && (
              <Box className={classes.section} flex={1}>
                {renderLabel('Select display logic')}
                <Box display="flex" alignItems="center" gap={3}>
                  <Select
                    value={logic.type || ''}
                    variant={selectType.SECONDARY}
                    emptyText="Select item"
                    displayEmpty
                    style={{ flex: 1 }}
                    disabled={editLocked}
                    options={[
                      { label: 'If Conversation Variable', value: 'R' },
                      ...(isEvaluation ? [] : [{ label: 'If Profile Element', value: 'P' }]),
                    ]}
                    onChange={e => {
                      let logics = flowItem?.displayLogics;

                      logics = logics.map((item, ind) => {
                        if (ind === index && item.type !== e.target.value) {
                          item.type = e.target.value;
                          item.key = null;
                          item.value = null;
                        }
                        return item;
                      });
                      changeHandlers.displayLogic.logicsUpdated(logics);
                      setUpdateComponent(!updateComponent);
                    }}
                  />
                  <IconButton
                    icon="delete-outlined-2"
                    className={classes.removeIconBtn}
                    onClick={() => {
                      const logics = flowItem?.displayLogics;
                      logics.splice(index, 1);
                      changeHandlers.displayLogic.logicsUpdated(logics);
                      setUpdateComponent(!updateComponent);
                    }}
                  />
                </Box>
              </Box>
            )}
            {logic.type === 'R' && (
              <Box className={classes.section}>
                {renderLabel('Select content block')}
                <Select
                  value={logic.key || ''}
                  variant={selectType.SECONDARY}
                  options={otherBlocks.map(cb => ({
                    label: `${cb.basicInfo.referenceId}: ${cb.basicInfo.text || cb.basicInfo.name}`,
                    value: cb.cbId,
                  }))}
                  emptyText="Select item"
                  displayEmpty
                  onChange={e => {
                    let logics = flowItem?.displayLogics;
                    logics = logics.map((item, indexInner) => {
                      if (indexInner === index && item.key !== e.target.value) {
                        console.log('key Value', e.target.value);
                        item.key = e.target.value;
                        item.value = null;
                      }
                      return item;
                    });
                    changeHandlers.displayLogic.logicsUpdated(logics);
                    setUpdateComponent(!updateComponent);
                  }}
                  disabled={editLocked}
                />
              </Box>
            )}
            {logic.type === 'P' && (
              <Box className={classes.section}>
                {renderLabel('Select profile element')}
                <Input
                  fullWidth
                  name="profileElement"
                  size={inputSize.M}
                  variant={inputType.AUTOCOMPLETE}
                  disabled={editLocked}
                  value={selectOptions.find(el => el.value === logic.key) || defaultSelectOption}
                  getOptionLabel={(option: ISelectOption) => option.label}
                  options={selectOptions}
                  onChange={(_: SyntheticEvent, newValue: ISelectOption) => {
                    let logics = flowItem?.displayLogics;
                    logics = logics.map((item, indexInner) => {
                      if (indexInner === index && item.key !== newValue.value) {
                        item.key = newValue.value;
                        item.rule = null;
                        item.value = null;
                      }
                      return item;
                    });
                    changeHandlers.displayLogic.logicsUpdated(logics);
                    setUpdateComponent(!updateComponent);
                  }}
                />
              </Box>
            )}
            {logic.key && (
              <Box className={classes.section}>
                {renderLabel('Select rule')}
                <Select
                  value={getValidOperatorValue(logic, index) || ''}
                  variant={selectType.SECONDARY}
                  options={renderOperatorValues(logic)}
                  emptyText="Select item"
                  displayEmpty
                  onChange={e => {
                    let logics = flowItem?.displayLogics;

                    logics = logics?.map((item, indexInner) => {
                      if (indexInner === index) {
                        item.rule = e.target.value;
                      }
                      return item;
                    });
                    changeHandlers.displayLogic.logicsUpdated(logics);
                    setUpdateComponent(!updateComponent);
                  }}
                  disabled={editLocked}
                />
              </Box>
            )}
            {renderValues(logic, index)}
            {index === 0 && flowItem?.displayLogics && flowItem.displayLogics.length > 1 && (
              <Box className={classes.section}>
                {renderLabel('Rule aggregator')}
                <Select
                  value={flowItem?.ruleAggregator || ''}
                  variant={selectType.SECONDARY}
                  options={[
                    { label: 'AND', value: 'and' },
                    { label: 'OR', value: 'or' },
                  ]}
                  emptyText="Select item"
                  displayEmpty
                  onChange={e => {
                    changeHandlers.displayLogic.ruleAggregatorChanged(e.target.value);
                    setUpdateComponent(!updateComponent);
                  }}
                  disabled={editLocked}
                />
              </Box>
            )}
          </Box>
        ))}
        {getMainLogicTypeValue() !== 'RAW' &&
          flowItem?.displayLogics &&
          flowItem?.displayLogics.length > 0 &&
          allLogicsComplete() && (
            <IconButton
              icon="plus"
              className={classes.addIconBtn}
              disabled={editLocked}
              onClick={() => {
                changeHandlers.displayLogic.logicsUpdated([...flowItem?.displayLogics, { rule: 'equals' }]);
                setUpdateComponent(!updateComponent);
              }}
            >
              Add Another
            </IconButton>
          )}
      </Box>
    );
  };

  const renderRelatedInformationBlock = () => {
    return (
      <Box className={classes.formBlock}>
        <Box className={classes.blockHeader}>
          <Text className={classes.title}>Related information</Text>
        </Box>
        <Box className={classes.section}>
          {renderLabel('Select profile element')}
          <Input
            fullWidth
            name="relatedProfileElements"
            variant={inputType.TAGS}
            size={inputSize.M}
            value={flowItem?.relatedProfileElements || []}
            getOptionLabel={(option: string) => option}
            options={profileElements.map(element => element.profileElementInfo.key)}
            onChange={(_: SyntheticEvent, newValue: string) => {
              flowItem.relatedProfileElements = newValue;
              setUpdateComponent(!updateComponent);
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderAiDocumentationConfig = () => {
    return (
      <Box className={classes.formBlock}>
        <Box className={classes.blockHeader}>
          <Text className={classes.title}>AI Documentation</Text>
        </Box>
        <Box className={classes.section}>
          {renderLabel(`Response when AI can't answer`)}
          <Select
            value={flowItem?.aiDocumentationConfig?.noAnswerResponse || 'Not Discussed'}
            name="noAnswerResponse"
            variant={selectType.SECONDARY}
            options={noAnswerOptions}
            disabled={editLocked}
            onChange={e => {
              changeHandlers.autoDocConfig.noAnswerResponseChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
          />
        </Box>
        <Box className={classes.section}>
          {renderLabel(`Response when not applicable to patient`)}
          <Select
            value={flowItem?.aiDocumentationConfig?.notApplicableResponse || 'Not Applicable'}
            name="notApplicableResponse"
            variant={selectType.SECONDARY}
            options={notApplicableOptions}
            disabled={editLocked}
            onChange={e => {
              changeHandlers.autoDocConfig.notApplicableResponseChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
          />
        </Box>
        <Box className={classes.section}>
          {renderLabel(`Length of response`)}
          <Select
            value={flowItem?.aiDocumentationConfig?.responseLength || 'short-with-description'}
            name="responseLength"
            variant={selectType.SECONDARY}
            options={responseLengthOptions}
            disabled={editLocked}
            onChange={e => {
              changeHandlers.autoDocConfig.lengthChanged(e.target.value);
              setUpdateComponent(!updateComponent);
            }}
          />
        </Box>
        <FormControlLabel
          control={<Checkbox />}
          checked={includeFormat}
          name="includeFormat"
          label="Include format"
          onChange={() => setIncludeFormat(!includeFormat)}
        />
        {includeFormat && (
          <>
            <Box className={clsx(classes.section)}>
              {renderLabel('Format of response')}
              <Input
                fullWidth
                value={flowItem?.aiDocumentationConfig?.responseFormat || ''}
                name="responseFormat"
                placeholder="Enter format here"
                onChange={e => {
                  changeHandlers.autoDocConfig.formatChanged(e.target.value);
                  setUpdateComponent(!updateComponent);
                }}
                size={inputSize.M}
              />
            </Box>
            <FormControlLabel
              control={<Checkbox />}
              checked={includeExample}
              name="includeExample"
              label="Include an example"
              onChange={() => setIncludeExample(!includeExample)}
            />
            {includeExample && (
              <Box className={classes.section}>
                {renderLabel('Format example')}
                <Textarea
                  value={flowItem?.aiDocumentationConfig?.responseFormatExample || ''}
                  name="responseFormatExample"
                  type="text"
                  placeholder="Enter an example here"
                  onChange={e => {
                    changeHandlers.autoDocConfig.exampleChanged(e.target.value);
                    setUpdateComponent(!updateComponent);
                  }}
                  minRows={2}
                  maxRows={5}
                  className={classes.textarea}
                />
              </Box>
            )}
          </>
        )}
        <FormControlLabel
          control={<Checkbox />}
          checked={includeRationale}
          name="includeRationale"
          label="Include rationale"
          onChange={() => setIncludeRationale(!includeRationale)}
        />
        <FormControlLabel
          control={<Checkbox />}
          checked={includeConfidence}
          name="includeRationale"
          label="Include confidence (0-10 scale)"
          onChange={() => setIncludeConfidence(!includeConfidence)}
        />
        <FormControlLabel
          control={<Checkbox />}
          checked={includeQuote}
          name="includeQuote"
          label="Include quote from transcript"
          onChange={() => setIncludeQuote(!includeQuote)}
        />
      </Box>
    );
  };

  const renderRoutingBlock = () => (
    <Box className={classes.formBlock}>
      <Box className={classes.blockHeader}>
        <Text className={classes.title}>Routing</Text>
      </Box>
      <Box className={classes.section}>
        {renderLabel('Routing method')}
        <Select
          value={flowItem.routingOption || (flowItem.routing ? 'SPECIFIC' : 'END')}
          variant={selectType.SECONDARY}
          options={[
            { label: 'Specific List of Content Blocks', value: 'SPECIFIC' },
            { label: 'Next Content Block', value: 'NEXT' },
            { label: 'End Of Conversation/DCT', value: 'END' },
          ]}
          emptyText="Select item"
          displayEmpty
          onChange={e => {
            changeHandlers.routing.routingOptionChanged(e.target.value);
            if (e.target.value === 'END') {
              changeHandlers.routing.routingChanged(null);
            } else if (e.target.value === 'NEXT') {
              changeHandlers.routing.routingChanged(null);
            } else {
              changeHandlers.routing.routingChanged(['']);
            }
            setUpdateComponent(!updateComponent);
          }}
          disabled={editLocked}
        />
      </Box>
      {flowItem.routing?.map((cbId, index) => (
        <Box key={`div-${index}-selector`}>
          <Box className={classes.section} flex={1}>
            {renderLabel(`Select content block`)}
            <Box display="flex" alignItems="center" gap={3}>
              <Select
                truncate
                truncateAt={64}
                displayEmpty
                emptyText="Select item"
                disabled={editLocked}
                value={cbId}
                variant={selectType.SECONDARY}
                options={contentBlocks
                  .filter(
                    cb =>
                      cb.cbId !== flowItem.cbId && (cb.cbId === cbId || !flowItem.routing.includes(cb.cbId)),
                  )
                  .map(cb => {
                    return {
                      label: `${cb.basicInfo.referenceId}: ${cb.basicInfo.text || cb.basicInfo.name}`,
                      value: cb.cbId,
                    };
                  })}
                onChange={e => {
                  const { routing } = flowItem;
                  routing.splice(index, 1, e.target.value);
                  changeHandlers.routing.routingChanged(routing);
                  setUpdateComponent(!updateComponent);
                }}
                style={{ flex: 1 }}
              />
              {index > 0 && (
                <IconButton
                  icon="delete-outlined-2"
                  className={classes.removeIconBtn}
                  onClick={() => {
                    const { routing } = flowItem;
                    routing.splice(index, 1);
                    changeHandlers.routing.routingChanged(routing);
                    setUpdateComponent(!updateComponent);
                  }}
                  disabled={editLocked}
                />
              )}
            </Box>
          </Box>
          {cbId !== '' && (
            <p className="display-logics-text">
              Display Logic: {getDisplayLogic(cbId, contentBlocks, flowItem)}
            </p>
          )}
        </Box>
      ))}
      {flowItem.routing && (
        <Box>
          <IconButton
            icon="plus"
            disabled={editLocked}
            className={classes.addIconBtn}
            onClick={() => {
              changeHandlers.routing.routingChanged([...flowItem.routing, '']);
              setUpdateComponent(!updateComponent);
            }}
          >
            Add Content Block
          </IconButton>
        </Box>
      )}
    </Box>
  );

  const renderDangerZone = () => (
    <Box className={classes.formBlock} alignItems="flex-start">
      <Text className={classes.title}>Danger zone</Text>
      <IconButton
        icon="delete-outlined-2"
        className={classes.removeContentBtn}
        onClick={onDelete}
        disabled={editLocked}
      >
        Remove content block
      </IconButton>
    </Box>
  );

  return (
    <>
      <Modal open={openChoiceTemplateSaveModal} onClose={closeChoiceTemplateModal}>
        <Box className={classes.modal}>
          <Box className={classes.modalContent}>
            <Box className={classes.content}>
              <Box className={classes.section}>
                {renderLabel('Choice template name')}
                <Input
                  fullWidth
                  size={inputSize.M}
                  placeholder="Choice Template Name"
                  value={choiceTemplateName}
                  onChange={e => {
                    setChoiceTemplateName(e.target.value);
                  }}
                />
              </Box>
              <Box display="flex" alignItems="center" gap={3} mt={3}>
                <Button size={btnSize.SMALL} onClick={() => onSaveChoiceTemplate(choiceTemplateName)}>
                  Save
                </Button>
                <Button size={btnSize.SMALL} onClick={closeChoiceTemplateModal}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Drawer
        open={isOpen}
        onClose={onClose}
        variant={drawerType.FORM}
        title={tileLabel !== '' ? tileLabel : 'Edit content block'}
        subTitle={tileLabel !== '' ? 'what-happened' : null}
        className={classes.drawer}
        footer={
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onSaveClick}>Save</Button>
          </Box>
        }
      >
        {isLoading ? (
          <Box padding="32px 16px">
            <Loader type={LoaderEnums.SLOTS} numItems={1} />
          </Box>
        ) : (
          <Box>
            <Box className={classes.formContent}>
              {renderGeneralBlock()}
              {renderChoicesBlock()}
              {renderDisplayBlock()}
              {!isEvaluation && !isEvaluationDct && renderRoutingBlock()}
              {(isEvaluation || isEvaluationDct) && renderRelatedInformationBlock()}
              {(isEvaluation || isEvaluationDct) &&
                flowItem.basicInfo.type !== CONTENT_BLOCK_TYPES.EXERCISE_CONTENT &&
                flowItem.basicInfo.type !== CONTENT_BLOCK_TYPES.EDUCATION_CONTENT &&
                flowItem?.type !== 'dct' &&
                renderAiDocumentationConfig()}
              {renderDangerZone()}
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  );
};

export { FlowEditing };
