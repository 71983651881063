import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Box, Stack } from '@mui/material';
import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { BaseLayout } from 'layouts/base';
import {
  getTagDetail,
  getTagReport,
  getTagAssignmentDetails,
} from 'services/conversation/conversation.service';

import AssignmentMethodCard from './components/assignments-method-card';
import AssignmentsCard from './components/assignments-chart-card';
import AssignmentsTableCard from './components/assignments-table-card';
import { useStyles } from './TagDetail.styles';

const TagDetail: FC = () => {
  const classes = useStyles();
  const [tagDetail, setTagDetail] = useState(null);
  const [tagReport, setTagReport] = useState([]);
  const [tagAssignment, setTagAssignment] = useState(null);
  // const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { tagId } = useParams();
  /**
   * @Name getTagDetailOnScreen
   * @description This method is used get tag details from BE
   */
  const getTagDetailOnScreen = () => {
    setIsLoading(true);
    getTagDetail({ tagId })
      .then(tagDetailResponse => {
        console.log({ tagDetailResponse });
        if (tagDetailResponse?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          setTagDetail(tagDetailResponse?.data);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  };

  /**
   * @Name getTagReportOnScreen
   * @description This method is used get tag reports list from BE
   */
  const getTagReportOnScreen = () => {
    getTagReport({ tagId })
      .then(tagReportResponse => {
        console.log({ tagReportResponse });
        if (tagReportResponse?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          setTagReport(tagReportResponse?.data);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  };

  /**
   * @Name getTagsAssignmentDetail
   * @description This method is used get tags assignment detail from BE
   */
  const getTagsAssignmentDetailOnScreen = () => {
    getTagAssignmentDetails({ tagId })
      .then(tagAssignmentResponse => {
        console.log({ tagAssignmentResponse });
        if (tagAssignmentResponse?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          setTagAssignment(tagAssignmentResponse?.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTagDetailOnScreen();
    getTagReportOnScreen();
    getTagsAssignmentDetailOnScreen();
  }, []);

  console.log({ tagAssignment });
  return (
    <>
      <BaseLayout>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Breadcrumbs
              links={[{ href: '/admin/data-management-tags', text: 'Tags' }, { text: tagDetail?.name }]}
            />
          </Box>
          <Box>
            {tagDetail && (
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Stack direction="column" gap={1}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                      {tagDetail?.name}
                    </Heading>
                    <Badge
                      style={badgeStyle[tagDetail?.importance?.toUpperCase()]}
                      className={clsx(classes.badge, tagDetail?.importance)}
                      variant={badgeType.FILLED}
                    >
                      {tagDetail?.importance}
                    </Badge>
                  </Stack>
                  <Text className={classes.subtitle}>{`Assigned to ${tagDetail?.associationCount} member${
                    tagDetail?.associationCount > 1 ? 's' : ''
                  }`}</Text>
                </Stack>
                <IconButton className={classes.editBtn} variant={iconBtnType.PRIMARY}>
                  Schedule new
                </IconButton>
              </Stack>
            )}
            {!isLoading && (
              <Box display="flex" flexDirection="column" gap={4} sx={{ marginTop: 7 }}>
                <AssignmentMethodCard assignmentsMethod={tagDetail?.assignmentLogic} />
                <AssignmentsCard tagReport={tagReport} />
                <AssignmentsTableCard tagAsssignment={tagAssignment} />
              </Box>
            )}
          </Box>
        </Box>
      </BaseLayout>
    </>
  );
};

export { TagDetail };
