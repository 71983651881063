import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { useNavigate } from 'react-router-dom';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Box, ButtonGroup, Stack } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { BaseLayout } from 'layouts/base';
import { EVALUATION_STATES } from 'pages/provider/sessions/evaluations-v2/Evaluations.constants';
import { stateActionCreators } from 'redux/modules/state';
import { conversationActionCreators } from 'redux/modules/conversation/actions';

import { addEvaluation, updateEvaluation } from 'services/conversation/conversation.service';
import { GLOBAL_DATE_FORMAT } from 'constants/CommonConstants';
import { selectEvaluationListState } from 'redux/modules/conversation/selectors';

import AddEvaluation from './components/add-evaluation';
import { evaluationColumns, EvaluationFilters, viewGroups } from './Evaluations.mock';
import { useStyles } from './Evaluations.styles';

const Evaluations: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });

  const { evaluations, isLoading, totalRecords } = useSelector(selectEvaluationListState);
  const [viewBy, setViewBy] = useState(EVALUATION_STATES.ACTIVE);
  const [openEvaluationDrawer, setOpenEvaluationDrawer] = useState(false);
  const [evaluationList, setEvaluationList] = useState([]);
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  useEffect(() => {
    if (evaluations) {
      const evals = formatList(evaluations || []);
      setEvaluationList(evals);
    }
  }, [evaluations]);
  /**
   * @Name onChangeView
   * @description This method is used to change evaluation view like active/draft
   */
  const onChangeView = type => () => {
    setViewBy(type);
    setTableParams({
      search: { searchKey: '' },
      pagination: { currentPage: 1, rowsPerPage: 10 },
      sorter: { direction: 'asc', column: '' },
    });
  };

  const onRowClick = rowItem => {
    navigate(
      `/admin/collection-evaluations/${rowItem.evaluationId}/${rowItem.version ?? 1}?name=assignments`,
    );
  };

  const onAddClick = () => {
    setSelectedEvaluation(null);
    setOpenEvaluationDrawer(true);
  };

  /**
   * @function onEditClick
   * @params dctId
   * @description This method is used to handle Edit menu option click.
   */
  const onEditClick = (evaluationId: string) => () => {
    setSelectedEvaluation(evaluationList?.find(evaluation => evaluation.evaluationId === evaluationId));
    setOpenEvaluationDrawer(true);
  };

  /**
   * @Name formatList
   * @description This method is used to format list according to UI
   */
  const formatList = list =>
    list.map(({ lastUpdated, ...evaluation }) => ({
      ...evaluation,
      lastUpdated: dayjs(lastUpdated).format(GLOBAL_DATE_FORMAT),
      actions: { id: evaluation?.evaluationId },
    }));

  /**
   * @Name getEvaluations
   * @description This method is used to get Evaluation List
   */
  const getEvaluations = () => {
    const { pagination, search, sorter } = tableParams;
    const filters = search.multiSelectFilter;
    const aiGenerated = filters?.Creation && filters?.Creation?.includes('AI Generated');
    const queryParams = {
      orderBy: sorter.direction,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      searchQuery: search.searchKey,
      sortBy: [sorter.column],
      status: viewBy,
      isAiGenerated: !!aiGenerated,
    };
    dispatch(conversationActionCreators.fetchEvaluations(queryParams));
  };

  /**
   * @function saveUpdateEvaluation
   * @params evaluationRequestPayload
   * @description This method is used to save or update Evaluation.
   */
  const saveUpdateEvaluation = (evaluationRequestPayload: any) => {
    if (selectedEvaluation) {
      const payload = {
        ...evaluationRequestPayload,
        avatar: evaluationRequestPayload.avatar,
      };

      const { evaluationId } = selectedEvaluation;
      updateEvaluation(payload, { evaluationId })
        .then(res => {
          if (res.status === 200) {
            setOpenEvaluationDrawer(false);
            getEvaluations();
            setViewBy(viewGroups[1].type);
          } else {
            console.log('response error');
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      const payload = {
        ...evaluationRequestPayload,
        contentBlocks: [],
      };
      addEvaluation(payload)
        .then(evaluation => {
          if (evaluation.status === 200) {
            setOpenEvaluationDrawer(false);
            getEvaluations();
            setViewBy(viewGroups[1].type);
          } else {
            console.log(evaluation);
          }
        })
        .catch(() => {
          console.log('Error Saving Evaluation');
        });
    }
  };

  /**
   * @Name useEffect
   * @description This method is used to set empty state
   */
  useEffect(() => {
    getEvaluations();
    dispatch(stateActionCreators.fetchCPTs());
  }, [viewBy, tableParams]);

  /**
   * @Name renderColumns
   * @description This method is used to render columns in the screen
   */
  const renderColumns = evaluationColumns.map(column => {
    if (column.id === 'name') {
      return {
        ...column,
        renderCell: (name: string) => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {name}
          </Text>
        ),
      };
    }

    if (column.id === 'description') {
      return {
        ...column,
        renderCell: (description: string) => {
          return (
            <Text className={classes.name} weight={fontWeight.MEDIUM}>
              {description}
            </Text>
          );
        },
      };
    }
    if (column.id === 'version') {
      return {
        ...column,
        renderCell: version => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {version}
          </Text>
        ),
      };
    }

    if (column.id === 'status') {
      return {
        ...column,
        renderCell: status => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {status}
          </Text>
        ),
      };
    }
    if (column.id === 'rejectionTemplate') {
      return {
        ...column,
        renderCell: items => (
          <Box display="flex" gap={1.5} alignItems="center" flexWrap="wrap">
            {items?.map(item => (
              <Badge key={item} className={clsx(classes.badge, item)} variant={badgeType.FILLED}>
                {item.trim()}
              </Badge>
            ))}
          </Box>
        ),
      };
    }
    if (column.id === 'dctCount') {
      return {
        ...column,
        renderCell: dctCount => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {dctCount}
          </Text>
        ),
      };
    }
    if (column.id === 'appointmentsCount') {
      return {
        ...column,
        renderCell: appointmentsCount => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {appointmentsCount}
          </Text>
        ),
      };
    }

    if (column.id === 'lastUpdated') {
      return {
        ...column,
        renderCell: lastUpdated => (
          <Text className={classes.name} weight={fontWeight.MEDIUM}>
            {lastUpdated}
          </Text>
        ),
      };
    }

    if (column?.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id: evaluationId }) => (
          <Menu
            icon="more"
            className={classes.menu}
            itemsWrapperClassName={classes.menuItemsWrapper}
            items={[{ label: 'Edit', onClick: onEditClick(evaluationId) }]}
          />
        ),
      };
    }

    return column;
  });

  return (
    <>
      {openEvaluationDrawer && (
        <AddEvaluation
          evaluation={selectedEvaluation}
          isOpen={openEvaluationDrawer}
          onClose={() => setOpenEvaluationDrawer(false)}
          onSubmit={saveUpdateEvaluation}
        />
      )}
      <BaseLayout>
        <div className={classes.root}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                Evaluations
              </Heading>
              {!!totalRecords && (
                <Badge
                  className={classes.totalMemberBadge}
                  variant={badgeType.OUTLINED}
                  style={badgeStyle.UNRELATED}
                >
                  {totalRecords} total
                </Badge>
              )}
            </Stack>

            <IconButton
              className={classes.addProfileBtn}
              icon="plus"
              variant={iconBtnType.PRIMARY}
              onClick={onAddClick}
            >
              Add new
            </IconButton>
          </Stack>

          <Table
            searchProps={{
              placeholder: 'Search evaluation by name',
              filterProps: {
                variant: Filter.tableFilterType.MULTIPLE,
                multiSelectOptions: EvaluationFilters,
                allOptionLabel: 'All template',
              },
              leftSearchComponent: (
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {viewGroups?.map(({ type, label }) => (
                    <Button
                      key={type}
                      className={clsx(classes.btnGroupIcon, {
                        [classes.btnGroupIconActive]: type === viewBy,
                      })}
                      onClick={onChangeView(type)}
                    >
                      {label}
                    </Button>
                  ))}
                </ButtonGroup>
              ),
            }}
            gridProps={{
              columns: renderColumns,
              data: evaluationList,
              isLoading,
              onRowClick,
            }}
            paginationProps={{
              currentRows: tableParams.pagination.rowsPerPage,
              totalCount: totalRecords,
              showRowsPerPage: true,
            }}
            value={tableParams}
            onChange={setTableParams}
            className={classes.table}
          />
        </div>
      </BaseLayout>
    </>
  );
};

export { Evaluations };
