import cx from 'clsx';
import dayjs from 'dayjs';

import { Box, Grid } from '@mui/material';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';

import { IProvider } from 'redux/modules/profile/types';
import { IProviderSchedule } from 'redux/modules/schedule/types';

import BoxFrame from 'components/v2/BoxFrame';
import Header from 'components/v2/Header';
import { HeadingFont, HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import ProfileInfo, { ProfileInfoRole } from 'components/v2/ProfileInfo';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

import { useStyles } from '../index.styles';

type Props = {
  onEdit: () => void;
  providerDetails: IProvider;
  providerSchedule: IProviderSchedule;
};

const About = ({ onEdit, providerDetails, providerSchedule }: Props) => {
  const classes = useStyles();
  const { firstName, lastName, profileImage, designation, bio, active } = providerDetails || {};

  return (
    <BoxFrame>
      <Box py={2.5} px={3} sx={{ '& button': { minWidth: 27 } }}>
        <Header
          label="About"
          fontFamily={HeadingFont.PRIMARY}
          fontVariant={HeadingLevel.SUB}
          fontWeight={HeadingWeight.SEMI_BOLD}
          actions={[
            {
              children: <Typography {...typography.body.s.bold}>Edit</Typography>,
              variant: btnType.TEXT,
              onClick: onEdit,
            },
          ]}
        />
      </Box>
      <Box p={3} borderTop={`1px solid ${colors.neutral100}`}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <ProfileInfo
              fullName={`${firstName || ''} ${lastName || ''}`}
              src={profileImage}
              role={designation as ProfileInfoRole}
            />
          </Grid>
          <Grid item xs={12} md={7} display="flex" justifyContent="flex-end">
            <Box display="flex" gap={4} alignItems="center">
              <Typography {...typography.body.s.medium} color={colors.neutral700}>
                {providerSchedule?.timezone} -{' '}
                {dayjs(new Date()).tz(providerSchedule?.timezone).format('hh:mm a')}
              </Typography>
              <Badge
                variant={badgeType.OUTLINED}
                style={active ? badgeStyle.RESOLVED : badgeStyle.UNRELATED}
                size={badgeSize.SMALL}
              >
                <span className={cx(classes.dot, { [classes.activeDot]: active })} />{' '}
                <Typography {...typography.body.normal.small.semibold}>
                  {active ? 'Active' : 'Inactive'}
                </Typography>
              </Badge>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography {...typography.body.m.medium}>{bio}</Typography>
          </Grid>
        </Grid>
      </Box>
    </BoxFrame>
  );
};

export default About;
