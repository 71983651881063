import S3 from 'react-aws-s3';
import { convertByteToString } from 'utils';

export const s3Config = {
  region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME || 'confidant-health-prod ',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY || 'AKIAWJFF7VSYVSQ6VMNH',
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY || 'quP84aNSlZv1AcKTH9z8RRjeV6byEIyOsg7pe8Qs',
  dirName: 'patients',
};
const s3Services = new S3(s3Config);

const replaceSpace = (name: string, character = '_'): string => {
  return name.replace(/ /g, character);
};

const analyzeFile = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve({
        attachmentName: replaceSpace(file.name),
        attachmentSize: convertByteToString(file.size),
        attachmentType: file.type,
        attachmentUrl: reader.result,
        originFile: file,
      });
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
};

const uploadFile = (file: File): Promise<any> => {
  const filename = replaceSpace(file.name);
  return new Promise((resolve, reject) => {
    s3Services
      .uploadFile(file, filename)
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
};

const removeFile = (fileName: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    s3Services
      .deleteFile(fileName)
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
};

export default {
  analyzeFile,
  uploadFile,
  removeFile,
};
