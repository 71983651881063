import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '@confidant-health/lib/ui/organisms/table/filter';

export const providerColumns: TableGrid.TableColumn[] = [
  { id: 'provider', label: 'Provider', sortable: true },
  { id: 'isActive', label: 'Status', sortable: false },
  { id: 'connections', label: 'Connections', sortable: true },
  { id: 'sessions', label: 'Sessions', sortable: true },
  { id: 'phoneNumber', label: 'Phone', sortable: false },
  { id: 'email', label: 'Email', sortable: false },
  { id: 'availability', label: 'Availability', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [
  { label: 'Active in chat', value: 'active_chat' },
  { label: 'Inactive in chat', value: 'inactive_chat' },
  { label: 'At Least 2 Sessions', value: '2_sessions' },
  { label: 'No Sessions', value: 'no_sessions' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
  {
    label: 'Licensed Professional Counselor',
    value: 'Licensed Professional Counselor',
  },
  { label: 'Social Worker', value: 'Social Worker' },
  { label: 'Psychiatrist', value: 'Psychiatrist' },
  { label: 'Care Manager', value: 'Care Manager' },
  { label: 'Coach', value: 'Coach' },
  { label: 'Matchmaker', value: 'Matchmaker' },
  { label: 'Prescriber', value: 'Prescriber' },
  { label: 'Supervising Prescriber', value: 'Supervising Prescriber' },
  {
    label: 'Drug and Alcohol Counselor',
    value: 'Drug and Alcohol Counselor',
  },
  {
    label: 'Psychiatric Nurse Practitioner',
    value: 'Psychiatric Nurse Practitioner',
  },
];

export const multiSelectFilterOptionsMock = [
  {
    sectionTitle: 'Active',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'Active in chat', value: 'Active in chat' },
      { label: 'Inactive in chat', value: 'Inactive in chat' },
    ],
  },
  {
    sectionTitle: 'State',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Texas', value: 'Texas' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'New Hampshire', value: 'New Hampshire' },
    ],
  },

  {
    sectionTitle: 'Role',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Nurse Practitioner', value: 'Nurse Practitioner' },
      { label: 'Therapist', value: 'Therapist' },
      { label: 'Counselor', value: 'Counselor' },
      { label: 'Coach', value: 'Coach' },
      { label: 'Care Manager', value: 'Care Manager' },
      { label: 'Physician', value: 'Physician' },
    ],
  },
  {
    sectionTitle: 'Sessions Attended',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'At Least 2 Sessions', value: '2 Sessions' },
      { label: 'No Sessions', value: 'No Sessions' },
    ],
  },
];
