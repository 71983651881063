import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

// components
import { Breadcrumbs } from '@confidant-health/lib/ui/molecules/breadcrumbs';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { useQuery } from '@confidant-health/lib/hooks';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { BaseLayout } from 'layouts/base';

// services
import {
  getConversationAssignmentDetail,
  getEvaluationAssignmentDetail,
  saveContentBlock,
} from 'services/conversation/conversation.service';
import { showSnackbar } from 'redux/modules/snackbar';

// types
import { IConversationAssignments } from 'pages/admin/conversations/Conversations.types';
import { CONVERSATION_TYPES } from 'constants/CommonConstants';
import { selectConversationState, selectEvaluationListState } from 'redux/modules/conversation/selectors';

import ConversationFlow from './components/conversation-flow';
import SelectedComponent from './ConversationDetail.selected';
import { path2type, rightSideNavList } from './ConversationDetail.mock';
import { useStyles } from './ConversationDetail.styles';

const ConversationDetail: FC = () => {
  const classes = useStyles();
  const query = useQuery();
  const { conversationId, version } = useParams();

  const [selectedScreen, setSelectedScreen] = useState('assignments');
  const [isReady, setIsReady] = useState(false);
  const [conversation, setConversation] = useState<IConversationAssignments>();
  const [selectedVersion, setSelectedVersion] = useState(parseInt(version, 10));
  const [showFlow, setShowFlow] = useState(false);
  const versions = Array.from(Array(parseInt(version, 10) + 1)?.keys())?.slice(1) || [1];
  const { conversations } = useSelector(selectConversationState);
  const { evaluations } = useSelector(selectEvaluationListState);

  const location = useLocation();
  const state = location.state as { openFlow: boolean };
  const path = location.pathname.split('/')[2];

  useEffect(() => {
    setSelectedVersion(parseInt(location.pathname.split('/')[4], 10));
  }, [location.pathname]);

  const versionOptions = useMemo(() => {
    return versions.map(item => ({
      label: `v${item}`,
      value: item,
      onClick: () => setSelectedVersion(item),
    }));
  }, [versions]);
  const getSelectedConversationAvatar = () => {
    if (conversationId) {
      if (path2type[path].type === CONVERSATION_TYPES.CONVERSATION) {
        const foundConversation = conversations.find(item => item.conversationId === conversationId);
        return foundConversation?.avatar ?? conversation?.conversation?.avatar;
      }
      // if (path2type[path].type === CONVERSATION_TYPES.EVALUATION) {
      const foundConversation = evaluations.find(item => item.evaluationId === conversationId);
      return foundConversation?.avatar ?? conversation?.evaluation?.avatar;
      // }
    }
    return '';
  };
  const getConversationDetails = () => {
    setIsReady(false);
    if (path2type[path].type === CONVERSATION_TYPES.EVALUATION) {
      getEvaluationAssignmentDetail(conversationId, selectedVersion)
        .then(res => {
          if (res.status === 200) {
            console.log({ fetchedEvaluation: res.data });
            // setSelectedVersion(res.data.evaluation?.version);
            setConversation({ ...res.data, conversationId, avatar: getSelectedConversationAvatar() });
            setIsReady(true);
            if (state?.openFlow) {
              setShowFlow(true);
            }
          }
        })
        .catch(err => console.log('err', err));
      return;
    }
    getConversationAssignmentDetail({ conversationId }, selectedVersion)
      .then(res => {
        if (res.status === 200) {
          console.log({ fetchecConversation: res.data });
          // setSelectedVersion(res.data.conversation?.version);

          setConversation({ ...res.data, avatar: getSelectedConversationAvatar() });
          setIsReady(true);

          if (state?.openFlow) {
            setShowFlow(true);
          }
        }
      })
      .catch(err => console.log('err', err));
  };

  useEffect(() => {
    if (state?.openFlow) {
      setShowFlow(false);
    }
    getConversationDetails();
  }, [conversationId, selectedVersion]);

  useEffect(() => {
    const getQuery = query.get('name');
    if (!getQuery) {
      query.set('name', 'assignments');
      window.location.search = query.toString();
    } else {
      setSelectedScreen(getQuery);
    }
  }, [query]);

  const onSaveContentBlock = async (contentBlockId: string, updatedContentBlock: any) => {
    // content block save API call
    const response = await saveContentBlock(contentBlockId, updatedContentBlock);
    if (response.status !== 200) {
      showSnackbar({ snackType: 'error', snackMessage: response.data.errors[0].endUserMessage });
    } else {
      showSnackbar({ snackType: 'success', snackMessage: 'Content block saved' });
    }
  };

  return (
    <>
      {showFlow ? (
        <ConversationFlow
          conversation={conversation}
          conversationType={path2type[path].type}
          onSave={onSaveContentBlock}
          onClose={() => setShowFlow(false)}
        />
      ) : (
        <BaseLayout
          rightSideNavList={rightSideNavList}
          rightSideBarWidth="320px"
          rightSidebarHeadCustom={
            <Heading className={classes.rightSidebarTitle} level={headingLevel.S} weight={fontWeight.BOLD}>
              Conversation
            </Heading>
          }
          propType="EVALUTIONS"
        >
          <Box className={classes.root}>
            <Box className={classes.header}>
              <Breadcrumbs
                links={[
                  { href: `/admin/${path}`, text: path2type[path].text },
                  { text: conversation?.conversation?.name ?? conversation?.evaluation?.name },
                ]}
              />
            </Box>
            <Box>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Stack direction="column" gap={1}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
                      {conversation?.conversation?.name ?? conversation?.evaluation?.name}
                    </Heading>
                    <Menu
                      icon="chevron-down"
                      items={versionOptions}
                      itemsWrapperClassName={classes.menuLabel}
                      className={classes.menu}
                    >
                      <Text className={classes.menuLabel} level={textLevel.S}>
                        v{selectedVersion}
                      </Text>
                    </Menu>
                  </Stack>
                  <Text className={classes.subtitle}>
                    Updated on {dayjs(conversation?.conversation?.lastUpdated).format('MM/DD/YYYY')}
                  </Text>
                </Stack>
                <IconButton
                  className={classes.editBtn}
                  variant={iconBtnType.PRIMARY}
                  onClick={() => setShowFlow(true)}
                >
                  Open Flow
                </IconButton>
              </Stack>
              {isReady ? (
                <SelectedComponent
                  selectedScreen={selectedScreen}
                  conversation={conversation}
                  isEvaluation={path2type[path].type === CONVERSATION_TYPES.EVALUATION}
                  getConversationDetails={getConversationDetails}
                  version={selectedVersion}
                  evaluationId={conversationId}
                />
              ) : (
                <div className={classes.loader}>
                  <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
                  Loading...
                </div>
              )}
            </Box>
          </Box>
        </BaseLayout>
      )}
    </>
  );
};

export { ConversationDetail };
