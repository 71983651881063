import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ClickAwayListener, InputAdornment } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { useStyles } from '../index.styles';

type Props = {
  date: Dayjs;
  label?: string;
  placeHolder?: string;
  onChange?: (date: Dayjs) => void;
};

export default function DatePickerInput2({ date, placeHolder, onChange }: Props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState<Dayjs | null>(
    !date || date.toString() === 'Invalid Date' ? null : date,
  );

  const handleClickAway = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={value}
            inputFormat="MM/DD/YYYY"
            onChange={newValue => {
              setValue(newValue);
              if (isOpen || dayjs(newValue).isValid() || !newValue) {
                onChange(newValue);
                setIsOpen(false);
              }
            }}
            open={isOpen}
            disableFuture
            className={classes.datePickerContainer}
            renderInput={params => (
              <>
                <TextField
                  {...params}
                  className={classes.datePickerContainer}
                  InputProps={{
                    inputProps: {
                      ...params.inputProps,
                      placeholder: !value ? placeHolder : value.format('MM/DD/YYYY'),
                      style: { color: '#000000' },
                      // readOnly: true,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icons glyph="calendar-primary-outlined" onClick={() => setIsOpen(true)} />
                      </InputAdornment>
                    ),
                  }}
                  error={params?.error}
                />
              </>
            )}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
}
