import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { profileActionCreators } from 'redux/modules/profile';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { stateActionCreators } from 'redux/modules/state';
import { selectProviderServicesState } from 'redux/modules/appointment/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import ScheduleLink from 'pages/provider/schedule/components/schedule-link/ScheduleLink';

import About from './about';
import Approach from './approach';
import EditGeneralInfo from './edit-about';
import EditApproach from './edit-approach';
import AvailableStates from './available-states';

import { GeneralInfoSections } from '../index.constants';

const GeneralInformation = () => {
  const dispatch = useDispatch();

  const { providerDetails } = useSelector(getProfile);
  const { services } = useSelector(selectProviderServicesState);
  const providerSchedule = useSelector(selectProviderSchedule);

  const [errorMsg, setErrorMsg] = useState(null);

  const [editSection, setEditSection] = useState<GeneralInfoSections | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch(stateActionCreators.fetchPayerGroups());
  }, []);

  const onEdit = (sectionId: GeneralInfoSections) => {
    setErrorMsg(null);
    setEditSection(sectionId);
  };

  const onCloseEdit = () => {
    setEditSection(null);
  };

  const onUpdateSuccess = () => {
    setIsSaving(false);
    onCloseEdit();
  };

  const onSubmit = (payload: any, type: string) => {
    try {
      setErrorMsg(null);
      const isActivePayload: boolean = payload?.active
        ? JSON.parse(payload?.active)
        : providerDetails?.active || false;
      let specialities = payload?.speciality;
      if (type === 'info') specialities = payload?.speciality.map(item => item.title);
      const data = {
        affiliationImage: payload?.affiliationImage || providerDetails?.providerProfile?.affiliationImage,
        affiliationName: payload?.affiliationName || providerDetails?.providerProfile?.affiliationName,
        affiliationPlace: payload?.affiliationPlace || providerDetails?.providerProfile?.affiliationPlace,
        approach: payload?.approach || providerDetails?.approach,
        certifications: payload?.certifications || providerDetails?.providerProfile?.certifications,
        credentials: payload?.credentials || providerDetails?.providerProfile?.credentials,
        educationDescription:
          payload?.educationDescription || providerDetails?.providerProfile?.educationDescription,
        educationEndDate: payload?.educationEndDate || providerDetails?.providerProfile?.educationEndDate,
        profilePicture: providerDetails?.profileImage,
        educationImage: payload?.educationImage || providerDetails?.providerProfile?.educationImage,
        educationName: payload?.educationName || providerDetails?.providerProfile?.educationName,
        educationPlace: payload?.educationPlace || providerDetails?.providerProfile?.educationPlace,
        educationStartDate:
          payload?.educationStartDate || providerDetails?.providerProfile?.educationStartDate,
        employmentEndDate: payload?.employmentEndDate || providerDetails?.providerProfile?.employmentEndDate,
        employmentImage: payload?.employmentImage || providerDetails?.providerProfile?.employmentImage,
        employmentName: payload?.employmentName || providerDetails?.providerProfile?.employmentName,
        employmentPlace: payload?.employmentPlace || providerDetails?.providerProfile?.employmentPlace,
        employmentStartDate:
          payload?.employmentStartDate || providerDetails?.providerProfile?.employmentStartDate,
        firstName: payload?.firstName || providerDetails?.firstName,
        lastName: payload?.lastName || providerDetails?.lastName,
        fullName: payload?.fullName || providerDetails?.fullName,
        active: payload?.active !== null ? isActivePayload : providerDetails?.active,
        bio: payload?.bio || providerDetails?.bio,
        providerRole: payload?.providerRole || providerDetails?.providerProfile?.providerRole,
        twoFAEnabled: payload?.twoFAEnabled !== null ? payload?.twoFAEnabled : providerDetails?.twoFAEnabled,
        speciality: payload?.speciality?.length > 0 ? specialities : providerDetails?.speciality,
        phoneNumber: payload?.phoneNumber !== null ? payload?.phoneNumber : providerDetails?.phoneNumber,
        signOffRole: payload?.signOffRole || providerDetails?.signOffRole,
        designation: payload?.designation !== null ? payload?.designation : providerDetails?.designation,
      };
      if (payload?.timezone !== providerSchedule?.timezone) {
        dispatch(
          scheduleActionCreators.updateProviderSchedule({
            providerId: providerDetails?.providerId,
            isProfile: true,
            data: {
              timezone: payload?.timezone || providerSchedule?.timezone,
            },
          }),
        );
      }
      dispatch(
        profileActionCreators.updateProviderGeneralInfo({
          id: providerDetails?.providerId,
          data,
          onSuccess: onUpdateSuccess,
        }),
      );
    } catch (err) {
      setErrorMsg(err.data?.errors?.[0]?.endUserMessage || 'Unable to update data');
      setIsSaving(false);
    }
  };

  const getContent = (id: string) => {
    switch (id) {
      case GeneralInfoSections.GENERAL: {
        return {
          description: providerDetails?.bio || '',
          profile: {
            name: providerDetails?.fullName || '',
            firstName: providerDetails?.firstName || '',
            lastName: providerDetails?.lastName || '',
            role: providerDetails?.providerProfile?.providerRole || '',
            activeInApp: providerDetails?.active,
            specialities: providerDetails?.speciality,
          },
        };
      }
      case GeneralInfoSections.APPROACH: {
        return {
          description: providerDetails?.approach || 'N/A',
        };
      }
      case GeneralInfoSections.AVAILABLE_STATES: {
        return {
          insurances: providerDetails?.insurances?.sort((a, b) => (a > b ? 1 : -1)) || [],
        };
      }
      default:
        return { services };
    }
  };

  const editContent = getContent(editSection);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <About
        onEdit={() => onEdit(GeneralInfoSections.GENERAL)}
        providerDetails={providerDetails}
        providerSchedule={providerSchedule}
      />
      <ScheduleLink />
      <Approach onEdit={() => onEdit(GeneralInfoSections.APPROACH)} providerDetails={providerDetails} />
      <AvailableStates insurances={providerDetails?.insurances?.sort((a, b) => (a > b ? 1 : -1)) || []} />
      {editSection === GeneralInfoSections.GENERAL && (
        <EditGeneralInfo
          isOpen={editSection === GeneralInfoSections.GENERAL}
          onClose={onCloseEdit}
          profile={{
            description: editContent?.description,
            ...editContent?.profile,
            timezone: (providerSchedule && providerSchedule.timezone) || '',
          }}
          error={errorMsg}
          onSubmit={payload => onSubmit(payload, 'info')}
          isSaving={isSaving}
        />
      )}
      {editSection === GeneralInfoSections.APPROACH && (
        <EditApproach
          isOpen={editSection === GeneralInfoSections.APPROACH}
          onClose={onCloseEdit}
          error={errorMsg}
          onSubmit={payload => onSubmit(payload, 'approach')}
          description={editContent.description}
        />
      )}
    </Box>
  );
};

export default GeneralInformation;
