import { Box } from '@mui/system';
import cx from 'clsx';
import { useParams } from 'react-router-dom';
import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { ConfirmationModal } from '@confidant-health/lib/ui/templates/confirmation-modal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getConversation, selectDctState } from 'redux/modules/conversation/selectors';
import { showSnackbar } from 'redux/modules/snackbar';

import {
  deleteContentBlock,
  getContentBlocksByConsversationId,
  getContentblocksByDct,
  integrateContentBlock,
  saveContentBlock,
} from 'services/conversation/conversation.service';

import { CONTENT_BLOCK_TYPES } from 'constants/CommonConstants';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import QuestionDrawer from './QuestionDrawer';

import { useStyles } from './EvaluationStates.styles';
import EditDCT from './EditDCT';
import FlowEditing from '../conversation/components/conversation-flow/flow-edting';

type DctSectionProps = {
  evaluations: any;
  conversationType?: string;
  fetchEvaluation?: () => void;
  tabType?: string;
  saveDct?: (val) => void;
  getDCT?: (val) => void;
};

const DctSection = ({ evaluations, fetchEvaluation, tabType, saveDct, getDCT }: DctSectionProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { evaluationId } = useParams();

  const { dcts } = useSelector(selectDctState);
  const {
    profileElements: { profileElementList },
  } = useSelector(getConversation);

  const [orderedItems, setOrderedItems] = useState(evaluations);
  const [openQuestionDrawer, setOpenQuestionDrawer] = useState(false);
  const [editFlowItem, setEditFlowItem] = useState<any>(null);
  const [isLoading] = useState(false);
  const [editLocked] = useState(false);
  const [contentBlocks, setContentBlocks] = useState([]);
  const [refreshContentBlocks, setRefreshContentBlocks] = useState(false);
  const [showRemoveQuestionModal, setShowRemoveQuestionModal] = useState(false);
  const [showRemoveDctModal, setShowRemoveDctModal] = useState(false);
  const [showEditDCT, setShowEditDCT] = useState(false);
  const [selectedCB, setSelectedCB] = useState(null);
  const [selectedDCT, setSelectedDCT] = useState(null);
  const [lastCB, setLastCB] = useState(null);
  const filteredCbs = orderedItems.filter(i => i.cbType !== 'dct');
  // const [totalCount, setTotalCount] = useState(0);

  /* const countObjects = array => {
    let count = 0;
    array.forEach(item => {
      count++;
      if (item.dctContentBlockList && Array.isArray(item.dctContentBlockList)) {
        count += item.dctContentBlockList.length;
      }
    });
    return count;
  }; */

  /* useEffect(() => {
    setTotalCount(countObjects(orderedItems));
    // void getContentBlocks();
  }, []); */
  useEffect(() => {
    if (orderedItems) {
      const allDCTs = orderedItems.filter(dct => dct.cbType === 'dct');
      const allCBs = orderedItems.filter(dct => dct.cbType !== 'dct');
      if (allCBs.length !== 0) setLastCB(allCBs[allCBs.length - 1]);
      getDCT(allDCTs.length);
    }
  }, [orderedItems]);

  const onCloseEditFlowItem = () => {
    setEditFlowItem(null);
  };

  const checkVariableName = () => {
    console.log(contentBlocks);
    const variableNamesList = contentBlocks?.map(item => item?.basicInfo?.referenceId?.trim());
    return variableNamesList.some((item, index) => variableNamesList.indexOf(item) !== index);
  };

  const allLogicsComplete = () => {
    return (
      editFlowItem?.displayLogics &&
      editFlowItem?.displayLogics?.length > 0 &&
      editFlowItem?.displayLogics?.length ===
        editFlowItem?.displayLogics.filter(logic => logic.type && logic.key && logic.value && logic.rule)
          .length
    );
  };

  const displayLogic = () => {
    if (editFlowItem?.mainLogicType) {
      if (editFlowItem?.mainLogicType === 'P' || editFlowItem?.mainLogicType === 'R') {
        if (editFlowItem?.displayLogics && !allLogicsComplete()) {
          return false;
        }
      }
    } else if (
      editFlowItem?.displayLogics &&
      editFlowItem?.displayLogics?.length > 0 &&
      !allLogicsComplete()
    ) {
      return false;
    } else if (editFlowItem?.displayLogics?.length > 1 && editFlowItem?.ruleAggregator === null) {
      return false;
    }
    return true;
  };

  const checkRoutingIsNull = routing => {
    return routing.filter(routingKey => routingKey === '').length === 0;
  };

  const isFormValid = () => {
    const { routing } = editFlowItem;
    const { type, variableMapping, ratingScale } = editFlowItem?.basicInfo;

    if (checkVariableName()) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Duplicates variable name are not allowed',
        }),
      );
      return false;
    }
    if (!displayLogic()) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Invalid display logic',
        }),
      );
      return false;
    }
    if (editFlowItem?.routingOption === 'SPECIFIC' && !checkRoutingIsNull(routing)) {
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Invalid routing',
        }),
      );
      return false;
    }
    if (
      type === CONTENT_BLOCK_TYPES.SINGLE_SELECT ||
      type === CONTENT_BLOCK_TYPES.MULTI_SELECT ||
      type === CONTENT_BLOCK_TYPES.TEXT_INPUT ||
      type === CONTENT_BLOCK_TYPES.RATING_SCALE
    ) {
      if (variableMapping?.scope === 'profile' || variableMapping?.scope === 'local') {
        if (!variableMapping.name) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Variable mapping is required',
            }),
          );
          return false;
        }
        if (variableMapping.name.trim() === '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Variable mapping is required',
            }),
          );

          return false;
        }
      }
      if (type === CONTENT_BLOCK_TYPES.RATING_SCALE) {
        if (!ratingScale.lowLabel || ratingScale.lowLabel.trim() === '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Low Label for Rating Scale is required',
            }),
          );
          return false;
        }
        if (!ratingScale.highLabel || ratingScale.highLabel.trim() === '') {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'High Label for Rating Scale is required',
            }),
          );
          return false;
        }
      }
    }
    return true;
  };

  const sanitizeContentBlock = contentBlock => {
    const { basicInfo } = contentBlock;
    const { type } = basicInfo;
    if (type !== CONTENT_BLOCK_TYPES.EDUCATION_CONTENT) {
      basicInfo.educationContentSlug = null;
    }
    if (type !== CONTENT_BLOCK_TYPES.RATING_SCALE) {
      basicInfo.ratingScale = null;
    }
    if (type !== CONTENT_BLOCK_TYPES.FILTERED_PROVIDERS) {
      basicInfo.popupText = null;
    }
    if (type !== CONTENT_BLOCK_TYPES.SINGLE_SELECT && type !== CONTENT_BLOCK_TYPES.MULTI_SELECT) {
      basicInfo.choices = null;
    }
    if (
      type === CONTENT_BLOCK_TYPES.PROVIDER_MESSAGE ||
      type === CONTENT_BLOCK_TYPES.PROVIDER_PROMPT ||
      type === CONTENT_BLOCK_TYPES.TELEHEALTH_SERVICES
    ) {
      basicInfo.variableMapping = null;
    }
    if (type === CONTENT_BLOCK_TYPES.SINGLE_SELECT || type === CONTENT_BLOCK_TYPES.MULTI_SELECT) {
      if (!basicInfo.variableMapping || basicInfo.variableMapping?.scope !== 'profile') {
        basicInfo.choices =
          basicInfo.choices &&
          basicInfo.choices.map(choice => {
            choice.value = choice.choice;
            return choice;
          });
      }
    }
    if (type === CONTENT_BLOCK_TYPES.RATING_SCALE && basicInfo.ratingScale) {
      basicInfo.ratingScale.lowLabel = basicInfo.ratingScale.lowLabel.trim();
      basicInfo.ratingScale.highLabel = basicInfo.ratingScale.highLabel.trim();
    }
  };

  // use async
  const onSaveFlowItem = async () => {
    const { cbId, ...contentBlock } = editFlowItem;
    if (isFormValid()) {
      if (contentBlock.basicInfo && contentBlock.basicInfo?.referenceId) {
        contentBlock.basicInfo.referenceId = contentBlock.basicInfo.referenceId.trim();
      }
      sanitizeContentBlock(contentBlock);
      await onSaveContentBlock(cbId, contentBlock);
      // void fetchData();
      fetchEvaluation();
    }
    setEditFlowItem(null);
  };

  const onSaveContentBlock = async (contentBlockId: string, updatedContentBlock: any) => {
    // content block save API call
    const response = await saveContentBlock(contentBlockId, updatedContentBlock);
    if (response.status !== 200) {
      showSnackbar({ snackType: 'error', snackMessage: response.data.errors[0].endUserMessage });
    } else {
      showSnackbar({ snackType: 'success', snackMessage: 'Content block saved' });
    }
  };

  const onDeleteFlow = id => {
    let cbId;
    if (id) {
      cbId = id;
    } else {
      const { cbId: cbIdd } = editFlowItem;
      cbId = cbIdd;
    }
    deleteContentBlock(cbId)
      .then(response => {
        if (response.status !== 200) {
          dispatch(
            showSnackbar({
              snackType: 'error',
              snackMessage: 'Error deleting.',
            }),
          );
        } else {
          setRefreshContentBlocks(!refreshContentBlocks);
          dispatch(
            showSnackbar({
              snackType: 'success',
              snackMessage: 'Deleted successfully.',
            }),
          );
        }
      })
      .catch(err => {
        console.log('Error deleting.', err);
      });
    setEditFlowItem(null);
    setTimeout(() => {
      void fetchEvaluation();
    }, 1000);
  };

  const onEditClick = (flowItem, cb) => {
    const foundContentBlock = cb.find(contentBlock => contentBlock.cbId === flowItem.cbId);
    if (foundContentBlock) {
      let extendedFlowItem = { ...flowItem, ...foundContentBlock };
      extendedFlowItem.logic = 'always';
      if (foundContentBlock.displayLogics && foundContentBlock.displayLogics.length > 0) {
        extendedFlowItem.logic = foundContentBlock.displayLogics[0].type as string;
      }
      if (foundContentBlock.rawDisplayLogic) {
        extendedFlowItem.logic = 'RAW';
      }
      if (!extendedFlowItem?.aiDocumentationConfig?.noAnswerResponse) {
        extendedFlowItem = {
          ...extendedFlowItem,
          aiDocumentationConfig: {
            ...extendedFlowItem.aiDocumentationConfig,
            noAnswerResponse: 'Not Discussed',
          },
        };
      }
      if (!extendedFlowItem?.aiDocumentationConfig?.notApplicableResponse) {
        extendedFlowItem = {
          ...extendedFlowItem,
          aiDocumentationConfig: {
            ...extendedFlowItem.aiDocumentationConfig,
            notApplicableResponse: 'Not Applicable',
          },
        };
      }
      if (!extendedFlowItem?.aiDocumentationConfig?.responseFormat) {
        extendedFlowItem = {
          ...extendedFlowItem,
          aiDocumentationConfig: {
            ...extendedFlowItem.aiDocumentationConfig,
            responseFormat: null,
          },
        };
      }
      if (!extendedFlowItem?.aiDocumentationConfig?.responseFormatExample) {
        extendedFlowItem = {
          ...extendedFlowItem,
          aiDocumentationConfig: {
            ...extendedFlowItem.aiDocumentationConfig,
            responseFormatExample: null,
          },
        };
      }
      if (!extendedFlowItem?.aiDocumentationConfig?.responseLength) {
        extendedFlowItem = {
          ...extendedFlowItem,
          aiDocumentationConfig: {
            ...extendedFlowItem.aiDocumentationConfig,
            responseLength: 'select-choice',
          },
        };
      }
      setEditFlowItem(extendedFlowItem);
    }
  };
  const extractNumber = referenceId => {
    const match = referenceId.match(/\d+$/);
    return match ? parseInt(match[0], 10) : null;
  };

  const addCB = async selectedCb => {
    let payload = {};
    if (selectedDCT) {
      const { data } = await getContentblocksByDct(selectedDCT.dctId);
      const highestNumber = data.contentBlocks.reduce((max, item) => {
        const number = extractNumber(item.basicInfo.referenceId);
        return number !== null && number > max ? number : max;
      }, -Infinity);
      payload = {
        cbId: selectedCb?.cbId,
        dctId: selectedDCT.dctId,
        // text: selectedCb?.basicInfo?.text,
        // type: selectedCb?.basicInfo?.type,
        referenceId: data?.contentBlocks?.length === 0 ? 1 : `CB-${Number(highestNumber) + 1}`,
      };
    } else {
      const { data } = await getContentBlocksByConsversationId(evaluationId);
      const highestNumber = data.contentBlocks.reduce((max, item) => {
        const number = extractNumber(item.basicInfo.referenceId);
        return number !== null && number > max ? number : max;
      }, -Infinity);
      payload = {
        cbId: selectedCb?.cbId,
        // text: selectedCb?.basicInfo?.text,
        // type: selectedCb?.basicInfo?.type,
        conversationId: evaluationId,
        referenceId: data?.contentBlocks?.length === 0 ? 1 : `CB-${Number(highestNumber) + 1}`,
      };
    }
    try {
      const response = await integrateContentBlock(payload);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Question added successfully',
          }),
        );
        fetchEvaluation();
      }
    } catch (err) {
      const msg = err.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: msg,
        }),
      );
    }
  };

  const onDragEnd = result => {
    const { destination, source, type } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const results = Array.from(list);
      const [removed] = results.splice(startIndex, 1);
      results.splice(endIndex, 0, removed);
      return results;
    };

    // Handle top-level reordering
    if (type === 'DEFAULT') {
      const reorderedItems = reorder(orderedItems, source.index, destination.index);
      setOrderedItems(reorderedItems);
      saveDct(reorderedItems);
    } else {
      // Handle nested reordering
      const parentItemIndex = orderedItems.findIndex(item => `sub-${item.cbId}` === type);
      const parentItem = orderedItems[parentItemIndex];
      const nestedItems = reorder(parentItem.dctContentBlockList, source.index, destination.index);
      const newOrderedItems = Array.from(orderedItems);
      newOrderedItems[parentItemIndex] = {
        ...parentItem,
        dctContentBlockList: nestedItems,
      };
      setOrderedItems(newOrderedItems);
      saveDct(newOrderedItems);
    }
  };

  const renderCB = (item, isDragging, dctCbId, showDragIcon = true) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {showDragIcon && (
            <Box>
              <Icons glyph="swap-icon" color={isDragging ? colors.primary500 : colors.neutral400} />
            </Box>
          )}
          <Box className={classes.question}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box className={classes.questionName}>{item?.text}</Box>
              <Box className={classes.questionTypeLabel}>{item?.cbType}</Box>
            </Box>
            {item.required && (
              <Badge className={classes.badge2} variant={badgeType.OUTLINED} style={badgeStyle.MEDIUM}>
                Required
              </Badge>
            )}
            {item.responderType === 'PROVIDER' && (
              <Badge className={classes.badge4} variant={badgeType.OUTLINED} style={badgeStyle.LOW}>
                For provider
              </Badge>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, pt: 2 }}>
          <IconButton
            icon="edit-outlined"
            className={cx(classes.icon, classes.editIcon)}
            onClick={async () => {
              try {
                if (dctCbId) {
                  const dctId = dctCbId;
                  const { data } = await getContentblocksByDct(dctId);
                  setContentBlocks(data.contentBlocks);
                  onEditClick(item, data.contentBlocks);
                } else {
                  const conversationId = evaluationId;
                  const { data } = await getContentBlocksByConsversationId(conversationId);
                  setContentBlocks(data.contentBlocks);
                  onEditClick(item, data.contentBlocks);
                }
              } catch (err) {
                dispatch(
                  showSnackbar({
                    snackType: 'error',
                    snackMessage: err?.data?.errors[0]?.endUserMessage || 'Something went wrong!',
                  }),
                );
              }
            }}
          />
          <IconButton
            icon="delete-outlined-2"
            className={classes.icon}
            onClick={() => {
              setSelectedCB(item);
              setShowRemoveQuestionModal(true);
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderDCT = item => {
    return (
      <Collapsable
        className={classes.collapsable}
        iconPosition={IiconPosition.LEFT}
        label={item.text}
        open
        isDCT
        onEdit={() => {
          setSelectedDCT(item);
          setShowEditDCT(true);
        }}
        onDelete={() => {
          setSelectedDCT(item);
          setShowRemoveDctModal(true);
        }}
      >
        <Droppable droppableId={`droppable-${item.cbId}`} type={`sub-${item.cbId}`}>
          {dctProvidedMain => (
            <div
              {...dctProvidedMain.droppableProps}
              ref={dctProvidedMain.innerRef}
              style={{
                // padding: 8,
                // backgroundColor: '#e0e0e0',
                // marginTop: 8,
                minHeight: item?.dctContentBlockList?.length * 82,
              }}
            >
              {item?.dctContentBlockList?.map((subItem, subIndex) => (
                <Draggable key={subItem.cbId} draggableId={subItem.cbId} index={subIndex}>
                  {(dctProvided, snapshot) => (
                    <div
                      ref={dctProvided.innerRef}
                      {...dctProvided.draggableProps}
                      {...dctProvided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        borderBottom:
                          subIndex < item?.dctContentBlockList?.length - 1 &&
                          `1px solid ${colors.neutral100}`,
                        ...dctProvided.draggableProps.style,
                      }}
                    >
                      {renderCB(subItem, snapshot.isDragging, item.dctId, true)}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
        <IconButton
          icon="plus"
          className={classes.addQuestionBtn}
          onClick={() => {
            setSelectedDCT(item);
            setOpenQuestionDrawer(true);
          }}
        >
          Add question
        </IconButton>
      </Collapsable>
    );
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      {tabType === 'all' && (
        <Box display="flex" flexDirection="column" width="100%">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(mainProvided, snapshots) => (
                <div
                  {...mainProvided.droppableProps}
                  ref={mainProvided.innerRef}
                  style={{
                    width: '100%',
                    height: !snapshots.isDraggingOver ? '100%' : '110%',
                    /* : hasDcts.length === 0
                      ? totalCount * 100
                      : totalCount * 84, */
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                  }}
                >
                  {orderedItems.map((item, index) => (
                    <>
                      <Draggable key={item.cbId} draggableId={item.cbId} index={index}>
                        {(mainDCTprovided, snapshot) => (
                          <div
                            ref={mainDCTprovided.innerRef}
                            {...mainDCTprovided.draggableProps}
                            {...mainDCTprovided.dragHandleProps}
                            style={{
                              userSelect: 'none',
                              paddingBottom: item?.cbType !== 'dct' ? 0 : 16,
                              borderBottom:
                                index < orderedItems.length - 1 &&
                                item?.cbType !== 'dct' &&
                                `1px solid ${colors.neutral100}`,
                              ...mainDCTprovided.draggableProps.style,
                            }}
                          >
                            {item?.cbType === 'dct' && renderDCT(item)}
                            {item?.cbType !== 'dct' && renderCB(item, snapshot.isDragging, null, true)}
                          </div>
                        )}
                      </Draggable>
                      {lastCB && lastCB.cbId === item.cbId && (
                        <IconButton
                          icon="plus"
                          className={classes.addQuestionBtn2}
                          onClick={() => {
                            setSelectedDCT(null);
                            setOpenQuestionDrawer(true);
                          }}
                        >
                          Add question
                        </IconButton>
                      )}
                    </>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {filteredCbs.length === 0 && (
            <IconButton
              icon="plus"
              className={classes.addQuestionBtn2}
              onClick={() => {
                setSelectedDCT(null);
                setOpenQuestionDrawer(true);
              }}
            >
              Add question
            </IconButton>
          )}
        </Box>
      )}
      {tabType !== 'all' && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {orderedItems.map((item, index) => (
            <div
              key={index}
              style={{
                paddingBottom: item?.cbType !== 'dct' ? 0 : 16,
                borderBottom:
                  index < orderedItems.length - 1 &&
                  item?.cbType !== 'dct' &&
                  `1px solid ${colors.neutral100}`,
              }}
            >
              {item?.cbType !== 'dct' && renderCB(item, null, item.dctId, false)}
            </div>
          ))}
        </div>
      )}
      {!!editFlowItem && (
        <>
          <FlowEditing
            isLoading={isLoading}
            isOpen={!!editFlowItem}
            flowItem={editFlowItem || {}}
            editLocked={editLocked}
            contentBlocks={contentBlocks}
            dcts={dcts}
            profileElements={profileElementList}
            onClose={onCloseEditFlowItem}
            onSave={onSaveFlowItem}
            onDelete={() => onDeleteFlow(null)}
            conversationType="EVALUATION"
            tileLabel="Edit question"
          />
        </>
      )}
      {openQuestionDrawer && (
        <QuestionDrawer
          isOpen={openQuestionDrawer}
          onClose={() => setOpenQuestionDrawer(false)}
          addCB={addCB}
        />
      )}
      {showEditDCT && (
        <EditDCT
          isOpen={showEditDCT}
          onClose={() => {
            setShowEditDCT(false);
            void fetchEvaluation();
          }}
          dct={selectedDCT}
          onDelete={() => {
            onDeleteFlow(selectedDCT.cbId);
          }}
        />
      )}
      {/* {!!editFlowItem && (
        <QuestionEditDrawer
          isLoading={isLoading}
          isOpen={!!editFlowItem}
          flowItem={editFlowItem || {}}
          editLocked={editLocked}
          contentBlocks={contentBlocks}
          dcts={dcts}
          profileElements={profileElementList}
          onClose={onCloseEditFlowItem}
          onSave={onSaveFlowItem}
          onDelete={onDeleteFlow}
          conversationType={conversationType}
        />
      )} */}
      {showRemoveQuestionModal && (
        <ConfirmationModal
          open={showRemoveQuestionModal}
          onClose={() => {
            setShowRemoveQuestionModal(false);
            setSelectedCB(null);
          }}
          onSave={() => {
            onDeleteFlow(selectedCB.cbId);
            setShowRemoveQuestionModal(false);
            setSelectedCB(null);
          }}
          title="Are you sure you want to remove this question?"
          message={selectedCB.text}
          noLabel="Keep question"
          yesLabel="Remove"
        />
      )}
      {showRemoveDctModal && (
        <ConfirmationModal
          open={showRemoveDctModal}
          onClose={() => {
            setShowRemoveDctModal(false);
            setSelectedDCT(null);
          }}
          onSave={() => {
            onDeleteFlow(selectedDCT.cbId);
            setShowRemoveDctModal(false);
            setSelectedDCT(null);
          }}
          title="Are you sure you want to remove this DCT?"
          message={selectedDCT.text}
          noLabel="Keep DCT"
          yesLabel="Remove"
        />
      )}
    </Box>
  );
};

export default DctSection;
