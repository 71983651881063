import cx from 'clsx';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, btnType, btnSize } from '@confidant-health/lib/ui/atoms/button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { theme } from '@confidant-health/lib/theme';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';

import { appointmentActionCreators } from 'redux/modules/appointment';
import { priorityActionCreators } from 'redux/modules/priority';
import { getAppointment } from 'redux/modules/appointment/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { getAuth } from 'redux/modules/auth/selectors';

import history from 'utils/history';
import dayjs, { getUserTimeZone } from 'utils/dayjs';
import AddSchedule, { INewSchedulePayload } from 'pages/admin/appointments/add-schedule';
import { conversationActionCreators } from 'redux/modules/conversation';

import Evaluations from './Evaluations';
import Diagnoses from './Diagnoses';
import ProcedureCodes from './ProcedureCodes';
import Medications from './Medications';
import Appointments from './Appointments';
import Labs from './Labs';
import SignOff from './SignOff';

import { useStyles } from './SessionSignOffV2.styles';
import { evaluationStatusStyle, initialStatus } from './SessionSignOffV2.constant';
import { ProfileInfoRole } from '../../../../components/v2/ProfileInfo';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { useDomainType } from '../../../../hooks/useDomainType';
import { DomainTypesEnum } from '../../../../constants/CommonConstants';

const ALL_STEPS = [
  'Evaluations',
  'Diagnoses',
  'Procedure codes',
  'Medications',
  'Labs and releases',
  'Next appointment',
  'Sign off',
];
const SessionSignOffV2 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { appointmentId } = useParams();
  const { isAdmin } = useSelector(getAuth);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { profile } = useSelector(getProfile);
  const providerSchedule = useSelector(selectProviderSchedule);
  const { appointments: appointmentsState = [] } = useSelector(getAppointment);
  const appointment = appointmentsState?.find(app => app.appointmentId === appointmentId);
  const [, diagnoses] = useDomainType({
    type: DomainTypesEnum.DIAGNOSES,
    patientId: appointment?.participantId,
  });
  const [activeStep, setActiveStep] = useState('Evaluations');
  const [signOffStatus, setSignOffStatus] = useState<any>(initialStatus);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [template, setTemplate] = useState('no');
  const [schedulesDrawerOpen, setSchedulesDrawerOpen] = useState(false);
  const [scheduledSuccess] = useState(false);
  const [inEvaluationReview, setInEvaluationReview] = useState(false);
  const [evaluation, setEvaluation] = useState(null);
  const [steps, setSteps] = useState([...ALL_STEPS]);

  const filterStepsByDesignation = stepsCopy => {
    let currentSteps = stepsCopy;
    if (
      appointment?.practitionerDesignation !== ProfileInfoRole.THERAPIST &&
      appointment?.practitionerDesignation !== ProfileInfoRole.NURSE_PRACTITIONER
    ) {
      currentSteps = currentSteps.filter(step => step !== 'Diagnoses');
    }
    if (appointment?.practitionerDesignation !== ProfileInfoRole.NURSE_PRACTITIONER) {
      currentSteps = currentSteps.filter(step => step !== 'Medications');
    }
    setSteps(currentSteps);
  };
  useEffect(() => {
    filterStepsByDesignation(steps);
  }, []);

  useEffect(() => {
    if (diagnoses?.relatedElements && diagnoses?.relatedElements.length > 0) {
      filterStepsByDesignation([...ALL_STEPS]);
    } else {
      setSteps(existing => existing.filter(step => step !== 'Procedure codes'));
    }
  }, [diagnoses?.relatedElements]);

  const startDate = dayjs().subtract(6, 'month').toISOString();
  const endDate = dayjs().toISOString();

  useEffect(() => {
    dispatch(priorityActionCreators.fetchPriorityDomainTypes());
    dispatch(profileActionCreators.fetchDomainTypes(appointment?.patientId));
  }, []);

  useEffect(() => {
    const bodyRequest = {
      providerId: profile.providerId,
      startDate,
      endDate,
      timezone: providerSchedule?.timezone || getUserTimeZone(),
      type: 'PENDING',
      textSearch: '',
      filters: [],
    };

    if (activeStep !== 'Labs and releases') {
      dispatch(
        appointmentActionCreators.fetchAppointments({
          bodyRequest,
          queryParams: {
            orderBy: 'desc',
            page: 0,
            size: 1000,
            sortBy: 'startTime',
            type: 'PENDING',
          },
        }),
      );
    }
  }, [activeStep]);

  const onNext = status => {
    const newCompleted = completed;
    newCompleted[steps.indexOf(activeStep)] = true;
    setCompleted(newCompleted);
    setSignOffStatus({ ...signOffStatus, [Object.keys(status)[0]]: Object.values(status)[0] });
    setActiveStep(prevActiveStep => steps[steps.indexOf(prevActiveStep) + 1]);
  };

  const onStep = (step: number) => {
    setActiveStep(steps[step]);
  };

  const onBack = () => {
    const newCompleted = completed;
    newCompleted[steps.indexOf(activeStep) - 1] = false;
    setCompleted(newCompleted);
    setActiveStep(prevActiveStep => steps[steps.indexOf(prevActiveStep) - 1]);
  };

  const onBackToAppointments = () => {
    if (inEvaluationReview) {
      setInEvaluationReview(false);
    } else {
      //  history.push(`/provider/appointments/current/${appointmentId}/session-signoff`, {
      //    isBackFrom: true,
      //  });
      history.go(-1);
    }
  };

  const onSetTemplate = (type: string) => {
    setTemplate(type);
  };

  // const onScheduledSuccess = () => {
  //   setScheduledSuccess(true);
  // };

  const fetchAppointments2 = (pId, timeZone) => {
    const filter2 = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId: pId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters: filter2,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const onSubmitNewSchedule = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed: boolean, isInstantSession: boolean, appId: string) => {
          if (isSucceed) {
            setSchedulesDrawerOpen(false);
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appId}/session`);
            }
          }
        },
      }),
    );
  };

  const onStartEvaluationsReview = item => {
    dispatch(
      conversationActionCreators.evaluationContextUpdated({
        evaluationContext: null,
      }),
    );
    setEvaluation(item);
    setInEvaluationReview(true);
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 'Evaluations':
        return (
          <Evaluations
            onNext={onNext}
            preFill={signOffStatus.evaluations}
            onSetTemplate={onSetTemplate}
            startEvaluationReview={onStartEvaluationsReview}
            inEvaluationReview={inEvaluationReview}
            memberId={appointment?.patientId}
          />
        );
      case 'Diagnoses':
        return (
          <Diagnoses
            onBack={onBack}
            onNext={onNext}
            appointment={appointment}
            preFill={signOffStatus.diagnoses}
            template={template}
          />
        );
      case 'Procedure codes':
        return (
          <ProcedureCodes
            onBack={onBack}
            onNext={onNext}
            appointment={appointment}
            preFill={signOffStatus.codes}
            template={template}
          />
        );
      case 'Medications':
        return (
          <Medications
            onBack={onBack}
            onNext={onNext}
            appointment={appointment}
            preFill={signOffStatus.medications}
            template={template}
          />
        );
      case 'Labs and releases':
        return (
          <Labs
            onBack={onBack}
            onNext={onNext}
            appointment={appointment}
            preFill={signOffStatus.notes}
            template={template}
          />
        );
      case 'Next appointment':
        return (
          <Appointments
            onBack={onBack}
            onNext={onNext}
            appointment={appointment}
            preFill={signOffStatus.appointment}
            setSchedulesDrawerOpen={setSchedulesDrawerOpen}
            template={template}
            scheduledSuccess={scheduledSuccess}
          />
        );
      case 'Sign off':
        return (
          <SignOff
            onBack={onBack}
            signOffStatus={signOffStatus}
            appointment={appointment}
            onStep={onStep}
            template={template}
            isSupervisor={profile.signOffRole === 'SUPERVISOR'}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Button variant={btnType.OUTLINE} className={classes.backBtn} onClick={onBackToAppointments}>
          <Icons color={colors.primary500} glyph="arrow-left" />
        </Button>
        {isMobile ? (
          <Box className={classes.activeStep}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: colors.secondary500,
                marginRight: 1,
                color: colors.white,
              }}
            >
              {steps.indexOf(activeStep) + 1}
            </Box>
            {activeStep}
          </Box>
        ) : inEvaluationReview ? (
          <Box className={classes.reviewHeader}>
            <Box className={cx(classes.flexContainer, classes.gap24)}>
              <Box className={cx(classes.flexContainer, classes.flexColumn, classes.gap4)}>
                <Box className={cx(classes.flexContainer, classes.gap12)}>
                  <Box className={classes.font22}>{evaluation?.name}</Box>
                  <Badge
                    variant={badgeType.OUTLINED}
                    style={evaluationStatusStyle.NEEDS_REVIEW}
                    size={28}
                    className={classes.badge}
                  >
                    <Box display="flex" justifyContent="center" alignItems="center" flex={1} gap="4px">
                      For review
                    </Box>
                  </Badge>
                </Box>
                <Box
                  className={classes.font16}
                  display="flex"
                  justifyContent="flex-start"
                  width="100%"
                  color={colors.neutral600}
                >
                  {evaluation?.evaluationStats?.totalCb - evaluation?.evaluationStats?.unanswered}/
                  {evaluation?.evaluationStats?.totalCb} completed {/* todo : verify these stats */}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button size={btnSize.SMALL} className={classes.activeBtn} onClick={onBackToAppointments}>
                Finish later
              </Button>
              <Button size={btnSize.SMALL} disabled className={classes.btn}>
                Complete evaluation
              </Button>
            </Box>
          </Box>
        ) : (
          <Stepper
            nonLinear
            activeStep={steps.indexOf(activeStep)}
            sx={{ width: 800 }}
            className={classes.stepper}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index] && steps.indexOf(activeStep) !== index}>
                <StepButton
                  color="inherit"
                  onClick={() => onStep(index)}
                  disabled={index > 0 && !completed[index - 1]}
                >
                  {steps.indexOf(activeStep) === index ? label : ''}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
      {renderSteps()}
      {schedulesDrawerOpen && (
        <AddSchedule
          isOpen={schedulesDrawerOpen}
          onClose={() => setSchedulesDrawerOpen(false)}
          onSubmit={onSubmitNewSchedule}
          // memberId={memberId}
          // providerId={chatType === 'providers' ?  selectedContact?.id : null}
        />
      )}
    </Box>
  );
};

export default SessionSignOffV2;
