import { TableGrid } from '@confidant-health/lib/ui/organisms/table';
import { forEach } from 'lodash';
import { REPORT_FILTERS } from 'constants/CommonConstants';
import { IReport, IReportFilter, ReportFilterRule } from 'redux/modules/reporting/types';
import { toDateString } from 'utils/CommonUtils';

export const REPORT_CONSTANTS = {
  MAX_RECORDS_IN_QUERY: 100,
};
export const DDE_REPORT_TYPES = {
  DEFAULT: 'dde',
};
export enum REPORT_COUNT_QUERY_KEYWORDS {
  DEFAULT = 'DEFAULT',
  EVALUATION = 'EVALUATION',
}
export const getReportFilterSubQuery = (filter: IReportFilter) => {
  switch (filter.rule) {
    case ReportFilterRule.EQUALS:
      return {
        nested: {
          path: 'dataElementDetails',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'dataElementDetails.dataElementKey.keyword': {
                      value: filter.profileElement,
                    },
                  },
                },
                {
                  match: {
                    'dataElementDetails.dataElement.values.value': filter.value,
                  },
                },
              ],
            },
          },
        },
      };
    case ReportFilterRule.NOT_EQUALS:
      return {
        nested: {
          path: 'dataElementDetails',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'dataElementDetails.dataElementKey.keyword': {
                      value: filter.profileElement,
                    },
                  },
                },
              ],
              must_not: [
                {
                  match: {
                    'dataElementDetails.dataElement.values.value': filter.value,
                  },
                },
              ],
            },
          },
        },
      };
    case ReportFilterRule.GREATER_THAN:
      return {
        nested: {
          path: 'dataElementDetails',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'dataElementDetails.dataElementKey.keyword': {
                      value: filter.profileElement,
                    },
                  },
                },
                {
                  range: {
                    'dataElementDetails.dataElement.values.value': {
                      gte: filter.value,
                    },
                  },
                },
              ],
            },
          },
        },
      };
    case ReportFilterRule.LESS_THAN:
      return {
        nested: {
          path: 'dataElementDetails',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'dataElementDetails.dataElementKey.keyword': {
                      value: filter.profileElement,
                    },
                  },
                },
                {
                  range: {
                    'dataElementDetails.dataElement.values.value': {
                      lte: filter.value,
                    },
                  },
                },
              ],
            },
          },
        },
      };
    case ReportFilterRule.CONTAINS:
      return {
        nested: {
          path: 'dataElementDetails',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'dataElementDetails.dataElementKey.keyword': {
                      value: filter.profileElement,
                    },
                  },
                },
                {
                  query_string: {
                    default_field: 'dataElementDetails.dataElement.values.value',
                    query: `*${filter.value}*`,
                  },
                },
              ],
            },
          },
        },
      };
    case ReportFilterRule.NOT_CONTAIN:
      return {
        nested: {
          path: 'dataElementDetails',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'dataElementDetails.dataElementKey.keyword': {
                      value: filter.profileElement,
                    },
                  },
                },
              ],
              must_not: [
                {
                  query_string: {
                    default_field: 'dataElementDetails.dataElement.values.value',
                    query: `*${filter.value}*`,
                  },
                },
              ],
            },
          },
        },
      };
    case ReportFilterRule.BEFORE_DATE:
      return {
        nested: {
          path: 'dataElementDetails',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'dataElementDetails.dataElementKey.keyword': {
                      value: filter.profileElement,
                    },
                  },
                },
                {
                  range: {
                    'dataElementDetails.dataElement.values.dateValue': {
                      lte: filter.value,
                    },
                  },
                },
              ],
            },
          },
        },
      };
    case ReportFilterRule.AFTER_DATE:
      return {
        nested: {
          path: 'dataElementDetails',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'dataElementDetails.dataElementKey.keyword': {
                      value: filter.profileElement,
                    },
                  },
                },
                {
                  range: {
                    'dataElementDetails.dataElement.values.dateValue': {
                      gte: filter.value,
                    },
                  },
                },
              ],
            },
          },
        },
      };
    default:
  }
  return {};
};
export const getReportFilterSubQueryForProfileElementReport = (filter: IReportFilter) => {
  switch (filter.rule) {
    case ReportFilterRule.EQUALS:
      return {
        bool: {
          must: [
            {
              term: {
                'dataElementKey.keyword': {
                  value: filter.profileElement,
                },
              },
            },
            {
              match: {
                dataElementValue: filter.value,
              },
            },
          ],
        },
      };
    case ReportFilterRule.NOT_EQUALS:
      return {
        bool: {
          must: [
            {
              term: {
                'dataElementKey.keyword': {
                  value: filter.profileElement,
                },
              },
            },
          ],
          must_not: [
            {
              match: {
                dataElementValue: filter.value,
              },
            },
          ],
        },
      };
    case ReportFilterRule.GREATER_THAN:
      return {
        bool: {
          must: [
            {
              term: {
                'dataElementKey.keyword': {
                  value: filter.profileElement,
                },
              },
            },
            {
              range: {
                dataElementValue: {
                  gte: filter.value,
                },
              },
            },
          ],
        },
      };
    case ReportFilterRule.LESS_THAN:
      return {
        bool: {
          must: [
            {
              term: {
                'dataElementKey.keyword': {
                  value: filter.profileElement,
                },
              },
            },
            {
              range: {
                dataElementValue: {
                  lte: filter.value,
                },
              },
            },
          ],
        },
      };
    case ReportFilterRule.CONTAINS:
      return {
        bool: {
          must: [
            {
              term: {
                'dataElementKey.keyword': {
                  value: filter.profileElement,
                },
              },
            },
            {
              query_string: {
                default_field: 'dataElementValue',
                query: `*${filter.value}*`,
              },
            },
          ],
        },
      };
    case ReportFilterRule.NOT_CONTAIN:
      return {
        bool: {
          must: [
            {
              term: {
                'dataElementKey.keyword': {
                  value: filter.profileElement,
                },
              },
            },
          ],
          must_not: [
            {
              query_string: {
                default_field: 'dataElementValue',
                query: `*${filter.value}*`,
              },
            },
          ],
        },
      };
    case ReportFilterRule.BEFORE_DATE:
      return {
        bool: {
          must: [
            {
              term: {
                'dataElementKey.keyword': {
                  value: filter.profileElement,
                },
              },
            },
            {
              range: {
                dataElementValue: {
                  lte: filter.value,
                },
              },
            },
          ],
        },
      };
    case ReportFilterRule.AFTER_DATE:
      return {
        bool: {
          must: [
            {
              term: {
                'dataElementKey.keyword': {
                  value: filter.profileElement,
                },
              },
            },
            {
              range: {
                dataElementValue: {
                  gte: filter.value,
                },
              },
            },
          ],
        },
      };
    default:
  }
  return {};
};
export const getReportAllFiltersSubQuery = (filter: IReportFilter) => {
  switch (filter.type) {
    case REPORT_FILTERS.STATE:
      return {
        terms: {
          'state.keyword': filter.states || [],
        },
      };
    case REPORT_FILTERS.LEVEL_OF_ENGAGEMENT:
      return {
        terms: {
          'levelOfEngagementStatus.keyword': filter.levelOfEngagements || [],
        },
      };
    // TODO: in case of appointment report we need to change the field 'activeConnections' to 'providerId'
    case REPORT_FILTERS.PROVIDER:
      return {
        terms: {
          'activeConnections.keyword': filter.providers || [],
        },
      };
    case REPORT_FILTERS.INSURANCE:
      return {
        terms: {
          'insurance.keyword': filter.insurance?.carriers || [],
        },
      };
    default:
      break;
  }

  return {};
};

export const getReportAllFiltersSubQueryReferralPartner = referralPartnerId => {
  if (referralPartnerId) {
    console.log(1);
    return [
      {
        term: {
          'referralPartnerList.keyword': {
            value: referralPartnerId,
          },
        },
      },
    ];
  }
  console.log(2);
  return [];
};

export enum REPORT_SECTION_NAMES {
  'appointmentsSection' = 'appointmentsSection',
  'chatbotDctSection' = 'chatbotDctSection',
  'chatbotSection' = 'chatbotSection',
  'dataDomainElementSection' = 'dataDomainElementSection',
  'demographicSection' = 'demographicSection',
  'evaluationsSection' = 'evaluationsSection',
  'evaluationDctSection' = 'evaluationDctSection',
  'loginSection' = 'loginSection',
  'profileElementsSection' = 'profileElementsSection',
}
const internalFiltersSectionBasedQuery = (
  sectionName: REPORT_SECTION_NAMES,
  report: IReport,
  internalFilters,
) => {
  switch (sectionName) {
    case REPORT_SECTION_NAMES.appointmentsSection:
      if (report?.data?.appointmentsSection?.serviceTypeIds?.serviceTypeIds?.length)
        internalFilters.push({
          terms: {
            'serviceTypes.keyword': report?.data?.appointmentsSection.serviceTypeIds.serviceTypeIds,
          },
        });
      break;
    case REPORT_SECTION_NAMES.chatbotDctSection:
      if (report?.data?.chatbotDctSection?.dataCollectionTemplateIds?.dctTemplateIds?.length)
        internalFilters.push({
          terms: {
            'dct_id.keyword': report.data.chatbotDctSection.dataCollectionTemplateIds.dctTemplateIds,
          },
        });

      break;
    case REPORT_SECTION_NAMES.chatbotSection:
      if (report?.data?.chatbotSection?.chatbotIds?.conversationIds?.length)
        internalFilters.push({
          terms: {
            'conversation_id.keyword': report.data.chatbotSection.chatbotIds.conversationIds,
          },
        });
      break;
    case REPORT_SECTION_NAMES.dataDomainElementSection:
      if (report?.data?.dataDomainElementSection?.dataDomainElementIds?.domainElementIds?.length)
        internalFilters.push({
          term: {
            'dde_id.keyword': {
              value: report.data.dataDomainElementSection.dataDomainElementIds.domainElementIds,
            },
          },
        });
      break;
    // case 'demoGraphicsSection':

    //   break;
    case REPORT_SECTION_NAMES.evaluationDctSection:
      if (report?.data?.evaluationDctSection?.dataCollectionTemplateIds?.dctTemplateIds?.length)
        internalFilters.push({
          terms: {
            'dct_id.keyword': report.data.evaluationDctSection.dataCollectionTemplateIds.dctTemplateIds,
          },
        });
      break;
    case REPORT_SECTION_NAMES.evaluationsSection:
      if (report?.data?.evaluationsSection?.evaluationIds?.evaluationIds?.length)
        internalFilters.push({
          terms: {
            'evaluation_id.keyword': report.data.evaluationsSection.evaluationIds.evaluationIds,
          },
        });
      break;
    // case 'loginSection':

    //   break;
    // case 'profileElementsSection':
    //   report.data.profileElementsSection.profileElementIds.profileElementIds
    //   break;
    default:
      break;
  }
};
export const getReportInternalFiltersSubQuery = (report: IReport, sectionName?: REPORT_SECTION_NAMES) => {
  const internalFilters = [];
  if (sectionName) {
    internalFiltersSectionBasedQuery(sectionName, report, internalFilters);
    return internalFilters;
  }
  forEach(report.data, (value, key) => {
    internalFiltersSectionBasedQuery(key as REPORT_SECTION_NAMES, report, internalFilters);
  });
  return internalFilters;
};

export const getReportFilterQuery = (report: IReport) => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }

    return {
      bool: {
        must: [
          ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
          ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
          ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
        ]?.filter(obj => Object.keys(obj)?.length > 0),
      },
    };
  }
  return {
    bool: {
      must: [],
    },
  };
};
const getCountFieldName = (reportType: REPORT_SECTION_NAMES) => {
  switch (reportType) {
    case REPORT_SECTION_NAMES.appointmentsSection:
      return 'patientId.keyword';
    case REPORT_SECTION_NAMES.chatbotDctSection:
    case REPORT_SECTION_NAMES.chatbotSection:
    case REPORT_SECTION_NAMES.dataDomainElementSection:
    case REPORT_SECTION_NAMES.evaluationDctSection:
    case REPORT_SECTION_NAMES.evaluationsSection:
    case REPORT_SECTION_NAMES.loginSection:
      return 'member_id.keyword';
    case REPORT_SECTION_NAMES.profileElementsSection:
      return 'userAccountId.keyword';
    default:
      return 'member_id.keyword';
  }
};
export const getReportAllFiltersQuery = (report: IReport, sectionName?: REPORT_SECTION_NAMES) => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    const query = {
      query: {
        bool: {
          must: [
            ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
            ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
            ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
            ...getReportInternalFiltersSubQuery(report, sectionName),
          ]?.filter(obj => Object.keys(obj)?.length > 0),
        },
      },
    };
    return {
      query: query.query,
      aggs: {
        count: {
          cardinality: {
            field: getCountFieldName(sectionName),
          },
        },
      },
    };
  }
  const query2 = {
    query: {
      bool: {
        must: getReportInternalFiltersSubQuery(report, sectionName)?.filter(
          obj => Object.keys(obj)?.length > 0,
        ),
      },
    },
  };
  return {
    query: query2.query,
    aggs: {
      count: {
        cardinality: {
          field: getCountFieldName(sectionName),
        },
      },
    },
  };
};
export const MemberListColumns: TableGrid.TableColumn[] = [
  {
    id: 'member',
    label: 'Members',
    sortable: false,
    textAlign: 'center',
    className: 'color-secondary-500',
  },
  {
    id: 'elementName',
    label: 'Element Name',
    sortable: false,
  },
  {
    id: 'importance',
    label: 'Importance',
    sortable: false,
  },
  {
    id: 'lastUpdated',
    label: 'Last',
    sortable: false,
  },
];

export const getMemberListdata = (report: any) => {
  /* eslint no-underscore-dangle: 0 */
  return (
    report?.aggregations.member_list.hits.hits.map(bucket => ({
      profile: {
        nickName: bucket?._source?.member_name,
        name: `${bucket?._source?.first_name} ${bucket?._source?.last_name}`,
      },
      member: {
        name: bucket?._source?.nick_name ?? bucket?._source?.member_name,
        fullname: `${bucket?._source?.first_name || ''} ${bucket?._source?.last_name || ''}`,
        memberId: bucket?._source?.member_id,
      },
      elementName: bucket?._source?.dde_name,
      importance: bucket?._source?.dde_importance,
      id: bucket?._source?.member_id,
      lastUpdated: toDateString(bucket._source.lastModified),
    })) ?? []
  );
};

export const getReportsCountQuery = (type: REPORT_COUNT_QUERY_KEYWORDS) => ({
  query: {
    bool: {
      must: [
        {
          term: {
            'type.keyword': {
              value: type,
            },
          },
        },
        {
          match: {
            status: 'ACTIVE',
          },
        },
      ],
    },
  },
});

export const getProfileReportQuery = (report: IReport) => {
  let allFilters = JSON.parse(JSON.stringify(report.reportFilters));
  if (report?.reportFilters?.length || report?.referralPartnerId) {
    const allInsuranceFilters = report.reportFilters?.filter(
      filter => filter.type === REPORT_FILTERS.INSURANCE,
    );
    if (allInsuranceFilters?.length) {
      allFilters = [
        ...report.reportFilters?.filter(filter => filter.type !== REPORT_FILTERS.INSURANCE),
        {
          type: REPORT_FILTERS.INSURANCE,
          insurance: {
            carriers: allInsuranceFilters?.map(filter => filter.insurance?.carriers)?.flat(),
            state: '',
          },
          value: '',
          profileElement: '',
          states: [],
          levelOfEngagements: [],
          providers: [],
          rule: ReportFilterRule.EQUALS,
        },
      ];
    }
    return {
      size: 0,
      query: {
        bool: {
          must: [
            ...allFilters?.map(filter => filter.profileElement && getReportFilterSubQuery(filter)),
            ...allFilters?.map(filter => getReportAllFiltersSubQuery(filter)),
            ...getReportAllFiltersSubQueryReferralPartner(report?.referralPartnerId),
          ]?.filter(obj => Object.keys(obj)?.length > 0),
        },
      },
      aggs: {
        count: {
          cardinality: {
            field: 'userAccountId.keyword',
          },
        },
      },
    };
  }
  return {
    size: 0,
    aggs: {
      count: {
        cardinality: {
          field: 'userAccountId.keyword',
        },
      },
    },
  };
};
