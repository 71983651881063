// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  buttonWrap: {
    display: 'flex',
    gap: 32,
  },
  download: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 32px',
  },
  texts: {
    width: '100%',
  },
  icon: {
    marginRight: 8,
    width: 24,
    minWidth: 24,
    height: 32,
  },
  fileName: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    margin: 0,
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  downloadPart: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    '& img': {
      width: 24,
      height: 32,
      marginRight: 16,
      objectFit: 'contain',
    },
    '& span': {
      fontSize: 14,
      color: colors.neutral500,
    },
  },
  downloadBtn: {
    fontSize: 14,
    '& svg': {
      width: 18,
      height: 19,
      marginRight: 8,
    },
  },
  removeBtn: {
    fontSize: 14,
    minWidth: 'auto',
  },
  link: {
    textDecoration: 'none',
  },
});
