import React, { FC, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'clsx';

// components
import { Box, useMediaQuery } from '@mui/material';
import { theme } from '@confidant-health/lib/theme';
import { SideNavigation, sideNavigationType } from '@confidant-health/lib/ui/templates/side-navigation';
import history from 'utils/history';
// get state
import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import { authActionCreators } from 'redux/modules/auth/actions';
import { getCommonState } from 'redux/modules/common/selectors';
import { commonActionCreators } from 'redux/modules/common';
import { notificationActionCreators } from 'redux/modules/notification';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { selectAppointmentCounts } from 'redux/modules/appointment/selectors';
import { SUPPORTED_AUTHORITIES } from 'constants/CommonConstants';
import { createMemberConnection } from 'services/conversation/conversation.service';
import { showSnackbar } from 'redux/modules/snackbar';
import { Logo, LogoOrientation, LogoSize } from '@confidant-health/lib/Logo';
import CreateConnectionModal from './CreateConnectionModal';

// styles
import { useStyles } from './BaseLayout.styles';

// constants
import {
  providerNavItems,
  adminNavItems,
  dropdownItems,
  providerNavItemsBottom,
  adminNavItemsBottom,
  providerPrimaryRoutes,
  adminPrimaryRoutes,
  PROVIDER_NAV_ITEM_TITLES,
  APPOINTMENT_SUB_NAV_ITEMS,
  ADMIN_NAV_ITEM_TITLES,
} from './BaseLayout.constants';

// types
import { IBaseLayoutProps } from './BaseLayout.types';
import { getNotifications } from '../../redux/modules/notification/selectors';
import { getConversation } from '../../redux/modules/conversation/selectors';
import dayjs, { getUserTimeZone } from '../../utils/dayjs';
import { profileActionCreators } from '../../redux/modules/profile';

const BaseLayout: FC<IBaseLayoutProps> = ({
  textFields,
  rightSideBarClassName,
  rightSideNavList,
  rightSideBarWidth,
  customRightSideBar,
  rightSidebarHeadCustom,
  moreInfoTextFields,
  className = '',
  children,
  propType,
  setOpenDrawer,
  setOpenInstantAppointmentDrawer,
  noPadding,
  isChatNav,
}) => {
  const classes = useStyles({ rightSideBarWidth, noPadding, isChatNav });
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { showSidebar } = useSelector(getCommonState);
  // const [showSidebar, setShowSideBar] = useState(true);
  const { isAuthenticated, isAdmin, meta } = useSelector(getAuth);
  const { profile, selectedPatient, demographicDetails, todosStats } = useSelector(getProfile);
  const appointmentCounts = useSelector(selectAppointmentCounts);
  const { adminNotifications } = useSelector(getNotifications);
  const { sendbirdStatus } = useSelector(getConversation);
  const providerNavItemss = providerNavItems?.map(item => {
    if (item?.link?.includes('/undefined/day-schedule')) {
      return {
        ...item,
        link: `/provider/${meta?.userId}/day-schedule?date=${dayjs()
          .tz(getUserTimeZone())
          .format('YYYY-MM-DD')}`,
      };
    }
    return item;
  });
  const [providerNavigantioItems, setproviderNavigationItems] = useState(providerNavItemss);
  const [adminNavigationItems, setAdminNavigationItems] = useState(adminNavItems);
  const [isCreateConnection, setIsCreateConnection] = useState(false);
  const [dropdownItemsState, setDropdownItems] = useState<{ label: string; onClick: () => void }[]>([]);
  const { pathname, search } = useLocation();
  const [triggerCount, setTriggerCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSideBarToggle = () => {
    dispatch(commonActionCreators.toggleSidebar());
    // setShowSideBar(false);
  };
  const handleChatBtn = () => {
    if (isAdmin && (meta?.authority === 'BILLING_SUPPORT' || meta?.authority === 'ADMIN')) {
      if (demographicDetails?.isConnected === false) {
        setIsCreateConnection(true);
      } else {
        history.push(`/admin/chats?userId=${selectedPatient?.member?.id}&type=members`);
      }
    } else if (isAdmin) {
      history.push(`/admin/chats?userId=${selectedPatient?.member?.id}&type=members`);
    } else {
      history.push(`/provider/chats?userId=${selectedPatient?.member?.id}&type=members`);
    }
  };
  const addConnectionWithMember = async () => {
    try {
      const bodyRequest = {
        suggestedFirstUser: meta.userId,
        suggestedSecondUser: selectedPatient?.member?.id,
      };
      const response = await createMemberConnection(bodyRequest);
      setIsCreateConnection(false);
      if (response.status === 200) {
        dispatch(
          showSnackbar({
            snackType: 'success',
            snackMessage: 'Connection with member created successfully.',
          }),
        );
        history.push(`/admin/chats?userId=${selectedPatient?.member?.id}&type=members`);
      }
    } catch (error) {
      setIsCreateConnection(false);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: error?.data?.errors[0].endUserMessage,
        }),
      );
    }
  };
  useEffect(() => {
    if (appointmentCounts?.current || appointmentCounts?.past || appointmentCounts?.pending) {
      if (!isAdmin) {
        setproviderNavigationItems(
          providerNavigantioItems.map(item => {
            if (item.text === PROVIDER_NAV_ITEM_TITLES.APPOINTMENTS) {
              return {
                ...item,
                children: item.children.map(child => {
                  switch (child.text) {
                    case APPOINTMENT_SUB_NAV_ITEMS.CURRENT:
                      return {
                        ...child,
                        badge: appointmentCounts?.current,
                      };
                    case APPOINTMENT_SUB_NAV_ITEMS.PENDING:
                      return {
                        ...child,
                        badge: appointmentCounts?.pending,
                      };
                    default:
                      return child;
                  }
                }),
              };
            }
            return item;
          }),
        );
      } else {
        setAdminNavigationItems(
          adminNavigationItems.map(item => {
            if (item.text === ADMIN_NAV_ITEM_TITLES.APPOINTMENTS) {
              return {
                ...item,
                children: item.children.map(child => {
                  switch (child.text) {
                    case APPOINTMENT_SUB_NAV_ITEMS.CURRENT:
                      return {
                        ...child,
                        badge: appointmentCounts?.current,
                      };
                    case APPOINTMENT_SUB_NAV_ITEMS.PENDING:
                      return {
                        ...child,
                        badge: appointmentCounts?.pending,
                      };
                    default:
                      return child;
                  }
                }),
              };
            }
            return item;
          }),
        );
      }
    }
  }, [appointmentCounts]);

  useEffect(() => {
    const status = sendbirdStatus === 2 ? 'CONNECTED' : sendbirdStatus === 0 ? 'DISCONNECTED' : 'CONNECTING';
    if (isAdmin) {
      setAdminNavigationItems(
        adminNavigationItems.map(item => {
          if (item.text === ADMIN_NAV_ITEM_TITLES.CHATS) {
            return {
              ...item,
              status,
            };
          }
          return item;
        }),
      );
    } else {
      setproviderNavigationItems(
        providerNavigantioItems.map(item => {
          if (item.text === PROVIDER_NAV_ITEM_TITLES.CHATS) {
            return {
              ...item,
              status,
            };
          }
          if (item.text === PROVIDER_NAV_ITEM_TITLES.Todos) {
            return {
              ...item,
              badge: todosStats.activeTodoStats?.total,
            };
          }
          return item;
        }),
      );
    }
  }, [sendbirdStatus, todosStats]);

  const gotoPage = (link: string) => () => {
    if (propType) {
      history.push(link, {
        from: propType,
      });
    } else {
      history.push(link);
    }
  };

  const getDropdownItemsCB = useCallback(() => {
    const rootLink = isAdmin ? '/admin' : '/provider';
    return dropdownItems
      .filter(item => item.label !== 'Account' || !isAdmin)
      .map(item => {
        if (item.label === 'Logout') {
          return {
            ...item,
            onClick: () => {
              dispatch(authActionCreators.logout());
            },
          };
        }
        if (item.label === 'Account') {
          return {
            ...item,
            onClick: gotoPage(`${rootLink}/profile`),
          };
        }
        return item;
      });
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (!isAuthenticated) {
      if (!showSidebar) dispatch(commonActionCreators.toggleSidebar());
      dispatch(authActionCreators.authenticatedLinkNavigation(pathname + search));
      navigate('/login');
    } else {
      if (pathname === '/') {
        if (!showSidebar) dispatch(commonActionCreators.toggleSidebar());
        history.replace(
          isAdmin
            ? '/admin/members'
            : `/provider/${meta?.userId}/day-schedule?date=${dayjs()
                .tz(getUserTimeZone())
                .format('YYYY-MM-DD')}`,
        );
      }

      setDropdownItems(getDropdownItemsCB());

      if (pathname !== '/provider/calendar') {
        if (!isAdmin) dispatch(notificationActionCreators.fetchNotifications());
        if (isAdmin) {
          dispatch(appointmentActionCreators.fetchAllAppointmentCounts());
          /* setInterval(() => {
            dispatch(appointmentActionCreators.fetchAllAppointmentCounts());
          }, TIME_CONSTANT_MILISECONDS.THIRTY_MINUTES); */
        }
      }
      if (!isAdmin) {
        const statsPayload = {
          assignedBy: '',
          assignedTo: profile.providerId,
        };
        dispatch(profileActionCreators.fetchTodoStats(statsPayload));
      }
    }
  }, []);

  // close left side bar on route change to page have right side bar
  /* useEffect(() => {
    const primaryRoute = isAdmin ? adminPrimaryRoutes : providerPrimaryRoutes;
    const isPrimaryPage = primaryRoute.includes(pathname);
    // scrollCount = 0;
    if (pathname !== '/' && !isPrimaryPage && showSidebar) {
      // dispatch(commonActionCreators.toggleSidebar());
    }
  }, [pathname]); */

  useEffect(() => {
    const scrollY = window.scrollY || window.pageYOffset;
    if (scrollY > 0 && triggerCount <= 1) {
      window.scrollTo(0, 0);
      setTriggerCount(prevCount => prevCount + 1);
    }
  }, [window.scrollY, triggerCount]);

  const adminBottomNav = adminNavItemsBottom.map(item => {
    if (item.text === 'Notifications' && adminNotifications.length > 0) {
      item.badge = adminNotifications.length;
    } else {
      item.badge = null;
    }
    return item;
  });
  let navItems;
  if (isAdmin) {
    navItems = adminNavigationItems;
    if (
      meta.authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR ||
      meta.authority === SUPPORTED_AUTHORITIES.BILLING_SUPPORT
    ) {
      navItems = adminNavigationItems.filter(item => {
        return item.authority.includes(meta.authority);
      });
    }
  } else {
    navItems = providerNavigantioItems;
  }

  const isMobileView =
    isTablet && !rightSideBarWidth && !rightSideBarClassName && pathname === '/provider/todos';

  return (
    <div className={cx(classes.root, className)}>
      {isMobileView ? (
        <Box py={2} px={3} sx={{ background: 'white' }}>
          <Logo orientation={LogoOrientation.HORIZONTAL} size={LogoSize.XSMALL} />
        </Box>
      ) : (
        <SideNavigation
          sideBarIsActive={showSidebar}
          dropdownItems={dropdownItemsState}
          bottomNavItems={isAdmin ? adminBottomNav : providerNavItemsBottom}
          handleSidebarToggle={handleSideBarToggle}
          className={classes.sideNavigation}
          navItems={navItems}
          avatar={profile.profileImage}
          name={meta.nickName}
          role={
            isAdmin
              ? meta.authority
                  .replaceAll('_', ' ')
                  .toLowerCase()
                  .replace(/\b\w/g, s => s.toUpperCase())
              : profile.designation
          }
          primaryRoutes={isAdmin ? adminPrimaryRoutes : providerPrimaryRoutes}
          handleGoBack={history.back}
        />
      )}

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className={cx(
            pathname.includes('/provider/chats/chats-with-members/') ||
              pathname.includes('/provider/chats/chats-with-providers/') ||
              pathname.includes('/provider/chats/chats-with-groups/')
              ? classes.content2
              : (pathname === '/provider/chats' || pathname === '/admin/chats') && search === ''
              ? classes.contentNoVerticalPadding
              : classes.content,
            {
              [classes.contentCollapse]: !showSidebar,
              [classes.contentHoldUp]: showSidebar && !isMobileView,
              [classes.isMobileView]: isMobileView,
            },
          )}
        >
          {children}
        </div>
        {isCreateConnection && !isMobileView && (
          <CreateConnectionModal
            icon="conversations-filled"
            open={isCreateConnection}
            title="Add Connection"
            content="Do you want to create connection with this member?"
            lbtnLabel="Cancel"
            rbtnLabel="Confirm"
            onClose={() => setIsCreateConnection(false)}
            onCancel={() => setIsCreateConnection(false)}
            onSubmit={addConnectionWithMember}
            isLoading={false}
            isDelete={false}
          />
        )}
        {rightSideBarWidth && !isMobileView && (
          <div className={classes.rightSideNavWrapper}>
            <SideNavigation
              sideBarIsActive
              handleSidebarToggle={handleSideBarToggle}
              className={cx({
                [classes.rightSideBar]: true,
                [rightSideBarClassName]: true,
              })}
              navItems={rightSideNavList}
              textFields={textFields}
              moreInfoTextFields={moreInfoTextFields}
              variant={sideNavigationType.RIGHT}
              custom={customRightSideBar}
              rightSidebarHeadCustom={rightSidebarHeadCustom}
              name={
                selectedPatient?.member?.firstName || selectedPatient?.member?.lastName
                  ? `${selectedPatient?.member?.firstName || ''} ${selectedPatient?.member?.lastName || ''}`
                  : selectedPatient?.member?.nickName
                  ? selectedPatient?.member?.nickName
                  : 'No Name'
              }
              connectionId={
                selectedPatient?.member?.id !== null &&
                selectedPatient?.member?.id !== undefined &&
                (pathname.includes('/admin/members') || pathname.includes('/provider/members'))
                  ? selectedPatient?.member?.id
                  : null
              }
              setOpenDrawer={setOpenDrawer}
              setOpenInstantAppointmentDrawer={setOpenInstantAppointmentDrawer}
              handleChatBtn={handleChatBtn}
              // eslint-disable-next-line jsx-a11y/aria-role
              role={`${selectedPatient?.member?.uuid || ''}${
                selectedPatient?.dob ? ` - ${dayjs(selectedPatient?.dob).format('MM/DD/YYYY')}` : ''
              }`}
              avatar={selectedPatient?.member?.photo}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { BaseLayout };
