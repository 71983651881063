import { createAction } from 'redux-actions';

export const FETCH_ADMIN_PROFILE = 'admin/profile/fetch';
export const FETCH_PROVIDER_PROFILE = 'provider/profile/fetch';
export const FETCH_PROFILE_SUCCESSFUL = 'profile/fetchSuccessful';
export const FETCH_PROFILE_FAILED = 'profile/fetchFailed';

export const FETCH_TODO_STATS = 'provider/fetchTodoStats';
export const FETCH_TODO_STATS_SUCCESSFUL = 'provider/fetchTodoStatsSuccessful';
export const FETCH_TODO_STATS_FAILED = 'provider/fetchTodoStatsFAILED';

export const FETCH_ADMIN_VALIDATION_TODO = 'provider/fetchAdminValidationTodo';
export const FETCH_ADMIN_VALIDATION_TODO_SUCCESSFUL = 'provider/fetchAdminValidationTodoSuccessful';
export const FETCH_ADMIN_VALIDATION_TODO_FAILED = 'provider/fetchAdminValidationTodoFAILED';

export const FETCH_TODO = 'provider/fetchToDo';
export const FETCH_TODO_SUCCESSFUL = 'provider/fetchToDoSuccessful';
export const FETCH_TODO_FAILED = 'provider/fetchToDoFailed';

export const FETCH_EVALUATION_TODO = 'provider/fetchEvaluationToDo';
export const FETCH_EVALUATION_TODO_SUCCESSFUL = 'provider/fetchEvaluationToDoSuccessful';
export const FETCH_EVALUATION_TODO_FAILED = 'provider/fetchEvaluationToDoFailed';

export const FETCH_NOTES_TODO = 'provider/fetchNotesToDo';
export const FETCH_NOTES_TODO_SUCCESSFUL = 'provider/fetchNotesToDoSuccessful';
export const FETCH_NOTES_TODO_FAILED = 'provider/fetchNotesToDoFailed';

export const FETCH_VALIDATION_TODO = 'provider/fetchValidationToDo';
export const FETCH_VALIDATION_TODO_SUCCESSFUL = 'provider/fetchValidationToDoSuccessful';
export const FETCH_VALIDATION_TODO_FAILED = 'provider/fetchValidationToDoFailed';

export const TODO_COMPLETED = 'provider/toDoCompleted';
export const TODO_COMPLETED_SUCCESSFUL = 'provider/toDoCompletedSuccessful';
export const TODO_COMPLETED_FAILED = 'provider/toDoCompletedFailed';

// export const FETCH_CONNECTIONS = 'connections/fetch';
// export const FETCH_CONNECTIONS_SILENT = 'connections/fetch_silent';
// export const FETCH_CONNECTIONS_SUCCESSFUL = 'connections/fetchSuccessful';
// export const FETCH_CONNECTIONS_FAILED = 'connections/fetchFailed';
export const FETCH_PROVIDERS = 'providers/fetch';
export const FETCH_PROVIDERS_FAILED = 'providers/fetchFailed';
export const FETCH_PROVIDERS_SUCCESSFUL = 'providers/fetchSuccessful';
export const FETCH_ALL_PROVIDERS = 'allProviders/fetch';
export const FETCH_ALL_PROVIDERS_FAILED = 'allProviders/fetchFailed';
export const FETCH_ALL_PROVIDERS_SUCCESSFUL = 'allProviders/fetchSuccessful';
export const FETCH_PROVIDER_DETAILS = 'provider-detail/fetch';
export const UPDATE_PROVIDER_GENERAL = 'provider-general/update';
export const UPDATE_PROVIDER_GENERAL_SUCCESSFUL = 'provider-general/update_success';
export const UPDATE_PROVIDER_GENERAL_FAILED = 'provider-general/update_failure';
export const UPDATE_TODO = 'profile/UpdateToDo';
export const SHARE_ALFIE_VALIDATION = 'profile/shareAlfieValidation';

export const FETCH_PROVIDER_DETAILS_FAILED = 'provider-detail/fetchFailed';
export const FETCH_PROVIDER_DETAILS_SUCCESSFUL = 'provider-detail/fetchSuccessful';
// export const FETCH_PROVIDERS_SILENT = 'admin/fetchProvidersSilent';
// export const REORDER_PROVIDERS = 'admin/reorderProviders';
// export const REORDER_PROVIDERS_FAILED = 'admin/reorderProvidersFailed';
// export const REORDER_PROVIDERS_SUCCESS = 'admin/reorderProvidersSuccess';
export const FETCH_PATIENTS = 'patients/fetch';
export const FETCH_PATIENTS_SUCCESSFUL = 'patients/fetchSuccessful';
export const FETCH_PATIENTS_FAILED = 'patients/fetchFailed';
export const SELECTED_PATIENT = 'patients/selectedPatient';
export const SET_WALLET_BALANCE = 'walletBalance/setWalletBalance';
export const GET_WALLET_BALANCE = 'walletBalance/fetch';
export const GET_WALLET_BALANCE_SUCCESSFUL = 'walletBalanceSuccessful/fetch';
export const GET_WALLET_BALANCE_FAILED = 'walletBalanceFailed/fetch';
export const FETCH_DEMOGRAPHIC_DETAILS = 'demographicDetails/fetch';
export const FETCH_DEMOGRAPHIC_DETAILS_SUCCESSFUL = 'demographicDetails/fetchSuccessful';
export const FETCH_DEMOGRAPHIC_DETAILS_FAILED = 'demographicDetails/fetchFailed';
export const PUT_DEMOGRAPHIC_DETAILS = 'demographicDetails/put';
export const PUT_DEMOGRAPHIC_DETAILS2 = 'demographicDetails/put2';
export const PUT_DEMOGRAPHIC_DETAILS_SUCCESSFUL = 'demographicDetails/putSuccessful';
export const PUT_DEMOGRAPHIC_DETAILS_FAILED = 'demographicDetails/putFailed';
export const PUT_CONTACT_DETAILS = 'contactDetails/put';
export const PUT_CONTACT_DETAILS_SUCCESS = 'contactDetails/put_success';
export const PUT_CONTACT_DETAILS_FAILURE = 'contactDetails/put_failure';
export const PUT_EMERGENCY_DETAILS = 'emergencyDetails/put';
export const PUT_EMERGENCY_DETAILS_SUCCESS = 'emergencyDetails/put_success';
export const PUT_EMERGENCY_DETAILS_FAILURE = 'emergencyDetails/put_failure';
export const UPDATE_WALLET_BALANCE = 'updateWalletBalance/post';
export const UPDATE_WALLET_BALANCE_SUCCESSFUL = 'updateWalletBalance/postSuccessful';
export const UPDATE_WALLET_BALANCE_FAILED = 'updateWalletBalance/postFailed';
export const UPDATE_ACTIVE_CONNECTION_LAST_MESSAGE = 'updateActiveConnectionLastMessage';
export const FETCH_USER_GROUPS = 'userGroups/fetch';
export const FETCH_USER_GROUPS_SUCCESSFUL = 'userGroups/fetchSuccessful';
export const FETCH_USER_GROUPS_FAILED = 'userGroups/fetchFailed';
export const GET_CONNECTIONS = 'connections/GET';
export const CONNECTIONS_FETCHED = 'connections/FETCHED';
export const MEMBER_CONNECTIONS_FETCHED = 'connections/MEMBERS_FETCHED';
export const MEMBER_CONNECTIONS_FETCH_FAILED = 'connections/MEMBERS_FETCH_FAILED';
export const PROVIDER_CONNECTIONS_FETCHED = 'connections/PROVIDERS_FETCHED';
export const PROVIDER_CONNECTIONS_FETCH_FAILED = 'connections/PROVIDERS_FETCH_FAILED';
export const CARETEAM_CONNECTIONS_FETCHED = 'connections/CARETEAM_FETCHED';
export const CARETEAM_CONNECTIONS_FETCH_FAILED = 'connections/CARETEAM_FETCH_FAILED';
export const CARE_NAVIGATOR_CONNECTIONS_FETCHED = 'connections/CARE_NAVIGATOR_FETCHED';
export const CARE_NAVIGATOR_CONNECTIONS_FETCH_FAILED = 'connections/CARE_NAVIGATOR_FETCH_FAILED';
export const FETCH_MORE_CONNECTIONS = 'connections/FETCH_MORE';
export const SEARCH_CHATS = 'connections/SEARCH_CHATS';
export const SEARCH_CHATS_SUCCESS = 'connections/SEARCH_CHATS_SUCCESS';
export const SEARCH_CHATS_FAILED = 'connections/SEARCH_CHATS_FAILURE';
export const SEARCH_CHATS_CLEAR = 'connections/SEARCH_CHATS_CLEAR';
export const GET_CONNECTIONS_FAILED = 'connections/GET_FAILED';
export const GET_CHAT_CONNECTION_BY_ID_SUCCESSFUL = 'connections/CHAT_GET_SPECIFIC_SUCCESSFUL';

export const GET_CONNECTIONS_BY_STATUS = 'connectionsByStatus/fetch';
export const GET_CONNECTIONS_BY_STATUS_SUCCESSFUL = 'connectionsByStatus/fetchSuccessful';
export const GET_CONNECTIONS_BY_STATUS_FAILED = 'connectionsByStatus/fetchFailed';

export const FETCH_DOMAIN_ELEMENTS = 'domainElements/fetch';
export const FETCH_DOMAIN_ELEMENTS_SUCCESSFUL = 'domainElements/fetchSuccessful';
export const FETCH_DOMAIN_ELEMENTS_FAILED = 'domainElements/fetchFailed';

export const FETCH_LOOKUP_DATA = 'lookupData/fetch';
export const FETCH_LOOKUP_DATA_SUCCESSFUL = 'lookupData/fetchSuccessful';
export const FETCH_LOOKUP_DATA_FAILED = 'lookupData/fetchFailed';
export const FETCH_DOMAIN_TYPES = 'domainTypes/fetch';
export const FETCH_DOMAIN_TYPES_SUCCESSFUL = 'domainTypes/fetchSuccessful';
export const FETCH_DOMAIN_TYPES_FAILED = 'domainTypes/fetchFailed';
export const CREATE_ASSOCIATED_TAG = 'domainTypes/createTag';
export const CREATE_ASSOCIATED_TAG_SUCCESSFUL = 'domainTypes/createTagSuccessful';
export const CREATE_ASSOCIATED_TAG_FAILED = 'domainTypes/createTagFailed';
export const UPDATE_ASSOCIATED_TAG = 'domainTypes/updateTag';
export const UPDATE_ASSOCIATED_TAG_SUCCESSFUL = 'domainTypes/updateTagSuccessful';
export const UPDATE_ASSOCIATED_TAG_FAILED = 'domainTypes/updateTagFailed';

export const SELECTED_APPOINTMENT_NOTE = 'appointments/appointmentNote';

export const FETCH_GROUPS = 'groups/fetch';
export const FETCH_GROUPS_SUCCESSFUL = 'groups/fetchSuccessful';
export const FETCH_GROUPS_FAILED = 'groups/fetchFailed';

export const FETCH_DEMOGRAPHIC_METADATA = 'demographicMetadata/fetch';
export const FETCH_DEMOGRAPHIC_METADATA_SUCCESSFUL = 'demographicMetadata/fetchSuccessful';
export const FETCH_DEMOGRAPHIC_METADATA_FAILED = 'demographicMetadata/fetchFailed';

export const FETCH_MEMBER_INSURANCE = 'reporting/memberInsurance/fetch';
export const FETCH_MEMBER_INSURANCE_FAILED = 'reporting/memberInsurance/fetchFailed';
export const FETCH_MEMBER_INSURANCE_SUCCESSFUL = 'reporting/memberInsurance/fetchSuccessful';

export const PUT_MEMBER_INSURANCE = 'reporting/memberInsurance/update';
export const PUT_MEMBER_INSURANCE_FAILED = 'reporting/memberInsurance/updateFailed';
export const PUT_MEMBER_INSURANCE_SUCCESSFUL = 'reporting/memberInsurance/updateSuccessful';

export const ADD_MEMBER_INSURANCE = 'reporting/memberInsurance/add';
export const ADD_MEMBER_INSURANCE_FAILED = 'reporting/memberInsurance/addFailed';
export const ADD_MEMBER_INSURANCE_SUCCESSFUL = 'reporting/memberInsurance/addSuccessful';

export const FETCH_CARE_TEAM = 'careteam/fetchCareTeam';
export const PUT_CARE_TEAM_LIST = 'careteam/putCareTeamList';
export const UPDATE_CARE_TEAM = 'careteam/updateCareTeam';
export const INVITE_MEMBER = 'profile/member/invite';
export const INVITE_MEMBER_SUCCESSFUL = 'profile/member/inviteSuccessful';
export const INVITE_MEMBER_FAILED = 'profile/member/inviteFailed';

export const FETCH_SYNCED_GOOGLE_CALENDAR = 'profile/google-calendar/fetch';
export const FETCH_SYNCED_GOOGLE_CALENDAR_SUCCESSFUL = 'profile/google-calendar/fetchSuccessful';
export const FETCH_SYNCED_GOOGLE_CALENDAR_FAILED = 'profile/google-calendar/fetchFailed';

export const FETCH_ALL_GOOGLE_CALENDAR_EVENTS = 'profile/google-calendar-events/fetch';
export const FETCH_ALL_GOOGLE_CALENDAR_EVENTS_SUCCESSFUL = 'profile/google-calendar-events/fetchSuccessful';
export const FETCH_ALL_GOOGLE_CALENDAR_EVENTS_FAILED = 'profile/google-calendar-events/fetchFailed';

export const FETCH_REFERRAL_PARTNERS = 'profile/referral-partners/fetch';
export const UPDATE_CHAT_CONNECTIONS = 'connections/UPDATE_CHAT_CONNECTIONS';
export const FETCH_REFERRAL_PARTNERS_SUCCESSFUL = 'profile/referral-partners/fetchSuccessful';
export const FETCH_REFERRAL_PARTNERS_FAILED = 'profile/referral-partners/fetchFailed';

export const CHATS_CLEAR = 'profile/CHATS_CLEAR';

export const profileActionCreators = {
  fetchProviders: createAction(FETCH_PROVIDERS),
  updateChatConnections: createAction(UPDATE_CHAT_CONNECTIONS),
  searchInChatsSuccess: createAction(SEARCH_CHATS_SUCCESS),
  searchInChatsFailed: createAction(SEARCH_CHATS_FAILED),
  fetchAllProviders: createAction(FETCH_ALL_PROVIDERS),
  fetchPatients: createAction(FETCH_PATIENTS),
  selectedPatient: createAction(SELECTED_PATIENT),
  fetchDemographicDetails: createAction(FETCH_DEMOGRAPHIC_DETAILS),
  fetchProviderDetails: createAction(FETCH_PROVIDER_DETAILS),
  updateDemographicDetails: createAction(PUT_DEMOGRAPHIC_DETAILS),
  updateContactInfo: createAction(PUT_CONTACT_DETAILS),
  updateEmergencyInfo: createAction(PUT_EMERGENCY_DETAILS),
  fetchUserGroups: createAction(FETCH_USER_GROUPS),
  fetchAdminProfile: createAction(FETCH_ADMIN_PROFILE),
  fetchProviderProfile: createAction(FETCH_PROVIDER_PROFILE),
  fetchTodo: createAction(FETCH_TODO),
  fetchEvaluationTodo: createAction(FETCH_EVALUATION_TODO),
  fetchTodoStats: createAction(FETCH_TODO_STATS),
  fetchNotesTodo: createAction(FETCH_NOTES_TODO),
  fetchValidationTodo: createAction(FETCH_VALIDATION_TODO),
  todoCompleted: createAction(TODO_COMPLETED),
  setWalletBalance: createAction(SET_WALLET_BALANCE),
  getWalletBalance: createAction(GET_WALLET_BALANCE),
  updateWalletBalance: createAction(UPDATE_WALLET_BALANCE),
  fetchDomainTypes: createAction(FETCH_DOMAIN_TYPES),
  fetchLookupData: createAction(FETCH_LOOKUP_DATA),
  fetchDomainElements: createAction(FETCH_DOMAIN_ELEMENTS),
  updateAppointmentNote: createAction(SELECTED_APPOINTMENT_NOTE),
  createNewAssociatedTag: createAction(CREATE_ASSOCIATED_TAG),
  updateAssociatedTag: createAction(UPDATE_ASSOCIATED_TAG),
  updateActiveConnectionLastMessage: createAction(UPDATE_ACTIVE_CONNECTION_LAST_MESSAGE),
  fetchConnections: createAction(GET_CONNECTIONS),
  fetchConnectionsByStatus: createAction(GET_CONNECTIONS_BY_STATUS),
  fetchGroups: createAction(FETCH_GROUPS),
  fetchDemographicMetadata: createAction(FETCH_DEMOGRAPHIC_METADATA),
  fetchMemberInsurance: createAction(FETCH_MEMBER_INSURANCE),
  updateMemberInsurance: createAction(PUT_MEMBER_INSURANCE),
  addMemberInsurance: createAction(ADD_MEMBER_INSURANCE),
  loadMoreConnections: createAction(FETCH_MORE_CONNECTIONS),
  searchInChats: createAction(SEARCH_CHATS),
  clearChatSearch: createAction(SEARCH_CHATS_CLEAR),
  fetchCareTeam: createAction(FETCH_CARE_TEAM),
  updateCareTeam: createAction(UPDATE_CARE_TEAM),
  storeSpecificConnection: createAction(GET_CHAT_CONNECTION_BY_ID_SUCCESSFUL),
  inviteMember: createAction(INVITE_MEMBER),
  updateProviderGeneralInfo: createAction(UPDATE_PROVIDER_GENERAL),
  updateTodo: createAction(UPDATE_TODO),
  shareAlfieValidation: createAction(SHARE_ALFIE_VALIDATION),
  fetchAdminValidationTodos: createAction(FETCH_ADMIN_VALIDATION_TODO),
  // fetchConnectionsSilent: createAction(FETCH_CONNECTIONS_SILENT),
  // fetchProvidersSilent: createAction(FETCH_PROVIDERS_SILENT),
  // reorderProviders: createAction(REORDER_PROVIDERS)
  fetchSyncedGoogleCalendars: createAction(FETCH_SYNCED_GOOGLE_CALENDAR),
  fetchAllGoogleCalendarEvents: createAction(FETCH_ALL_GOOGLE_CALENDAR_EVENTS),
  fetchReferralPartners: createAction(FETCH_REFERRAL_PARTNERS),
  clearChats: createAction(CHATS_CLEAR),
};
