const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'staging';

const getConfig = () => {
  switch (ENV) {
    case 'prod':
      return {
        api: {
          elkUrl: 'https://ch-prod.es.us-east-2.aws.elastic-cloud.com/prod_',
          baseUrl: 'https://web.confidanthealth.com/api/rest',
          username: 'elastic',
          password: 'FdgOar7wcDj8fPlkTghIx2Nk',
          socketClientUrl: 'wss://prod2-chat.confidantdemos.com/',
          oneSignalAppId: '6b0fc72c-d9a7-41bc-8342-56a25e05b24c',
          timeout: 60000,
          GC_WEBAPP_AUTH_ID: '437840869953-ae48s7f0nekp7ln4pdrd5ft1m947p99b.apps.googleusercontent.com',
        },
        memberAppUrl: 'https://member.confidanthealth.com/',
        sendbird: {
          appId: '33ED704E-1A8D-4506-A63B-C793B3307A4B', // TODO: Replace with Prod Sendbird App ID
        },
        opentok: {
          apiKey: '46695512',
        },
      };
    case 'staging':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/qa_',
          baseUrl: 'https://staging.confidantdemos.com/rest',
          socketClientUrl: 'wss://staging-chat.confidantdemos.com/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          timeout: 60000,
          GC_WEBAPP_AUTH_ID: '165839181374-u4q36ps1a1prfda7fku8u60uepj5tvqo.apps.googleusercontent.com',
        },
        memberAppUrl: 'https://staging.member.confidanthealth.com/',
        sendbird: {
          appId: '', // TODO: Replace with Staging Sendbird App ID
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    case 'qa':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/qa_',
          baseUrl: 'https://qa.confidantdemos.com/rest',
          socketClientUrl: 'wss://qa-chat.confidantdemos.com/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          timeout: 60000,
          GC_WEBAPP_AUTH_ID: '165839181374-v6ubh1t138cptthemoe12l31u663g6eq.apps.googleusercontent.com',
        },
        memberAppUrl: 'https://qa.member.confidanthealth.com/',
        muiLicenseKey:
          'cc7a6341c78b9a54d3d90cdc05a314c5Tz02MTc3MCxFPTE4MTAwOTk4NzM4OTcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
        sendbird: {
          appId: 'A8D3D77F-81DF-4D44-87CA-6581C24413CE', // TODO: Replace with QA Sendbird App ID
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    case 'sit2':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/qa_',
          baseUrl: 'https://sit2.confidantdemos.com/rest',
          socketClientUrl: 'wss://sit2-chat.confidantdemos.com/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          timeout: 60000,
          GC_WEBAPP_AUTH_ID: '165839181374-v6ubh1t138cptthemoe12l31u663g6eq.apps.googleusercontent.com',
        },
        memberAppUrl: 'https://sit2.member.confidanthealth.com/',
        sendbird: {
          appId: 'A8D3D77F-81DF-4D44-87CA-6581C24413CE', // TODO: Replace with QA Sendbird App ID
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    case 'local':
    case 'development':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/qa_',
          baseUrl: 'https://qa.confidantdemos.com/rest', // 'https://sit1.confidantdemos.com/rest',
          socketClientUrl: 'wss://qa-chat.confidantdemos.com/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          GC_WEBAPP_AUTH_ID: '165839181374-v6ubh1t138cptthemoe12l31u663g6eq.apps.googleusercontent.com',
          timeout: 60000,
        },
        memberAppUrl: 'https://sit1.member.confidanthealth.com/',
        sendbird: {
          appId: 'A8D3D77F-81DF-4D44-87CA-6581C24413CE', // TODO: Replace with Dev Sendbird App ID
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    case 'sit1':
      return {
        api: {
          elkUrl: 'https://elastic-staging.confidanthealth.com/sit1',
          baseUrl: 'https://sit1.confidantdemos.com/rest', // 'https://sit1.confidantdemos.com/rest',
          socketClientUrl: 'ws://sit1.confidantdemos.com:8086/',
          oneSignalAppId: '660a6f2d-471a-40f8-9ad0-a0a16f99eebb',
          timeout: 60000,
        },
        memberAppUrl: 'https://sit1.member.confidanthealth.com/',
        sendbird: {
          appId: 'A8D3D77F-81DF-4D44-87CA-6581C24413CE', // Sendbird SIT1
        },
        opentok: {
          apiKey: '46419812',
        },
      };
    default:
      throw Error(`Unconfigured environment '${ENV}'. Can be configured in src/config.js.`);
  }
};

export default getConfig();
