import { Fragment, FC, useMemo } from 'react';
// components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import cx from 'clsx';
import dayjs from 'dayjs';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { btnType, btnSize, Button } from '@confidant-health/lib/ui/atoms/button';
import { Badge, badgeType, badgeStyle } from '@confidant-health/lib/ui/atoms/badge';
import { Link } from '@confidant-health/lib/ui/atoms/link';
import { colors } from '@confidant-health/lib/colors';
import { boxType, DashboardBox } from '@confidant-health/lib/ui/templates/dashboard-box';
import { MONTH_ABREVIATIONS } from 'constants/CommonConstants';
import ScoredOutcomesChart from '../scored-chart';

import { ScoredCardProps } from './ScoredCard.types';
import { useStyles } from './ScoredCard.styles';

const ScoredCard: FC<ScoredCardProps> = ({ item, onPreviousCompletion, onEvaluation }) => {
  const classes = useStyles({});

  const initial = item?.initialDCTOutComeDetail || item?.initialDCTOutcomeDetail;
  const previous = item?.previousDCTOutComeDetail || item?.previousDCTOutcomeDetail;
  const current = item?.currentDCTOutComeDetail || item?.currentDCTOutcomeDetail;

  const chatData = useMemo(() => {
    console.log('item', item?.reports);
    return item?.reports
      .sort((a, b) => {
        return dayjs(a.completedAt).diff(dayjs(b.completedAt));
      })
      ?.map(dct => {
        return {
          score: dct.dctScore,
          date: `${MONTH_ABREVIATIONS[dayjs(dct.completedAt).month()]} ${dayjs(dct.completedAt)
            .toDate()
            .getUTCDate()}`,
        };
      });
  }, [item]);
  return (
    <Fragment>
      <Box className={classes.card}>
        <Box className={classes.cardHeadWrap}>
          <Box className={classes.dFlexColumn}>
            <Box className={classes.dFlexRow}>
              <Heading className={classes.headTitle} weight={fontWeight.SEMI_BOLD} level={headingLevel.M}>
                {item.dctName}
              </Heading>
              <Badge className={classes.badgeOutlined} style={badgeStyle.HIGH} variant={badgeType.OUTLINED}>
                {item.reports.length} responses
              </Badge>
            </Box>
            <Text className={classes.headSubtitle} level={textLevel.M} weight={fontWeight.MEDIUM}>
              v{item.version}
            </Text>
          </Box>
          <Box className={classes.topRight}>
            <Link
              className={cx({ [classes.careTeamValueWrapper]: true, [classes.link]: true })}
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                onPreviousCompletion(item);
              }}
            >
              Previous completions
            </Link>
            <Button variant={btnType.SECONDARY} size={btnSize.SMALL} onClick={() => onEvaluation(item)}>
              View responses
            </Button>
          </Box>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.reports}>
            <Grid container spacing={2}>
              {current && (
                <Grid item xs={4}>
                  <DashboardBox
                    className={classes.reportItem}
                    label={`Current (${dayjs(current?.completedAt).format('MM/DD/YYYY')})`}
                    value={current?.score}
                    showIncrement={current?.score !== previous?.score}
                    isIncrease={current?.score > previous?.score}
                    badge={Math.abs(current?.score - previous?.score)}
                    type={current?.score === 0 ? boxType.TOTAL : undefined}
                    profileName={current?.providerName}
                    profileImage={current?.providerProfileImage}
                  />
                </Grid>
              )}
              {previous && (
                <Grid item xs={4}>
                  <DashboardBox
                    className={classes.reportItem}
                    label={`Previous (${dayjs(previous?.completedAt).format('MM/DD/YYYY')})`}
                    value={previous?.score}
                    showIncrement={previous?.score !== initial?.score}
                    isIncrease={previous?.score > initial?.score}
                    badge={Math.abs(previous?.score - initial?.score)}
                    profileName={previous?.providerName}
                    profileImage={previous?.providerProfileImage}
                  />
                </Grid>
              )}
              {initial && (
                <Grid item xs={4}>
                  <DashboardBox
                    className={classes.reportItem}
                    label={`Initial (${dayjs(initial?.completedAt).format('MM/DD/YYYY')})`}
                    value={initial?.score}
                    showIncrement={false}
                    isIncrease={null}
                    profileName={initial?.providerName}
                    profileImage={initial?.providerProfileImage}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <ScoredOutcomesChart
            title="Reports"
            className={classes.chart}
            chart={{
              series: [
                {
                  name: 'Reports',
                  color: colors.primary500,
                  data: { score: chatData.map(d => d.score), date: chatData.map(d => d.date) },
                },
              ],
              tooltipConfig: {
                isShowTotal: false,
                title: 'Total',
              },
              grid: {
                row: {
                  colors: [
                    colors.success50,
                    colors.success50,
                    colors.success50,
                    colors.primary50,
                    colors.warning50,
                    colors.destructive50,
                  ],
                },
              },
              showLegend: false,
            }}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export { ScoredCard };
