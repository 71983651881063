import { Box } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: string | ReactNode;
  color?: string;
  [prop: string]: any;
};

const Typography = ({ children, color, ...rest }: Props) => {
  return (
    <Box color={color} {...rest}>
      {children}
    </Box>
  );
};

export default Typography;
