import { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@confidant-health/lib/hooks';

import { profileActionCreators } from 'redux/modules/profile';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { getProfile } from 'redux/modules/profile/selectors';

import { BaseLayout } from 'layouts/base';
import Header from 'components/v2/Header';
import { HeadingFont, HeadingLevel } from 'components/v2/Heading/index.types';

import SelectedComponent from './index.detail.selected';

import { rightSideNavList } from './index.constants';

import { useStyles } from './index.styles';

const ProfileV2: FC = memo(() => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const query = useQuery();

  const { providers, profile } = useSelector(getProfile);
  const [selectedScreen, setSelectedScreen] = useState<string>('');

  useEffect(() => {
    const getQuery = query.get('name');
    setSelectedScreen(getQuery || 'general-information');
  }, [query]);

  useEffect(() => {
    if (profile?.providerId) {
      dispatch(profileActionCreators.fetchProviderDetails(profile?.providerId));
      dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: profile?.providerId }));
    }
  }, [profile?.providerId, providers]);

  return (
    <>
      <BaseLayout
        rightSideNavList={rightSideNavList}
        rightSidebarHeadCustom={
          <Header label="My profile" fontVariant={HeadingLevel.SUB} fontFamily={HeadingFont.PRIMARY} />
        }
        rightSideBarWidth="280px"
      >
        <div className={classes.root}>
          <Header label="My profile" />
          <SelectedComponent selectedScreen={selectedScreen} providerId={profile?.providerId} />
        </div>
      </BaseLayout>
    </>
  );
});

ProfileV2.displayName = 'Profile';

export default ProfileV2;
