import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { fontWeight, Heading, headingLevel, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';

import { StateItemFormProps } from './AvailableState.types';
import { useStyles } from './AvailableState.styles';

const AvailableStateForm: React.FC<StateItemFormProps> = ({
  stateList,
  payersGroup,
  item,
  onUpdate,
  onDelete,
  errorObj,
  touched,
}) => {
  const classes = useStyles();
  const [currentStateList, setCurrentStateList] = useState<any>([]);
  useEffect(() => {
    if (item.state) {
      const index = Object.keys(payersGroup).findIndex(key => key === item.state);
      if (index === -1) return;
      setCurrentStateList(Object.values(payersGroup as any)[index]);
    }
  }, [item.state]);
  const payersInState = useMemo(
    () =>
      currentStateList
        .map(
          itm =>
            ({
              title: itm.name,
              value: itm._id,
            } || []),
        )
        .filter(obj => {
          return !item.insurancesInState.some(obj2 => obj2.value === obj.value);
        }),
    [currentStateList],
  );

  const handleChangeState = ({ target }) => {
    if (target.value !== item.state) {
      onUpdate({ ...item, insurancesInState: [], state: target.value });
    } else {
      onUpdate({ ...item, state: target.value });
    }
  };

  const onChangeTags = (_, val) => {
    onUpdate({ ...item, insurancesInState: val });
  };

  useEffect(() => {
    console.log('Item : ', item);
  }, [item.state]);

  const renderLabel = (label: string, className = '') => (
    <Heading level={headingLevel.S} className={clsx(classes.label, className)} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Box className={classes.formItem}>
      <Box className={classes.section}>
        {renderLabel('Select state')}
        <Select
          options={stateList?.sort((a, b) => a.label.localeCompare(b.label))}
          value={item.state}
          getOptionLabel={option => `${option.label}`}
          emptyText="Select state"
          name="state"
          onChange={handleChangeState}
          variant={selectType.SECONDARY}
          fullWidth
        />
        <TextError errorMsg={touched?.state ? errorObj?.state : null} />
      </Box>
      <Box className={classes.section}>
        {renderLabel('Insurance available in this state')}
        <Input
          value={item.insurancesInState}
          name="insurancesInState"
          variant={inputType.TAGS}
          placeholder="Enter an insurance"
          onChange={onChangeTags}
          options={payersInState}
          getOptionLabel={option => `${option.title}`}
          size={inputSize.M}
          fullWidth
        />
        <TextError errorMsg={touched?.insurancesInState ? errorObj?.insurancesInState : null} />
      </Box>
      <IconButton icon="delete-outlined-2" onClick={onDelete} className={classes.deleteBtn}>
        Delete state
      </IconButton>
    </Box>
  );
};

export { AvailableStateForm };
