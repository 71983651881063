import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ButtonGroup } from '@mui/material';
import clsx from 'clsx';

// components
import { Button } from '@confidant-health/lib/ui/atoms/button';
import { Input, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { stateSelector } from 'redux/modules/state/selectors';
import { stateActionCreators } from 'redux/modules/state/actions';
import { IAddStatePayload, IState } from 'redux/modules/state/types';

import { BaseLayout } from 'layouts/base';

import { StateCard } from './state-card';
import { AddStateDrawer } from './add-state';
import { StatesTabs } from './States.constants';
import { useStyles } from './States.styles';

const States: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading, states } = useSelector(stateSelector);
  const [statesFiltered, setStatesFiltered] = useState(states);
  const [selectedType, setSelectedType] = useState<string>('all');
  const [searchKey, setSearchKey] = useState('');
  const [selectedState, setSelectedState] = useState<IState>();
  const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

  const onChangeType = (type: string) => () => {
    setSelectedType(type);
  };

  const onClickUpdate = (item: IState) => {
    setSelectedState(item);
    setOpenStateDrawer(true);
  };

  const onSubmit = (payload: IAddStatePayload) => {
    dispatch(
      stateActionCreators.updateState({
        bodyRequest: payload,
        pathParams: { stateId: selectedState.state._id },
        callback: () => {
          setOpenStateDrawer(false);
          dispatch(stateActionCreators.fetchStates());
        },
      }),
    );
  };

  useEffect(() => {
    let newList = [];
    switch (selectedType) {
      case StatesTabs[1].type:
        newList = states?.filter(
          (item: IState) =>
            item?.state?.isActive && item?.state?.name.toLocaleLowerCase().startsWith(searchKey?.charAt(0)),
        );
        break;
      case StatesTabs[2].type:
        newList = states?.filter(
          (item: IState) =>
            !item?.state?.isActive && item?.state?.name?.toLocaleLowerCase().startsWith(searchKey?.charAt(0)),
        );
        break;
      default:
        newList = states?.filter((item: IState) =>
          item?.state?.name?.toLocaleLowerCase().startsWith(searchKey?.charAt(0)),
        );
        break;
    }
    setStatesFiltered(newList);
  }, [states, selectedType, searchKey]);

  useEffect(() => {
    dispatch(stateActionCreators.fetchStates());
  }, []);

  console.log({ statesFiltered });

  console.log('checking');
  return (
    <BaseLayout>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Box display="flex" alignItems="center" gap={2}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              States
            </Heading>
            <Badge className={classes.totalBadge} variant={badgeType.OUTLINED} style={badgeStyle.UNRELATED}>
              {statesFiltered?.length || '0'} total
            </Badge>
          </Box>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {StatesTabs.map(({ type, label }) => (
              <Button
                key={type}
                className={clsx(classes.btnGroupIcon, {
                  [classes.btnGroupIconActive]: type === selectedType,
                })}
                onClick={onChangeType(type)}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <AddStateDrawer
          isOpen={openStateDrawer}
          selectedState={selectedState}
          onSubmit={onSubmit}
          onClose={() => setOpenStateDrawer(false)}
        />
        <Box gap="16px" display="flex" flexDirection="column">
          <Input
            className={classes.searchInput}
            variant={inputType.SEARCH}
            placeholder="Search"
            value={searchKey}
            onChange={setSearchKey}
          />

          {isLoading && states.length === 0 ? (
            <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
              <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
            </Box>
          ) : (
            <>
              {statesFiltered.length ? (
                statesFiltered.map((item: IState, index: number) => (
                  <StateCard key={index} item={item} onClickUpdate={() => onClickUpdate(item)} />
                ))
              ) : (
                <>No states available</>
              )}
            </>
          )}
        </Box>
      </Box>
    </BaseLayout>
  );
};

export { States };
