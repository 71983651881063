export const roles = [
  { label: 'Provider', value: 'Provider' },
  { label: 'Coach', value: 'Coach' },
  { label: 'Supervising provider', value: 'Supervising provider' },
];

export const specialities = [
  { title: 'ADHD', value: 'ADHD' },
  { title: 'Addiction', value: 'Addiction' },
  { title: 'Alcohol Use', value: 'Alcohol Use' },
  { title: 'Anxiety', value: 'Anxiety' },
  { title: 'Behavioral Health', value: 'Behavioral Health' },
  { title: 'Bipolar', value: 'Bipolar' },
  { title: 'CBT', value: 'CBT' },
  { title: 'Care Navigation', value: 'Care Navigation' },
  { title: 'Criminal Justice Involved', value: 'Criminal Justice Involved' },
  { title: 'DBT', value: 'DBT' },
  { title: 'Depression', value: 'Depression' },
  { title: 'Employment', value: 'Employment' },
  { title: 'Family Support', value: 'Family Support' },
  { title: 'Grief', value: 'Grief' },
  { title: 'Harm Reduction', value: 'Harm Reduction' },
  { title: 'LGBTQ*', value: 'LGBTQ*' },
  { title: 'MAT', value: 'MAT' },
  { title: 'Marriage and Family', value: 'Marriage and Family' },
  { title: 'Medication Management', value: 'Medication Management' },
  { title: 'Mindfulness', value: 'Mindfulness' },
  { title: 'Moderation', value: 'Moderation' },
  { title: 'Opioid Use', value: 'Opioid Use' },
  { title: 'PTSD', value: 'PTSD' },
  { title: 'Peer Support', value: 'Peer Support' },
  { title: 'Pregnancy', value: 'Pregnancy' },
  { title: 'Psychiatric Care', value: 'Psychiatric Care' },
  { title: 'Recovery Coaching', value: 'Recovery Coaching' },
  { title: 'Self Esteem', value: 'Self Esteem' },
  { title: 'Sex and Intimacy', value: 'Sex and Intimacy' },
  { title: 'Sleep', value: 'Sleep' },
  { title: 'Social Anxiety', value: 'Social Anxiety' },
  { title: 'Stress', value: 'Stress' },
  { title: 'Substance Use', value: 'Substance Use' },
  { title: 'Therapy', value: 'Therapy' },
  { title: 'Whole Person Health', value: 'Whole Person Health' },
  { title: 'Womens Health', value: 'Womens Health' },
];
