import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import cx from 'clsx';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Link } from '@confidant-health/lib/ui/atoms/link';
import { badgeStyle, Badge } from '@confidant-health/lib/ui/atoms/badge';
import { Collapsable, IiconPosition } from '@confidant-health/lib/ui/atoms/collapsable';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { getDctResponses, getContextResponses } from 'services/reporting/reporting.service';

import { useStyles } from '../../../Evaluations/evaluations.styles';

type ResponseDrawerProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  params: {
    dctId?: string;
    contextId: string;
  };
};

function ReponseItem({ response = {}, totalCompletions = 0 }: { response: any; totalCompletions: number }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { question, answer, cbId } = response;
  const { memberId } = useParams();
  const [showReponses, setShowResponses] = useState(false);
  const [responses, setReponses] = useState([]);

  const retrivePreviousCompletions = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await getDctResponses({
        beforeDate: 30,
        userId: memberId,
        cbId,
      });
      setReponses(data.responseHistory);
      setShowResponses(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="collapsableInfoCardWrapper">
      <Text className="collapsableInfoCardTitle" level={textLevel.M} weight={fontWeight.SEMI_BOLD}>
        {question}
      </Text>
      <Box className={classes.chatBotAnswerWrapper}>
        <Text className="collapsableInfoCardSubTitle" level={textLevel.M} weight={fontWeight.MEDIUM}>
          {answer}
        </Text>
      </Box>
      {loading && (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      )}
      <Collapsable open={showReponses} headerHidden iconHidden>
        {showReponses &&
          responses.length &&
          responses
            ?.sort(
              (a, b) =>
                dayjs(b?.completedAt, 'YYYYMMDDHHmmSS').unix() -
                dayjs(a?.completedAt, 'YYYYMMDDHHmmSS').unix(),
            )
            .map((resp, indexInner) => (
              <Box key={`content-block-history-${indexInner}`} className={classes.chatBotAnswerWrapper}>
                <Text
                  className={cx(classes.chatBotPrevHistory, 'collapsableInfoCardSubTitle')}
                  level={textLevel.M}
                  weight={fontWeight.MEDIUM}
                >
                  {resp.responses ? resp.responses : 'USER DID NOT COMPLETE'}
                </Text>
                <Text
                  className={cx(classes.chatBotPrevHistory, 'collapsableInfoCardSubTitle')}
                  level={textLevel.M}
                  weight={fontWeight.MEDIUM}
                >
                  {dayjs(resp.completedAt).format('MMMM D, YYYY - hh:mm a')}
                </Text>
              </Box>
            ))}
      </Collapsable>
      {totalCompletions > 1 && (
        <Box>
          {!showReponses ? (
            <Link
              onClick={async e => {
                e.preventDefault();
                await retrivePreviousCompletions();
              }}
            >
              Show previous completions
            </Link>
          ) : (
            <Link
              onClick={e => {
                e.preventDefault();
                setShowResponses(false);
              }}
            >
              Hide previous completions
            </Link>
          )}
        </Box>
      )}
    </div>
  );
}

const ResponseDrawer: React.FC<ResponseDrawerProps> = ({ title, open, onClose, params }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    void (async () => {
      setLoading(true);
      try {
        const {
          data: { message },
        } = await getContextResponses(params);
        setData(message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  console.log('====>', data);

  return (
    <Drawer
      title="View responses"
      subTitle={title}
      open={open}
      onClose={onClose}
      variant={drawerType.FORM}
      footer={<div />}
      className={classes.drawer}
    >
      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" className={classes.drawerHeader}>
            <Box display="flex" alignItems="center" gap="12px">
              <Avatar
                name={data?.providerName}
                src={data?.providerProfileImage}
                size="40px"
                variant={avatarType.CIRCLE}
              />
              <Box>
                <Text level={textLevel.M}>{data?.providerName}</Text>
                <Badge style={badgeStyle.PURPLE}>{data?.providerDesignation}</Badge>
              </Box>
            </Box>
            <Box gap="12px" display="flex" flexDirection="column">
              <Text level={textLevel.L} weight={fontWeight.SEMI_BOLD}>
                {data?.evaluationName}
              </Text>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="9px">
                  <Icons glyph="calendar-primary-outlined" color={colors.neutral600} />
                  <Text level={textLevel.M}>{dayjs(data?.completionTime).format('MMMM DD, YYYY')}</Text>
                </Box>
                <Box display="flex" alignItems="center" gap="9px">
                  <Icons glyph="clock" color={colors.neutral600} />
                  <Text level={textLevel.M}>{dayjs(data?.completionTime).format('hh:mm a')}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box padding="24px" gap="16px" display="flex" flexDirection="column">
            {data?.dctDetails?.map(
              (dct, index) =>
                dct && (
                  <div key={index} className={classes.collapsableWrapper}>
                    {dct?.scorable && (
                      <Text
                        className="collapsableCardScoreText"
                        level={textLevel.S}
                        weight={fontWeight.MEDIUM}
                      >
                        Score: {dct.score}
                      </Text>
                    )}

                    <Collapsable
                      className={classes.drawerCollapsableAccordion}
                      iconPosition={IiconPosition.LEFT}
                      label={dct.dctName}
                      open
                    >
                      <div className="collapsableInfoCards">
                        {dct.responses.map((info, idx) => (
                          <ReponseItem response={info} key={idx} totalCompletions={data?.totalCompletions} />
                        ))}
                      </div>
                    </Collapsable>
                  </div>
                ),
            )}
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default ResponseDrawer;
