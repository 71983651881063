// types
import dayjs from 'dayjs';
import { INavItemType } from '@confidant-health/lib/ui/templates/side-navigation';
import { getUserTimeZone } from 'utils/dayjs';
import { SUPPORTED_AUTHORITIES } from '../../constants/CommonConstants';

const metaString = localStorage.getItem('meta');
const meta = JSON.parse(metaString);
const userId = meta?.userId;

export const PROVIDER_NAV_ITEM_TITLES = {
  DASHBOARD: 'Dashboard',
  Todos: 'To-dos',
  MEMBERS: 'Members',
  APPOINTMENTS: 'Appointments',
  CALENDAR: 'Calendar',
  CHATS: 'Chats',
  GOOGLE_CALENDAR: 'Google Calendar',
  SCHEDULE: 'Schedule',
  PRACTICE: 'Practice',
};
export const ADMIN_NAV_ITEM_TITLES = {
  USER_MANAGEMENT: 'User management',
  APPOINTMENTS: 'Appointments',
  SCHEDULING: 'Scheduling',
  PAYMENTS: 'Payments',
  DATA_COLLECTION: 'Data collection',
  PRIORITIES: 'Priorities',
  DATA_MANAGEMENT: 'Data management',
  CASE_NOTES: 'Case notes',
  REFERRAL_PARTNERS: 'Referral partners',
  AUTOMATIONS: 'Automations',
  REPORTS: 'Reports',
  STATE_MANAGEMENT: 'State management',
  CHATS: 'Chats',
};
export const APPOINTMENT_SUB_NAV_ITEMS = {
  CURRENT: 'Current',
  PAST: 'Past',
  PENDING: 'Pending',
};
export const providerNavItems: INavItemType[] = [
  {
    icon: 'calendar-today-outlined',
    activeIcon: 'calendar-today-outlined',
    text: PROVIDER_NAV_ITEM_TITLES.SCHEDULE,
    link: `/provider/${userId}/day-schedule?date=${dayjs().tz(getUserTimeZone()).format('YYYY-MM-DD')}`,
    authority: [SUPPORTED_AUTHORITIES.PRACTITIONER, SUPPORTED_AUTHORITIES.MATCH_MAKER],
  },
  {
    icon: 'survey-line',
    activeIcon: 'survey-line',
    text: PROVIDER_NAV_ITEM_TITLES.PRACTICE,
    link: '/provider/practice',
    authority: [SUPPORTED_AUTHORITIES.PRACTITIONER, SUPPORTED_AUTHORITIES.MATCH_MAKER],
  },
  {
    icon: 'members-outlined',
    activeIcon: 'members-filled',
    text: PROVIDER_NAV_ITEM_TITLES.MEMBERS,
    link: '/provider/members',
    authority: [SUPPORTED_AUTHORITIES.PRACTITIONER, SUPPORTED_AUTHORITIES.MATCH_MAKER],
  },
  {
    icon: 'list2',
    activeIcon: 'list2',
    text: PROVIDER_NAV_ITEM_TITLES.Todos,
    link: '/provider/todos',
    authority: [SUPPORTED_AUTHORITIES.PRACTITIONER, SUPPORTED_AUTHORITIES.MATCH_MAKER],
  },
  {
    icon: 'chat-square',
    activeIcon: 'chat-square',
    text: PROVIDER_NAV_ITEM_TITLES.CHATS,
    link: '/provider/chats',
    authority: [SUPPORTED_AUTHORITIES.PRACTITIONER, SUPPORTED_AUTHORITIES.MATCH_MAKER],
  },
];

export const adminNavItems: INavItemType[] = [
  // {
  //   icon: 'speed',
  //   activeIcon: 'speed',
  //   classIcon: 'custom-icon',
  //   text: 'Dashboard',
  //   link: '/admin/dashboard',
  // },
  {
    icon: 'members-outlined',
    activeIcon: 'members-filled',
    text: ADMIN_NAV_ITEM_TITLES.USER_MANAGEMENT,
    link: '/admin/users',
    authority: [
      SUPPORTED_AUTHORITIES.ADMIN,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
    ],
    children: [
      {
        text: 'Members',
        link: '/admin/members',
      },
      {
        text: 'Providers',
        link: '/admin/providers',
      },
    ],
  },
  {
    icon: 'event-outlined',
    activeIcon: 'event-outlined',
    text: ADMIN_NAV_ITEM_TITLES.APPOINTMENTS,
    authority: [
      SUPPORTED_AUTHORITIES.ADMIN,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
    ],
    children: [
      {
        text: 'Pending',
        link: '/admin/appointments/pending',
      },
      {
        text: 'Current',
        link: '/admin/appointments/current',
      },
      {
        text: 'Past',
        link: '/admin/appointments/past',
      },
    ],
  },
  {
    icon: 'appointment',
    activeIcon: 'appointment',
    text: ADMIN_NAV_ITEM_TITLES.SCHEDULING,
    link: '/admin/schedule-link',
    authority: [
      SUPPORTED_AUTHORITIES.ADMIN,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
    ],
    children: [
      {
        text: 'Session types',
        link: '/admin/session-types',
      },
      {
        text: 'Master schedule',
        link: '/admin/master-schedule',
      },
    ],
  },
  {
    icon: 'dollar-outlined',
    activeIcon: 'dollar-outlined',
    text: ADMIN_NAV_ITEM_TITLES.PAYMENTS,
    authority: [
      SUPPORTED_AUTHORITIES.ADMIN,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
    ],
    children: [
      {
        text: 'Cash Payments',
        link: '/admin/payments',
      },
      {
        text: 'Claims',
        link: '/admin/claims',
      },
      {
        text: 'Invoices',
        link: '/admin/invoices',
      },
      {
        text: 'Provider Fee',
        link: '/admin/provider-fee',
      },
    ],
  },
  {
    icon: 'collection',
    activeIcon: 'collection',
    classIcon: 'custom-icon',
    text: ADMIN_NAV_ITEM_TITLES.DATA_COLLECTION,
    link: '/admin/collections',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    children: [
      {
        text: 'Chatbots',
        link: '/admin/collection-conversations',
      },
      {
        text: 'Chatbot DCTs',
        link: '/admin/collection-templates',
      },
      {
        text: 'Evaluations',
        link: '/admin/collection-evaluations',
      },
      {
        text: 'Evaluation DCTs',
        link: '/admin/collection-evaluation-dcts',
      },
      {
        text: 'Content Block Library',
        link: '/admin/content-block-library',
      },
    ],
  },
  {
    icon: 'level',
    activeIcon: 'level',
    classIcon: 'custom-icon',
    text: ADMIN_NAV_ITEM_TITLES.PRIORITIES,
    link: '/admin/priorities',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    children: [
      {
        text: 'Elements',
        link: '/admin/priority-elements',
      },
      {
        text: 'Groupings',
        link: '/admin/priority-groupings',
      },
      {
        text: 'Types',
        link: '/admin/priority-types',
      },
    ],
  },
  {
    icon: 'data-block',
    activeIcon: 'data-block',
    classIcon: 'custom-icon',
    text: ADMIN_NAV_ITEM_TITLES.DATA_MANAGEMENT,
    link: '/admin/managements',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
    children: [
      {
        text: 'Profile elements',
        link: '/admin/data-management-elements',
      },
      // {
      //   text: 'Tags',
      //   link: '/admin/data-management-tags',
      // },
      {
        text: 'Plan items',
        link: '/admin/data-management-plan-items',
      },
      {
        text: 'Level of Engagement',
        link: '/admin/data-management-level-of-engagement',
      },
    ],
  },
  {
    icon: 'report-outlined',
    activeIcon: 'report-outlined',
    text: ADMIN_NAV_ITEM_TITLES.CASE_NOTES,
    link: '/admin/notes',
    authority: [
      SUPPORTED_AUTHORITIES.ADMIN,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
    ],
    children: [
      {
        text: 'All',
        link: '/admin/notes/all',
      },
      {
        text: 'General',
        link: '/admin/notes/general',
      },
      {
        text: 'Contact',
        link: '/admin/notes/contact',
      },
      {
        text: 'Prohibitive',
        link: '/admin/notes/prohibitive',
      },
      {
        text: 'Cautionary',
        link: '/admin/notes/caution',
      },
      {
        text: 'Release of Information',
        link: '/admin/notes/release-of-information',
      },
      {
        text: 'Lab Request',
        link: '/admin/notes/lab-request',
      },
      {
        text: 'Pharmacy Assistance',
        link: '/admin/notes/pharmacy-assistance',
      },
      {
        text: 'Referral Out',
        link: '/admin/notes/referral-out',
      },
      {
        text: 'Prior Authorization',
        link: '/admin/notes/prior-authorization',
      },
    ],
  },
  {
    icon: 'profile-arrow-left-outlined',
    activeIcon: 'profile-arrow-left-outlined',
    text: ADMIN_NAV_ITEM_TITLES.REFERRAL_PARTNERS,
    link: '/admin/referral-partners',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
  },
  {
    icon: 'star2',
    activeIcon: 'star2',
    text: ADMIN_NAV_ITEM_TITLES.AUTOMATIONS,
    link: '/admin/automations',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
  },
  {
    icon: 'dashboard',
    activeIcon: 'dashboard',
    text: ADMIN_NAV_ITEM_TITLES.REPORTS,
    link: '/admin/reports',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
  },
  {
    icon: 'earth',
    activeIcon: 'earth',
    text: ADMIN_NAV_ITEM_TITLES.STATE_MANAGEMENT,
    link: '/admin/states',
    authority: [SUPPORTED_AUTHORITIES.ADMIN],
  },
  {
    icon: 'chat',
    activeIcon: 'chat',
    text: ADMIN_NAV_ITEM_TITLES.CHATS,
    link: '/admin/chats',
    authority: [
      SUPPORTED_AUTHORITIES.ADMIN,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
    ],
  },
];

export const dropdownItems = [
  {
    label: 'Account',
    onClick: (): void => console.log('account clicked!'),
  },
  // {
  //   label: 'Settings',
  //   onClick: (): void => console.log('settings clicked!'),
  // },
  {
    label: 'Logout',
    onClick: (): void => console.log('logout clicked!'),
  },
];

export const providerNavItemsBottom: INavItemType[] = [
  {
    icon: 'bell-filled',
    activeIcon: 'bell-filled',
    text: 'Notifications',
    link: '/provider/notifications',
    authority: [SUPPORTED_AUTHORITIES.PRACTITIONER, SUPPORTED_AUTHORITIES.MATCH_MAKER],
  },
  {
    icon: 'support',
    activeIcon: 'support',
    text: 'Contact support',
    link: '/provider/contact-support',
    authority: [SUPPORTED_AUTHORITIES.PRACTITIONER, SUPPORTED_AUTHORITIES.MATCH_MAKER],
  },
];

export const adminNavItemsBottom: INavItemType[] = [
  {
    icon: 'bell-filled',
    activeIcon: 'bell-filled',
    text: 'Notifications',
    link: '/admin/notifications',
    authority: [
      SUPPORTED_AUTHORITIES.ADMIN,
      SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
      SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
    ],
  },
];

export const adminPrimaryRoutes = [
  '/admin/dashboard',
  '/admin/members',
  '/admin/providers',
  '/admin/appointments/pending',
  '/admin/appointments/current',
  '/admin/appointments/past',
  '/admin/schedule-link-appointments',
  '/admin/session-types',
  '/admin/payments',
  '/admin/claims',
  '/admin/invoices',
  '/admin/provider-fee',
  '/admin/insurance-co-pays',
  '/admin/subscriptions',
  '/admin/contributions',
  '/admin/collection-templates',
  '/admin/collection-conversations',
  '/admin/collection-evaluations',
  '/admin/collection-evaluation-dcts',
  '/admin/collection-documentation',
  '/admin/priority-types',
  '/admin/priority-elements',
  '/admin/priority-groupings',
  '/admin/data-management-elements',
  '/admin/data-management-tags',
  '/admin/data-management-plan-items',
  '/admin/data-management-level-of-engagement',
  '/admin/reports',
  '/admin/states',
  '/admin/notifications',
  '/admin/contact-support',
  '/admin/profile',
];

export const providerPrimaryRoutes = [
  '/provider/dashboard',
  `/provider/${userId}/day-schedule?date=${dayjs().tz(getUserTimeZone()).format('YYYY-MM-DD')}`,
  '/provider/todos',
  '/provider/members',
  '/provider/appointments/current',
  '/provider/appointments/past',
  '/provider/appointments/pending',
  '/provider/chats',
  '/provider/notifications',
  '/provider/contact-support',
  '/provider/profile',
  '/provider/practice',
];
