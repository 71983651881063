import { CONTACT_NOTES_FLAGS } from '@confidant-health/lib/constants/CommonConstants';
import { badgeStyle } from '@confidant-health/lib/ui/atoms/badge';
import config from '../config';
import { S3_BUCKET, S3_BUCKET_URLL } from './AwsUrl';

export const GLOBAL_DATE_FORMAT = 'MM/DD/YYYY';

export enum LoginType {
  ADMIN = 'ADMIN',
  PROVIDER = 'PRACTITIONER',
  ADMIN_LABEL = 'Admin',
  PROVIDER_LABEL = 'Provider',
}

export const SUPPORTED_AUTHORITIES = {
  PRACTITIONER: 'PRACTITIONER',
  ADMIN: 'ADMIN',
  MATCH_MAKER: 'MATCH_MAKER',
  CARE_NAVIGATOR: 'CARE_NAVIGATOR',
  BILLING_SUPPORT: 'BILLING_SUPPORT',
};

export const ProhibitiveOpts = [
  { value: '1', label: 'High Risk for Suicidal Ideation as per protocol' },
  { value: '2', label: 'High Risk for Suicidal Ideation as per protocol 1' },
  { value: '3', label: 'High Risk for Suicidal Ideation as per protocol 2' },
  { value: '4', label: 'High Risk for Suicidal Ideation as per protocol 3' },
];

export const CautionaryOpts = [
  { value: '1', label: 'Low or Moderate Risk for Homicidal Ideation as per protocol' },
  { value: '2', label: 'Low or Moderate Risk for Homicidal Ideation as per protocol 1' },
  { value: '3', label: 'Low or Moderate Risk for Homicidal Ideation as per protocol 2' },
  { value: '4', label: 'Low or Moderate Risk for Homicidal Ideation as per protocol 3' },
];

export const ReleaseOpts = [
  { value: '1', label: 'Select type' },
  { value: '2', label: 'Select type 1' },
  { value: '3', label: 'Select type 2' },
  { value: '4', label: 'Select type 3' },
];

export const ReactQuillEditorFormats = ['header'];

export const LabRequestOpts = [
  { value: '1', label: 'Complete Metabolic Panel' },
  { value: '2', label: 'Complete Metabolic Panel 1' },
  { value: '3', label: 'Complete Metabolic Panel 2' },
  { value: '4', label: 'Complete Metabolic Panel 3' },
];

export const ContactOpts = [
  { value: '1', label: 'Phone call' },
  { value: '2', label: 'Phone call 1' },
  { value: '3', label: 'Phone call 2' },
  { value: '4', label: 'Phone call 3' },
];

export const AppointmentsFilterDates = [
  { value: 7, label: 'Last 7 days' },
  { value: 30, label: 'Last 30 days' },
  { value: 90, label: 'Last 3 months' },
  { value: 180, label: 'Last 6 months' },
  { value: 360, label: 'Last 12 months' },
];

export const LoeAssignmentsFilterDates = [
  { value: 7, label: 'Last 7 days' },
  { value: 30, label: 'Last 30 days' },
  { value: 90, label: 'Last 3 months' },
  { value: 180, label: 'Last 6 months' },
  { value: 360, label: 'Last 12 months' },
];

export const AppointmentsFilterStatus = [
  { value: 'all', label: 'Show all' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'completed', label: 'Completed' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'noShow', label: 'No Show' },
];

export const AppointmentsReportsFilterStatus = [
  { value: '', label: 'Show all' },
  { value: 'PROPOSED', label: 'Scheduled' },
  { value: 'FULFILLED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'INACTIVE', label: 'Inactive' },
  { value: 'NO_SHOW', label: 'No Show' },
];
export const WorkingDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export enum ActivityActionType {
  CONTENT_BOOKMARKED = 'CONTENT_BOOKMARKED',
  CONTENT_REMOVED_BOOKMARKED = 'CONTENT_REMOVED_BOOKMARKED',
  CONTENT_MARKED_AS_COMPLETE = 'CONTENT_MARKED_AS_COMPLETE',
  CONVERSATION_ASSIGNED = 'CONVERSATION_ASSIGNED',
  CONVERSATION_COMPLETED = 'CONVERSATION_COMPLETED',
  DCT_STARTED = 'DCT_STARTED',
  DCT_COMPLETED = 'DCT_COMPLETED',
  APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
  APPOINTMENT_COMPLETED = 'APPOINTMENT_COMPLETED',
  TRANSCRIPT_READY = 'TRANSCRIPT_READY',
  APPOINTMENT_MARK_FOR_SIGNOFF = 'APPOINTMENT_MARK_FOR_SIGNOFF',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  JOINED_GROUP = 'JOINED_GROUP',
  LEFT_GROUP = 'LEFT_GROUP',
  JOINED_GROUP_SESSION = 'JOINED_GROUP_SESSION',
}

export const FilterNotesOptions = [
  { value: 'all', label: 'All Notes' },
  { value: CONTACT_NOTES_FLAGS.PROHIBITIVE, label: 'Prohibitive' },
  { value: CONTACT_NOTES_FLAGS.CAUTION, label: 'Caution' },
  { value: CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION, label: 'Release of information' },
  { value: CONTACT_NOTES_FLAGS.LAB_REQUEST, label: 'Lab request' },
  { value: CONTACT_NOTES_FLAGS.CONTACT, label: 'Contact' },
  { value: CONTACT_NOTES_FLAGS.GENERAL, label: 'General' },
  { value: CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE, label: 'Pharmacy Assistance' },
  { value: CONTACT_NOTES_FLAGS.REFERRAL_OUT, label: 'Referral Out' },
  { value: CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION, label: 'Prior Authorization' },
];

export const NoteIcons = {
  [CONTACT_NOTES_FLAGS.PROHIBITIVE]: 'close-circle-outlined',
  [CONTACT_NOTES_FLAGS.CAUTION]: 'flag-outlined',
  [CONTACT_NOTES_FLAGS.RELEASE_OF_INFORMATION]: 'info-outlined',
  [CONTACT_NOTES_FLAGS.LAB_REQUEST]: 'lab-bottle-outlined',
  [CONTACT_NOTES_FLAGS.CONTACT]: 'user-report-outlined',
  [CONTACT_NOTES_FLAGS.GENERAL]: 'report-outlined',
  [CONTACT_NOTES_FLAGS.PRIOR_AUTHORIZATION]: 'shield-outlined',
  [CONTACT_NOTES_FLAGS.PHARMACY_ASSISTANCE]: 'pharmacy-outlined',
  [CONTACT_NOTES_FLAGS.REFERRAL_OUT]: 'profile-arrow-left-outlined',
};

export const CONNECTIONS_TYPES = {
  PRACTITIONER: 'PRACTITIONER',
  PATIENT: 'PATIENT',
  CHAT_GROUP: 'CHAT_GROUP',
  MATCH_MAKER: 'MATCH_MAKER',
  CARE_TEAM: 'CARE_TEAM',
  CARE_NAVIGATOR: 'CARE_NAVIGATOR',
};

export const S3_BUCKET_URL = S3_BUCKET_URLL;
export const S3_CLIENT_OPTIONS = {
  region: 'us-east-1', // applicationConfig['s3.client.region'],
  accessKey: 'AKIAWJFF7VSY6E6OEMAQ', // applicationConfig['s3.client.accessKey'],
  secretKey: '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', // applicationConfig['s3.client.secretKey'],
  successActionStatus: 201, // applicationConfig['s3.client.successActionStatus'],
  bucket: S3_BUCKET,
};
export const frequencyConstants = {
  1: 'Once',
  2: 'Twice',
  3: 'Three',
};

export const dayConstants = {
  DAYS: 'daily',
  WEEKS: 'weekly',
  MONTHS: 'monthly',
};

export enum GENDER_TYPES {
  MALE = 'male',
  FEMALE = 'female',
}

export const OPENTOK_APIKEY = config.opentok.apiKey;

export const AVATAR_COLOR_ARRAY = ['#7a00e7', '#f78795', '#d97eff', '#2bb826', '#ff7f05'];

export const FEEDBACK_ISSUES_LIST = [
  'I experienced connection issues',
  'I experienced reminder issues',
  'I experienced communication issues',
];

export enum DomainTypesEnum {
  MEDIAL_HISTORY = 'Medical History',
  SOCIALS_DETERMINANTS = 'Social Determinants',
  LIFE_EVENTS = 'Life Events',
  SYMPTOMS = 'Symptoms',
  MEDICATIONS = 'Medications',
  SUBSTANCE_USE = 'Substance Use',
  DIAGNOSES = 'Diagnoses',
  SIDE_EFFECTS = 'Side Effects',
  COGNITIVE_ISSUES = 'Cognitive Issues',
}

export const EVALUATION_QUESTION_TYPES = {
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
  EDUCATION: 'education',
  EXERCISE: 'exercise',
  RATING_SCALE: 'rating-scale',
  // 'provider-message': 'Provider Message',
  // 'provider-prompt': 'Provider Prompt',
  // "single-select": "Single Select",
  // "multi-select": "Multi Select",
  // 'multi-dropdown': 'Multi dropdown',
  TEXT_INPUT: 'text-input',
  // "rating-scale": "Rating Scale",
  // education: "Education",
  // 'activity': 'Activity',
  // 'payment': 'Payment',
  // 'filtered-providers': 'Filtered Providers',
  // 'telehealth-services': 'Telehealth Services'
};

export enum DCT_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
}

export const TOAST_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
};

export const ConversationPriority = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
  CRITICAL: 'CRITICAL',
};

export const CONTENT_BLOCK_TYPES = {
  PROVIDER_MESSAGE: 'provider-message',
  PROVIDER_PROMPT: 'provider-prompt',
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
  // MULTI_DROPDOWN: 'multi-dropdown',
  TEXT_INPUT: 'text-input',
  RATING_SCALE: 'rating-scale',
  EDUCATION_CONTENT: 'education',
  EXERCISE_CONTENT: 'exercise',
  // ACTIVITY: 'activity',
  // PAYMENT: 'payment',
  FILTERED_PROVIDERS: 'filtered-providers',
  TELEHEALTH_SERVICES: 'telehealth-services',
};

export const CONTENT_BLOCK_TYPES_CONVERSATION = {
  PROVIDER_MESSAGE: 'provider-message',
  PROVIDER_PROMPT: 'provider-prompt',
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
  // MULTI_DROPDOWN: 'multi-dropdown',
  TEXT_INPUT: 'text-input',
  RATING_SCALE: 'rating-scale',
  EDUCATION_CONTENT: 'education',
  // ACTIVITY: 'activity',
  // PAYMENT: 'payment',
  FILTERED_PROVIDERS: 'filtered-providers',
  TELEHEALTH_SERVICES: 'telehealth-services',
};

export const CONTENT_BLOCK_TYPES_EVALUATION = {
  // 'provider-message': 'Provider Message',
  // 'provider-prompt': 'Provider Prompt',
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
  // 'multi-dropdown': 'Multi dropdown',
  TEXT_INPUT: 'text-input',
  RATING_SCALE: 'rating-scale',
  EDUCATION_CONTENT: 'education',
  EXERCISE: 'exercise',
  // 'activity': 'Activity',
  // 'payment': 'Payment',
  // 'filtered-providers': 'Filtered Providers',
  // 'telehealth-services': 'Telehealth Services'
};

export const CONTENT_BLOCK_CARD_TITLES = {
  CHOICES: 'Choices',
  VARIABLE: 'Variable',
  TYPE: 'Type',
  DISPLAY_LOGIC: 'Display Logic',
  ROUTING: 'Routing',
  VARIABLE_MAPPING: 'Variable Mapping',
  RELATED_INFORMATION: 'Related Information',
};

export enum CONVERSATION_STATUS {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}

export const CONVERSATION_TYPES = {
  CONVERSATION: 'CONVERSATION',
  DCT: 'DCT',
  EVALUATION: 'EVALUATION',
  EVALUATION_DCT: 'EVALUATION_DCT',
};

export const FLOW_CARD_TYPES = {
  CHOICES: 'choices',
  DISPLAY: 'display',
  ROUTING: 'routing',
};

export const ROUTING_OPTIONS = {
  NEXT: 'NEXT',
  SPECIFIC: 'SPECIFIC',
  END: 'END',
};

export const PROFILE_ELEMENT_DEF_TYPE = {
  SCORE_BASED: 'Score Based',
  TEXT_INPUT: 'Text Input',
  RATING_SCALE: 'Rating Scale',
  DATE: 'Date',
  DATE_TIME: 'Date Time',
  YES_NO: 'Yes/No',
  NUMERIC: 'Numeric',
  USER_DEFINED_VALUES: 'User Defined Values',
};

export const RATING_SCALE_OPTIONS = [3, 4, 5, 6, 7, 8, 9, 10, 11];

export const BUILDER_CONSTANTS = {
  ContentBlockTypes: {
    PROVIDER_MESSAGE: 'provider-message',
    PROVIDER_PROMPT: 'provider-prompt',
    SINGLE_SELECT: 'single-select',
    MULTI_SELECT: 'multi-select',
    // MULTI_DROPDOWN: 'multi-dropdown',
    TEXT_INPUT: 'text-input',
    RATING_SCALE: 'rating-scale',
    EDUCATION_CONTENT: 'education',
    EXERCISE_CONTENT: 'exercise',
    // ACTIVITY: 'activity',
    // PAYMENT: 'payment',
    FILTERED_PROVIDERS: 'filtered-providers',
    TELEHEALTH_SERVICES: 'telehealth-services',
  },
  BlockTypeNames: {
    'provider-message': 'Provider Message',
    'provider-prompt': 'Provider Prompt',
    'single-select': 'Single Select',
    'multi-select': 'Multi Select',
    // 'multi-dropdown': 'Multi dropdown',
    'text-input': 'Text Input',
    'rating-scale': 'Rating Scale',
    education: 'Education',
    exercise: 'exercise',
    // 'activity': 'Activity',
    // 'payment': 'Payment',
    'filtered-providers': 'Filtered Providers',
    'telehealth-services': 'Telehealth Services',
  },
  BlockTypeNamesInEvaluation: {
    // 'provider-message': 'Provider Message',
    // 'provider-prompt': 'Provider Prompt',
    'single-select': 'Single Select',
    'multi-select': 'Multi Select',
    // 'multi-dropdown': 'Multi dropdown',
    'text-input': 'Text Input',
    'rating-scale': 'Rating Scale',
    education: 'Education',
    exercise: 'Exercise',
    // 'activity': 'Activity',
    // 'payment': 'Payment',
    // 'filtered-providers': 'Filtered Providers',
    // 'telehealth-services': 'Telehealth Services'
  },
  DefaultOperators: [
    { value: 'equals', displayName: 'Equals' },
    { value: 'not-equals', displayName: 'Not Equals' },
    { value: 'contains', displayName: 'Contains' },
    { value: 'does-not-contains', displayName: 'Does not contains' },
  ],
  RoutingOptions: {
    SPECIFIC: 'Specific List of Content Blocks',
    NEXT: 'Next Block',
    END: 'End of Conversation/DCT',
  },
  StaticChatBotMessages: {
    'telehealth-services': 'Learn more about our clinical services',
    'filtered-providers': 'Get introduced to our Clinical Team',
    'provider-prompt': 'Get introduced to our Clinical Team',
  },
  AUTOMATION_RULES: {
    ACTIONS: {
      ASSIGN_CONVERSATION: 'ASSIGN CONVERSATION',
      ASSIGN_EDUCATION: 'ASSIGN EDUCATION',
      ASSIGN_PLAN_ITEM: 'ASSIGN PLAN ITEM',
      ASSIGN_EVALUATION: 'ASSIGN EVALUATION',
      RUN_AI_JOB: 'RUN AI JOB',
    },
    FILTERS: {
      RESPONSE_BASED_FILTER: 'RESPONSE_BASED_FILTER',
      USER_WHITE_LIST_FILTER: 'USER_WHITE_LIST_FILTER',
    },
    TIME_INTERVAL: {
      MIN: 'MIN',
      HOUR: 'HOUR',
      DAY: 'DAY',
    },
    EVENTS: {
      Names: {
        APPOINTMENT_REQUESTED: 'Appointment Requested',
        APPOINTMENT_SCHEDULED: 'Appointment Scheduled',
        APPOINTMENT_COMPLETED: 'Appointment Completed',
        APPOINTMENT_CANCELLED: 'Appointment Cancelled',
        APPOINTMENT_NO_SHOW: 'Appointment No Show',
        CONVERSATION_COMPLETED: 'CONVERSATION COMPLETED',
        TRANSCRIPT_READY: 'Transcript Ready',
        CONVERSATION_ASSIGNED: 'Conversation Assigned',
        DCT_COMPLETED: 'DCT COMPLETED',
        EVALUATION_COMPLETED: 'EVALUATION COMPLETED',
        PROFILE_ELEMENT_UPDATED: 'PROFILE ELEMENT UPDATED',
        GROUP_JOINED: 'Group Joined',
        GROUP_LEFT: 'Group Left',
        GROUP_SESSION_JOINED: 'Group Session Joined',
        GROUP_CONTRIBUTION_MADE: 'Group Contribution Made',
        PAYMENT_TYPE_SELECTED: 'Payment Type Selected',
        PAYMENT_MADE: 'Payment Made',
        CONTRIBUTION_SET: 'Contribution Set',
        CONTRIBUTION_CANCELLED: 'Contribution Cancelled',
        EDUCATION_MARKED_READ: 'Education Marked as Read',
        EDUCATION_VIEWED: 'Education Viewed',
        EDUCATION_SHARED: 'Education Shared',
        EDUCATION_ADDED_TO_FAVOURITES: 'Education Added To Favourites',
      },
      Values: {
        APPOINTMENT_REQUESTED: 'APPOINTMENT_REQUESTED',
        APPOINTMENT_SCHEDULED: 'APPOINTMENT_SCHEDULED',
        APPOINTMENT_COMPLETED: 'APPOINTMENT_COMPLETED',
        APPOINTMENT_CANCELLED: 'APPOINTMENT_CANCELLED',
        APPOINTMENT_NO_SHOW: 'APPOINTMENT_NO_SHOW',
        TRANSCRIPT_READY: 'TRANSCRIPT_READY',
        CONVERSATION_COMPLETED: 'CONVERSATION_COMPLETED',
        CONVERSATION_ASSIGNED: 'CONVERSATION_ASSIGNED',
        DCT_COMPLETED: 'DCT_COMPLETED',
        EVALUATION_COMPLETED: 'EVALUATION_COMPLETED',
        PROFILE_ELEMENT_UPDATED: 'PROFILE_ELEMENT_UPDATED',
        GROUP_JOINED: 'GROUP_JOINED',
        GROUP_LEFT: 'GROUP_LEFT',
        GROUP_SESSION_JOINED: 'GROUP_SESSION_JOINED',
        GROUP_CONTRIBUTION_MADE: 'GROUP_CONTRIBUTION_MADE',
        PAYMENT_TYPE_SELECTED: 'PAYMENT_TYPE_SELECTED',
        PAYMENT_MADE: 'PAYMENT_MADE',
        CONTRIBUTION_SET: 'CONTRIBUTION_SET',
        CONTRIBUTION_CANCELLED: 'CONTRIBUTION_CANCELLED',
        EDUCATION_MARKED_READ: 'EDUCATION_MARKED_READ',
        EDUCATION_VIEWED: 'EDUCATION_VIEWED',
        EDUCATION_SHARED: 'EDUCATION_SHARED',
        EDUCATION_ADDED_TO_FAVOURITES: 'EDUCATION_ADDED_TO_FAVOURITES',
        PLAN_ITEM_ASSIGNED: 'PLAN_ITEM_ASSIGNED',
        PLAN_ITEM_STARTED: 'PLAN_ITEM_STARTED',
        PLAN_ITEM_COMPLETED: 'PLAN_ITEM_COMPLETED',
      },
    },
    EVENTS_DROPDOWN_VALUES: [
      { label: 'Appointment Requested', value: 'APPOINTMENT_REQUESTED' },
      { label: 'Appointment Scheduled', value: 'APPOINTMENT_SCHEDULED' },
      { label: 'Appointment Completed', value: 'APPOINTMENT_COMPLETED' },
      { label: 'Appointment Cancelled', value: 'APPOINTMENT_CANCELLED' },
      { label: 'Appointment No Show', value: 'APPOINTMENT_NO_SHOW' },
      { label: 'Transcript Ready', value: 'TRANSCRIPT_READY' },
      { label: 'AI Job Completed', value: 'AI_JOB_COMPLETED' },
      { label: 'Conversation Completed', value: 'CONVERSATION_COMPLETED' },
      { label: 'Conversation Assigned', value: 'CONVERSATION_ASSIGNED' },
      { label: 'DCT Completed', value: 'DCT_COMPLETED' },
      { label: 'Profile Element Updated', value: 'PROFILE_ELEMENT_UPDATED' },
      { label: 'Plan Item Assigned', value: 'PLAN_ITEM_ASSIGNED' },
      { label: 'Plan Item Started', value: 'PLAN_ITEM_STARTED' },
      { label: 'Plan Item Completed', value: 'PLAN_ITEM_COMPLETED' },
      // { label: 'Evaluation Completed', value: 'EVALUATION_COMPLETED' },
      // { label: 'Appointment Cancelled', value: 'APPOINTMENT_CANCELLED' },
      // { label: 'Appointment No Show', value: 'APPOINTMENT_NO_SHOW' },
      // { label: 'Group Joined', value: 'GROUP_JOINED' },
      // { label: 'Group Left', value: 'GROUP_LEFT' },
      // { label: 'Group Session Joined', value: 'GROUP_SESSION_JOINED' },
      // { label: 'Group Contribution Made', value: 'GROUP_CONTRIBUTION_MADE' },
      // { label: 'Payment Type Selected', value: 'PAYMENT_TYPE_SELECTED' },
      // { label: 'Payment Made', value: 'PAYMENT_MADE' },
      // { label: 'Contribution Set', value: 'CONTRIBUTION_SET' },
      // { label: 'Contribution Cancelled', value: 'CONTRIBUTION_CANCELLED' },
      // { label: 'Education Marked as Read', value: 'EDUCATION_MARKED_READ' },
      // { label: 'Education Viewed', value: 'EDUCATION_VIEWED' },
      // { label: 'Education Shared', value: 'EDUCATION_SHARED' },
      // { label: 'Education Added To Favourites', value: 'EDUCATION_ADDED_TO_FAVOURITES' },
    ],
    AUTOMATION_ACCORDIAN_DEF: {
      EVENT: 'EVENT',
      FILTER: 'FILTER',
      ACTION: 'ACTION',
      // GOAL : "GOAL"
    },
  },
  TAGS: {
    TAGS_PRIORITY_LEVELS: {
      CRITICAL: 'Critical',
      NORMAL: 'Normal',
      HIGH: 'High',
      LOW: 'Low',
      NONE: 'None',
    },
    TAGS_COLOR: {
      RED: 'red',
      YELLOW: 'yellow',
      GREEN: 'green',
      PURPLE: 'purple',
    },
    TAGS_TYPES: {
      ALERTS: 'Alerts',
      LIFE_EVENTS: 'Life Events',
      SYMPTOMS: 'Symptoms',
      SIDE_EFFECTS: 'Side Effects',
      DIAGNOSIS: 'Diagnosis',
      PRESCRIPTIONS: 'Prescriptions',
      IMPROVEMENTS: 'Improvements',
      SUBSTANCE_USE: 'Substance Use',
      GENERAL: 'General',
    },
    TAGS_ASSIGNMENT_METHOD: {
      BASED_ON_PROFILE_ELEMENT: 'Profile element',
      BASED_ON_DCT: 'DCT',
    },
    BASED_ON_TAGS_ASSIGNMENT_METHOD: {
      BASED_ON_PROFILE_ELEMENT: 'BASED_ON_PROFILE_ELEMENT',
      BASED_ON_DCT: 'BASED_ON_DCT',
    },
  },
  SORT_DIRECTIONS: {
    asc: 'ASC',
    desc: 'DESC',
  },
  GENDER: {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
  },
};
export const RX_DRUG_INFO = {
  doseFrequency: 'Dose Frequency',
  highEndDose: 'High End Dose',
  highEndSupply: 'High End Supply',
  lowEndDose: 'Low End Dose',
  lowEndSupply: 'Low End Supply',
  medicationType: 'Medication Type',
  refillable: 'Refillable',
  relatedEducationContent: 'Related Education Content',
  supplyUnit: 'Supply Unit',
  dose: 'Dose',
  generic: 'Generic',
  medicationClass: 'Medication Class',
  supply: 'Supply',
};

export const DIAGNOSIS_INFO = {
  approach: 'Approach',
  relatedEducationContent: 'Related Education Content',
  summary: 'Summary',
  therapeuticPhilosophy: 'Therapeutic Philosophy',
};

export const HISTORY_INFO = {
  allergies: 'Allergies',
  criminalJusticeInvolvement: 'Criminal Justice Involvement',
  familyMedicationConditions: 'Family Medication Conditions',
  familyMentalHealthConditions: 'Family Mental Health Conditions',
  genderIdentity: 'Gender Identity',
  genderPronoun: 'Gender Pronoun',
  hasSupportNetwork: 'Has Support Network',
  medicalConditionsCurrentlyTreatedFor: 'Medical Conditions Currently Treated For',
  mentalHealthConditionsCurrentlyTreatedFor: 'Mental Health Conditions Currently Treated For',
  preferredPharmacy: 'Preferred Pharmacy',
  previousOverDose: 'Previous Over Dose',
  previousProblemsWithMedication: 'Previous Problems With Medication',
  previousSuicideAttempt: 'Previous Suicide Attempt',
  previouslyDiagnosed: 'Previously Diagnosed',
  previouslyDiagnosedMedicalConditions: 'Previously Diagnosed Medical Conditions',
  previouslyDiagnosedMentalHealthConditions: 'Previously DiagnosedMental Health Conditions',
  previouslyHospitalizedForPsychiatricCare: 'Previously Hospitalized For Psychiatric Care',
  previouslyReceivedSubstanceUseTreatment: 'Previously Received SubstanceUse Treatment',
  previouslySeenProvider: 'Previously Seen Provider',
  sexAssigned: 'Sex Assigned',
};

export const NOTES_INFO = {
  completedAt: 'Completed At',
  name: 'Name',
};

export const CONTACT_NOTES = 'CONTACT_NOTES';
export const REVAMP_INFO = {
  mindAndBodyQuestions: 'Mind And Body Questions',
  personalStatements: 'Personal Statements',
  planItems: 'Plan Items',
  reward: 'Reward',
  selectedActivities: 'Selected Activities',
};

export const SPECIFICATION = {
  relatedToMedicalCondition: 'Related To Medical Condition',
  relatedToMedication: 'Related To Medication',
  relatedToSubstanceUse: 'Related To SubstanceUse',
  relatedToWithdrawal: 'Related To With drawal',
};

export const SUBSTANCE_USE = {
  currentFrequencyOfUse: 'Current Frequency Of Use',
  howLongUsingThisLevel: 'How Long Using This Level',
  importanceLevel: 'Importance Level',
  lastUse: 'Last Use',
  methodOfUse: 'Method Of Use',
  unitOfUse: 'Unit Of Use',
};

export const SPECIFICATION_LIST = ['requireDiagnosisInfo', 'requireRxInfo', 'requireRxInfo'];

export const DOMAIN_TYPE_SPECIFICATIONS_VALUES = {
  rxDrugInfo: 'Rx Drug Info',
  diagnosisInfo: 'Diagnosis Info',
  historyInfo: 'History Info',
  notesInfo: 'Notes Info',
  revampInfo: 'Revamp Info',
  specification: 'Specification',
};

export enum CONTENTFUL_CONTENT_TYPES {
  EDUCATIONAL_CONTENT = 'educationalContent',
  TOPICS = 'topics',
}

export const DashboardTopSectionOrder = [
  'TOTAL',
  'BOOKED',
  'FULFILLED',
  'INACTIVE',
  'CANCELLED',
  'NO_SHOW',
  'PROPOSED',
  'PENDING',
];

export const DashboardAppointmentThisMonthSectionOrder = [
  'PROPOSED',
  'PENDING',
  'BOOKED',
  'FULFILLED',
  'CANCELLED',
  'INACTIVE',
];

export const DEFAULT_STATES_OPTIONS = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'District Of Columbia', value: 'District Of Columbia' },
  { label: 'Federated States Of Micronesia', value: 'Federated States Of Micronesia' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virgin Islands', value: 'Virgin Islands' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
];
export const SOCIAL_LOGIN_KEYS = {
  //  PROVIDER WEBAPP KEY CREATED FOR PROVIDER BY ADEEL CONSOLE FOR NOW
  // WEBAPP_AUTH_ID: '263851608573-hcc75nj835d1k83v2uuinhcr32bdcdik.apps.googleusercontent.com',
  WEBAPP_AUTH_ID: '217216560148-0hc3vfbf85uaug9b6q8utb6hqmv5hfr4.apps.googleusercontent.com',
};

export const MONTH_ABREVIATIONS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export enum REPORT_FILTERS {
  PROFILE_ELEMENT = 'profileElement',
  STATE = 'state',
  LEVEL_OF_ENGAGEMENT = 'levelOfEngagement',
  INSURANCE = 'insurance',
  PROVIDER = 'provider',
  REFERRAL_PARTNER = 'referralPartner',
}

export const getTimeZoneText = (timeZone: string) => {
  return timeZone?.replace('/', ' ').replace('_', ' ');
};

export const TIME_CONSTANT_MILISECONDS = {
  THIRTY_MINUTES: 1800000,
};

export const EVALUATION_STATUS = {
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  NOT_STARTED: 'Assigned',
  IN_PROGRESS: 'In progress',
  INCOMPLETE: 'Incomplete',
  NEEDS_REVIEW: 'For review',
};

export const evaluationStatusStyle = {
  REJECTED: badgeStyle.CRITICAL,
  COMPLETED: badgeStyle.RESOLVED,
  NOT_STARTED: badgeStyle.UNRELATED,
  IN_PROGRESS: badgeStyle.MISREPORTED,
  INCOMPLETE: badgeStyle.PRIMARY,
  NEEDS_REVIEW: badgeStyle.MEDIUM,
};
