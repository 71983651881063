import { useEffect, useState } from 'react';
import { NoteCategoryCard } from '@confidant-health/lib/ui/templates/note-category-card';
import { Box } from '@mui/material';

import { getMemberNotes } from 'services/member/member.service';
import NoteDrawer from 'pages/provider/member-detail/components/Notes/NoteDrawer';
import { INote } from '@confidant-health/lib/ui/templates/note-card';
import AddNoteDrawer from 'pages/provider/member-detail/components/Notes/AddNoteDrawer/AddNoteDrawer';
import { CONTACT_NOTES_FLAGS } from '@confidant-health/lib/constants/CommonConstants';
import { IGlyphs } from '@confidant-health/lib/icons';

type Props = {
  memberId: string;
  hideIcon?: boolean;
};

const RenderNotes = ({ memberId, hideIcon = true }: Props) => {
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState<INote | null>(null);
  const [isOpenAddNote, setIsOpenAddNote] = useState(false);
  const fetchNotes = async () => {
    try {
      const { data } = await getMemberNotes({ userId: memberId });
      const listSorted = data.patientContactNotes;
      let patientNotes = listSorted?.map(note => {
        return {
          ...note,
          icon: note?.flag === 'PROHIBITIVE' ? 'close-circle-outlined' : 'flag-outlined',
          title: note?.name,
          subTitle: note?.description,
          actions: [{ label: 'Learn more' }],
        };
      });
      patientNotes = patientNotes.filter(item => item.flag === 'PROHIBITIVE' || item.flag === 'CAUTION');
      const newArray = patientNotes.map(({ patientId, ...keepAttrs }) => {
        console.log(patientId);
        return keepAttrs;
      });
      setNotes(newArray);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    void fetchNotes();
  }, []);

  const fetchNotesCall = () => {
    getMemberNotes({ userId: memberId })
      .then(res => {
        if (res.data?.patientContactNotes) {
          console.log({ allNotes: res.data?.patientContactNotes });
          const prohibitiveNotes = res.data?.patientContactNotes
            ?.filter(note => note.type === CONTACT_NOTES_FLAGS.PROHIBITIVE)
            ?.map(note => ({
              ...note,
              icon: 'close-circle-outlined' as IGlyphs['glyph'],
              flag: CONTACT_NOTES_FLAGS.PROHIBITIVE,
              title: note?.name || '',
              subTitle: note?.description || '',
              actions: [
                {
                  label: 'Learn more',
                  onClick: () => setSelectedNote(note),
                },
              ],
            }));
          const cautionaryNotes = res.data?.patientContactNotes
            ?.filter(note => note.type === CONTACT_NOTES_FLAGS.CAUTION)
            ?.map(note => ({
              ...note,
              icon: 'flag-outlined' as IGlyphs['glyph'],
              flag: CONTACT_NOTES_FLAGS.CAUTION,
              title: note?.name || '',
              subTitle: note?.description || '',
              actions: [
                {
                  label: 'Learn more',
                  onClick: () => setSelectedNote(note),
                },
              ],
            }));
          const notesArray = [...prohibitiveNotes, ...cautionaryNotes];
          const newArray = notesArray.map(({ patientId, ...keepAttrs }) => {
            console.log(patientId);
            return keepAttrs;
          });
          setNotes(newArray);
        }
      })
      .catch(error => console.log({ error }));
  };

  const handleDeselectNote = () => {
    setSelectedNote(null);
  };

  const onCloseAddNote = () => {
    setIsOpenAddNote(false);
    setSelectedNote(null);
  };

  const onClickAddNewNote = () => {
    setIsOpenAddNote(true);
  };

  const onUpdateNote = (note: INote) => {
    setSelectedNote(note);
    onClickAddNewNote();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {notes?.map((note, index) => (
        <NoteCategoryCard key={index} {...note} onClick={() => setSelectedNote(note)} hideIcon={hideIcon} />
      ))}
      <AddNoteDrawer
        isOpen={isOpenAddNote}
        onClose={onCloseAddNote}
        fetchNotes={fetchNotesCall}
        note={selectedNote}
        memberId={memberId}
      />
      <NoteDrawer
        selectedNote={isOpenAddNote ? undefined : selectedNote}
        onClose={handleDeselectNote}
        onUpdateNote={onUpdateNote}
      />
    </Box>
  );
};

export default RenderNotes;
