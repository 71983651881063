import { Box } from '@mui/material';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { colors } from '@confidant-health/lib/colors';

import { IProvider } from 'redux/modules/profile/types';

import BoxFrame from 'components/v2/BoxFrame';
import Header from 'components/v2/Header';
import { HeadingFont, HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

type Props = {
  onEdit: () => void;
  providerDetails: IProvider;
};

const Approach = ({ onEdit, providerDetails }: Props) => {
  const { approach = 'N/A' } = providerDetails || {};

  return (
    <BoxFrame>
      <Box py={2.5} px={3} sx={{ '& button': { minWidth: 27 } }}>
        <Header
          label="Approach"
          fontFamily={HeadingFont.PRIMARY}
          fontVariant={HeadingLevel.SUB}
          fontWeight={HeadingWeight.SEMI_BOLD}
          actions={[
            {
              children: <Typography {...typography.body.s.bold}>Edit</Typography>,
              variant: btnType.TEXT,
              onClick: onEdit,
            },
          ]}
        />
      </Box>
      <Box p={3} borderTop={`1px solid ${colors.neutral100}`}>
        <Typography {...typography.body.m.medium}>{approach}</Typography>
      </Box>
    </BoxFrame>
  );
};

export default Approach;
