import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import { getAuth } from 'redux/modules/auth/selectors';
import { selectProviderServicesState1 } from 'redux/modules/schedule/selectors';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { stateActionCreators } from 'redux/modules/state';

import ProfileCard from '../education-experience/card';

import QuestionsDrawer from './questions-drawer';

const Services: FC = () => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const { services, isLoading } = useSelector(selectProviderServicesState1);

  useEffect(() => {
    dispatch(scheduleActionCreators.fetchProviderServices(meta.userId));
    dispatch(stateActionCreators.fetchCPTs());
  }, []);

  console.log(services);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="calc(100vh - 168px)"
      gap="344px"
    >
      <Box display="flex" flexDirection="column" gap="16px">
        {isLoading ? (
          <Box display="flex" justifyContent="center" sx={{ paddingY: 1 }}>
            <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
          </Box>
        ) : (
          <ProfileCard title="Services" content={{ services }} />
        )}
      </Box>
      <Box paddingBottom="32px">
        <QuestionsDrawer />
      </Box>
    </Box>
  );
};

export { Services };
