import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import { IIconButtonProps, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import {
  AppointmentStatus,
  AppointmentStatusBagde,
  AppointmentStatusDone,
  AppointmentStatusText,
  SignOffStatus,
} from '@confidant-health/lib/constants/CommonConstants';
import { theme } from '@confidant-health/lib/theme';
import { colors } from '@confidant-health/lib/colors';
import { Icons } from '@confidant-health/lib/icons';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';
import Heading from 'components/v2/Heading';
import { HeadingLevel } from 'components/v2/Heading/index.types';
import {
  EVALUATION_STATUS_V2,
  evaluationStatusStyleV2,
} from 'pages/provider/sessions/evaluations-v2/Evaluations.constants';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { IAssignedEvaluation } from 'redux/modules/conversation/types';
import { getAppointmentEvaluationProgress } from 'services/conversation/conversation.service';
import { getUserTimeZone } from 'utils/dayjs';
import { RECURRING_APPOINTMENT_FREQUENCIED } from 'pages/admin/appointments/add-schedule/AddSchedule.constants';

import AssignedEvaluationDrawer from '../../priorities/appointments/AssignedEvaluationDrawer';
import EvaluationDetail from '../../priorities/appointments/evaluation-detail';
import RecurringDrawer from './RecurringDrawer';

import { useStyles } from '../index.styles';

type Props = {
  appointment: IAppointment;
  actions?: IIconButtonProps[];
  start?: string;
  end?: string;
  viewDetail?: (appt: IAppointment | null) => void;
};

const AppointmentCard = ({ actions, appointment, viewDetail }: Props) => {
  const classes = useStyles();
  const providerSchedule = useSelector(selectProviderSchedule);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedEvaluation, setSelectedEvaluation] = useState<IAssignedEvaluation>(null);
  const [evaluations, setEvaluations] = useState<IAssignedEvaluation[]>([]);
  const [showAssignedEvaluations, setShowAssignedEvaluations] = useState(false);
  const [openRecurringDrawer, setOpenRecurringDrawer] = useState(false);
  const { appointmentId } = appointment || {};
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const timezone = useMemo(() => providerSchedule?.timezone || getUserTimeZone(), [providerSchedule]);

  useEffect(() => {
    if (appointmentId) {
      getAppointmentEvaluationProgress({ appointmentId })
        .then(evaluationProgressDetailList => {
          if (evaluationProgressDetailList?.status !== 200) {
            throw new Error('Whoops ! Something went wrong . Please try later');
          } else {
            const responseList = evaluationProgressDetailList?.data || [];
            setEvaluations(responseList);
          }
        })
        .catch(error => {
          console.log('Whoops ! Something went wrong . Please try later', error);
        });
    }
  }, []);

  const appointmentLabel = useMemo(() => {
    if (appointment?.instantAppointment) {
      return (
        <Typography {...typography.body.s.medium} color={colors.neutral600}>
          One-time appointment
        </Typography>
      );
    }
    const getFrequencyTextForRecurringSlots = (appt: any) => {
      if (appt?.startTime && appt?.recurringType) {
        switch (appt?.recurringType) {
          case RECURRING_APPOINTMENT_FREQUENCIED.DAILY:
            return `${appt?.isLastRecurringAppointment ? 'Last of every' : 'Every'} day at ${dayjs(
              appt?.startTime,
            ).format('hh:mm a')}`;
          case RECURRING_APPOINTMENT_FREQUENCIED.WEEKLY:
            return `${appt?.isLastRecurringAppointment ? 'Last of every' : 'Every'} ${
              weekDays[dayjs(appt?.startTime).get('day')]
            } at ${dayjs(appt?.startTime).format('hh:mm a')}`;
          case RECURRING_APPOINTMENT_FREQUENCIED.BI_WEEKLY:
            return `${appt?.isLastRecurringAppointment ? 'Last of every' : 'Every'} other week on ${
              weekDays[dayjs(appt?.startTime).get('day')]
            } at ${dayjs(appt?.startTime).format('hh:mm a')}`;
          case RECURRING_APPOINTMENT_FREQUENCIED.MONTHLY:
            return `${appt?.isLastRecurringAppointment ? 'Last of every' : 'Every'} month on ${dayjs(
              appt?.startTime,
            ).format('DD')} at ${dayjs(appt?.startTime).format('hh:mm a')}`;

          default:
            return '';
        }
      }
      return '-';
    };
    return appointment?.recurringType ? (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Icons glyph="recurring" color={colors.secondary500} />
        <Typography {...typography.body.s.medium} color={colors.neutral600}>
          {getFrequencyTextForRecurringSlots(appointment)}
        </Typography>
      </Box>
    ) : null;
  }, [appointment]);

  const onSelectAssignedEvaluation = (evaluation: IAssignedEvaluation) => {
    setSelectedEvaluation(evaluation);
  };

  const appointmentDate = dayjs(appointment?.startTime);
  const isToday = appointmentDate.isSame(dayjs(), 'day');
  const isDone = AppointmentStatusDone.includes(appointment?.status);

  const isWaitingSignOff =
    appointment?.requireSupervisorSignOff &&
    !!appointment?.signOffStatus &&
    appointment?.signOffStatus !== SignOffStatus.APPROVED;
  let finalStatus =
    // eslint-disable-next-line no-nested-ternary
    !isDone && appointment?.status !== AppointmentStatus.BOOKED && isWaitingSignOff
      ? appointment?.signOffStatus === SignOffStatus.REVIEW
        ? 'WAITING_REVIEW'
        : 'SIGN_OFF'
      : appointment?.status;

  if (appointment?.signOffStatus === SignOffStatus.DRAFTED) {
    finalStatus = 'SIGN_OFF';
  }
  const { status, signOffStatus, patientStatus, practitionerStatus } = appointment || {};
  let statusText = AppointmentStatusText[status];
  if (status === AppointmentStatus.FULFILLED && signOffStatus === SignOffStatus.DRAFTED) {
    statusText = 'Waiting for Sign off';
  }
  if (status === AppointmentStatus.PROPOSED || status === AppointmentStatus.PENDING) {
    if (patientStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Requested';
    } else if (practitionerStatus === AppointmentStatus.NEEDS_ACTION) {
      statusText = 'Pending';
    }
  }
  if (status === AppointmentStatus.BOOKED) {
    statusText = isToday ? 'Today' : 'Scheduled';
  }
  const memberName =
    appointment?.patientFirstName && appointment?.patientLastName
      ? `${appointment?.patientFirstName || ''} ${appointment?.patientLastName || ''}`
      : appointment?.participantName ?? 'No Name';
  return (
    <Box className={classes.apptCardContainer}>
      <Box
        display="flex"
        sx={{
          flexDirection: {
            md: 'row',
            xs: 'column',
          },
          gap: {
            xs: 3,
            md: 0,
          },
          justifyContent: {
            md: 'space-between',
          },
          alignItems: {
            md: 'center',
          },
          padding: 3,
          borderRadius: 1,
        }}
      >
        <Box
          display="flex"
          gap={3}
          sx={{
            width: {
              md: '70%',
              xs: '100%',
            },
            flexDirection: 'row',
            gap: { md: 2, xs: 0 },
            justifyContent: {
              md: 'flex-start',
              xs: 'space-between',
            },
            alignItems: { md: 'center' },
            cursor: isToday ? 'pointer' : 'default',
          }}
          onClick={() => viewDetail(appointment)}
        >
          <Heading variant={HeadingLevel.SUB}>{appointment?.serviceType || appointment?.serviceName}</Heading>
          <Badge
            style={
              appointment?.signOffStatus === 'REVIEW' && appointment?.status === 'FULFILLED'
                ? AppointmentStatusBagde.SIGN_OFF
                : AppointmentStatusBagde[finalStatus]
            }
            variant={badgeType.FILLED}
            className={classes.badge}
          >
            {appointment?.signOffStatus === 'REVIEW' && appointment?.status === 'FULFILLED' ? (
              'Waiting for Supervisor Sign Off'
            ) : statusText?.toLowerCase()?.includes('cancel') ? (
              <span className={classes.cancelledStatusText}>{statusText}</span>
            ) : (
              statusText
            )}
          </Badge>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              md: 'row',
              xs: 'column',
            },
            minWidth: {
              md: 294,
              xs: '100%',
            },
            gap: 2,
            justifyContent: 'flex-end',
          }}
        >
          {actions.map((action, index) => (
            <IconButton key={index} {...action}>
              {action.children}
            </IconButton>
          ))}
        </Box>
      </Box>
      <Box
        className={classes.borderTop}
        sx={{
          p: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'center', xs: 'flex-start' },
          flexDirection: { md: 'row', xs: 'column' },
          gap: { md: 0, xs: 2 },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-center' }}>
            <Typography {...typography.body.s.semibold}>
              {dayjs(appointment?.startTime).format('MMM DD')}
            </Typography>
            {isToday ? (
              <Typography {...typography.body.s.medium} color={colors.neutral600}>
                For {`${appointment?.serviceDuration} mins`}
              </Typography>
            ) : (
              <Typography {...typography.body.s.semibold} color={colors.neutral600}>
                {dayjs(appointment?.startTime).format('h:mm a')}
              </Typography>
            )}
          </Box>
          <ProfileInfo
            type="member"
            isProvider
            memberId={appointment?.participantId ?? ''}
            photo={appointment?.participantImage || ''}
            nickName={memberName}
            fullName={appointment?.patientUuid || ''}
            showProfilePhotoName
            profilePhotoName={memberName}
          />
        </Box>
        {appointmentLabel && (
          <Box sx={{ cursor: appointment?.instantAppointment ? 'default' : 'pointer' }}>
            {appointmentLabel}
          </Box>
        )}
      </Box>
      {!isTablet &&
        evaluations.map((evaluation, index) => {
          const evaluationStatus =
            evaluation.status === 'NOT_STARTED'
              ? 'Assigned'
              : evaluation.status === 'IN_PROGRESS'
              ? 'In progress'
              : evaluation.status === 'INCOMPLETE'
              ? 'Incomplete'
              : EVALUATION_STATUS_V2[evaluation.status];
          const statusBadgeStyle =
            evaluation.status === 'NOT_STARTED'
              ? badgeStyle.INACTIVE
              : evaluation.status === 'IN_PROGRESS' || evaluation.status === 'INCOMPLETE'
              ? badgeStyle.PRIMARY
              : evaluationStatusStyleV2[evaluation.status];
          const { totalCb, totalExercises, totalRequiredCb, forProviderCount, unanswered } =
            evaluation?.evaluationStats ?? {
              totalCb: 0,
              totalExercises: 0,
              totalRequiredCb: 0,
              forProviderCount: 0,
              unanswered: 0,
            };
          return (
            <Box
              key={index}
              className={classes.evaluation}
              onClick={() => onSelectAssignedEvaluation(evaluation)}
            >
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: 'inherit',
                    }}
                  >
                    <Box className={classes.text14}>{evaluation.name}</Box>
                    <Badge className={classes.badge} variant={badgeType.OUTLINED} style={statusBadgeStyle}>
                      {evaluationStatus}
                    </Badge>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', gap: 3, justifyContent: 'flex-end', width: '100%' }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Box className={classes.text14}>All</Box>
                      <Box>
                        <Badge
                          className={classes.badge}
                          variant={badgeType.OUTLINED}
                          style={badgeStyle.INACTIVE}
                        >
                          {totalCb}
                        </Badge>
                      </Box>
                    </Box>
                    {totalRequiredCb > 0 && (
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Box className={classes.text14}>Required</Box>
                        <Box>
                          <Badge
                            className={classes.badge}
                            variant={badgeType.OUTLINED}
                            style={badgeStyle.INACTIVE}
                          >
                            {totalRequiredCb}
                          </Badge>
                        </Box>
                      </Box>
                    )}
                    {unanswered > 0 &&
                      (evaluation.status === 'COMPLETED' || evaluation.status === 'IN_PROGRESS') && (
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <Box className={classes.text14}>Unanswered</Box>
                          <Box>
                            <Badge
                              className={classes.badge}
                              variant={badgeType.OUTLINED}
                              style={badgeStyle.INACTIVE}
                            >
                              {unanswered}
                            </Badge>
                          </Box>
                        </Box>
                      )}
                    {forProviderCount > 0 && (
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Box className={classes.text14}>For provider</Box>
                        <Box>
                          <Badge
                            className={classes.badge}
                            variant={badgeType.OUTLINED}
                            style={badgeStyle.INACTIVE}
                          >
                            {forProviderCount}
                          </Badge>
                        </Box>
                      </Box>
                    )}
                    {totalExercises > 0 && (
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Box className={classes.text14}>Exercises</Box>
                        <Box>
                          <Badge
                            className={classes.badge}
                            variant={badgeType.OUTLINED}
                            style={badgeStyle.INACTIVE}
                          >
                            {totalExercises}
                          </Badge>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      {showAssignedEvaluations && (
        <AssignedEvaluationDrawer
          onEvaluationSelected={onSelectAssignedEvaluation}
          evaluations={evaluations}
          open={showAssignedEvaluations}
          past={false}
          onClose={() => setShowAssignedEvaluations(false)}
        />
      )}
      {selectedEvaluation && (
        <EvaluationDetail
          isOpen={!!selectedEvaluation}
          onClose={() => setSelectedEvaluation(null)}
          evaluation={selectedEvaluation}
          memberId={appointment?.patientId}
          appointmentId={appointmentId}
          isView={false}
        />
      )}
      {openRecurringDrawer && (
        <RecurringDrawer
          open={openRecurringDrawer}
          onClose={() => setOpenRecurringDrawer(false)}
          startTime={appointment?.startTime}
          viewDetail={() => viewDetail(appointment)}
        />
      )}
    </Box>
  );
};

export default AppointmentCard;
