import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { IAction } from 'redux/store/types';
import { getUserAuthority } from 'utils/CommonUtils';
import { SUPPORTED_AUTHORITIES } from 'constants/CommonConstants';
import * as AuthService from 'services/auth/auth.service';
import { setAuthToken } from 'utils/HttpClient';
import {
  AUTHENTICATED_LINK_NAVIGATION,
  FORGOT_PASSWORD_OTP_VERIFICATION_FAILED,
  FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL,
  LOGIN_FAILED,
  LOGIN_SUCCESSFUL,
  PERFORM_LOGIN,
  REQUIRE_OTP,
  USER_LOGOUT,
  VERIFY_FORGOT_PASSWORD_OTP,
  VERIFY_OTP,
} from './actions';
import { profileActionCreators } from '../profile/actions';
import { AuthState } from './types';

function* fetchProfilesOnLogin(action: IAction) {
  const { payload } = action;
  const { authority, userId } = payload;
  if (
    authority === SUPPORTED_AUTHORITIES.ADMIN ||
    authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR ||
    authority === SUPPORTED_AUTHORITIES.BILLING_SUPPORT
  ) {
    yield put(profileActionCreators.fetchAdminProfile());
  } else {
    yield put(profileActionCreators.fetchProviderProfile(userId));
  }
}

function* loginHandler(action: IAction) {
  const { email, password, type } = action.payload;

  try {
    const response = yield call(AuthService.login, { emailOrPhone: email, password, type });

    if (response.status === 200) {
      const { data } = response;
      if (!data.requiresOTP) {
        setAuthToken(data.accessToken);
        const authority = yield call(getUserAuthority, data.accessToken);
        yield put(profileActionCreators.fetchProviders());
        const meta = {
          userId: data.userId,
          nickName: data.nickName,
          authority,
          isAdmin:
            authority === SUPPORTED_AUTHORITIES.ADMIN ||
            authority === SUPPORTED_AUTHORITIES.BILLING_SUPPORT ||
            authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR,
          mobileNumber: data?.mobileNumber,
          aliasId: data?.aliasId ?? null,
          aliasEmail: data?.aliasEmail ?? null,
        };
        localStorage.setItem('meta', JSON.stringify(meta));
        yield put({
          type: LOGIN_SUCCESSFUL,
          payload: meta,
        });
        console.log('hello');
      } else {
        const meta = {
          userId: data.userId,
          nickName: data.nickName,
          userEmail: email,
          mobileNumber: data?.mobileNumber,
          aliasId: data?.aliasId ?? null,
          aliasEmail: data?.aliasEmail ?? null,
        };
        localStorage.setItem('meta', JSON.stringify(meta));
        console.log('hello');
        yield put({
          type: REQUIRE_OTP,
        });
      }
    }
  } catch (e) {
    yield put({
      type: LOGIN_FAILED,
      payload: {
        message: e?.data?.errors?.[0]?.endUserMessage || e || 'Something went wrong !',
      },
    });
  }
}

function* verifyForgotPasswordOtpHandler(action: IAction) {
  const { emailOrPhone, code, codeType, userType } = action.payload;

  try {
    const response = yield call(AuthService.verifyConfirmationCode, {
      code,
      codeType,
      emailOrPhone,
      userType,
    });

    if (response.status === 200) {
      yield put({
        type: FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL,
      });
    } else {
      yield put({
        type: FORGOT_PASSWORD_OTP_VERIFICATION_FAILED,
        payload: {
          message: response,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FORGOT_PASSWORD_OTP_VERIFICATION_FAILED,
      payload: {
        message: e,
      },
    });
  }
}

function* otpHandler(action: IAction) {
  const { emailOrPhone, code, codeType, userType } = action.payload;

  try {
    const response = yield call(AuthService.verifyOtp, { code, codeType, emailOrPhone, userType });
    // const response = yield call(AuthService.login, { emailOrPhone: email, password, type });

    if (response.status === 200) {
      const { data } = response;
      if (!data.requiresOTP) {
        setAuthToken(data.accessToken);
        const authority = yield call(getUserAuthority, data.accessToken);

        if (
          authority === SUPPORTED_AUTHORITIES.ADMIN ||
          authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR ||
          authority === SUPPORTED_AUTHORITIES.BILLING_SUPPORT
        ) {
          yield put(profileActionCreators.fetchAdminProfile());
        } else {
          yield put(profileActionCreators.fetchProviderProfile(data.userId));
        }
        yield put(profileActionCreators.fetchProviders());

        const meta = {
          userId: data.userId,
          nickName: data.nickName,
          authority,
          isAdmin:
            authority === SUPPORTED_AUTHORITIES.ADMIN ||
            authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR ||
            authority === SUPPORTED_AUTHORITIES.BILLING_SUPPORT,
          aliasId: data?.aliasId ?? null,
          aliasEmail: data?.aliasEmail ?? null,
        };
        localStorage.setItem('meta', JSON.stringify(meta));
        yield put({
          type: LOGIN_SUCCESSFUL,
          payload: meta,
        });
      } else {
        yield put({
          type: REQUIRE_OTP,
        });
      }
    } else {
      // console.log('else block called');
      yield put({
        type: LOGIN_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: LOGIN_FAILED,
      payload: {
        message: e,
      },
    });
  }
}

function* logoutHandler(action, dispatch) {
  yield AuthService.logout();
  setTimeout(() => {
    dispatch({
      type: AUTHENTICATED_LINK_NAVIGATION,
      payload: '',
    });
  }, 10);
}

function* authSaga(store): IterableIterator<any> {
  yield takeLatest(PERFORM_LOGIN, loginHandler);
  yield takeLatest(VERIFY_OTP, otpHandler);
  yield takeLatest(VERIFY_FORGOT_PASSWORD_OTP, verifyForgotPasswordOtpHandler);
  yield takeLatest(USER_LOGOUT, function* (action) {
    yield fork(logoutHandler, action, store.dispatch);
  });
  yield takeLatest(LOGIN_SUCCESSFUL, fetchProfilesOnLogin);
  const auth: AuthState = yield select(state => state.auth);
  const { isAuthenticated, meta, isAdmin } = auth;
  if (isAuthenticated) {
    yield put({
      type: LOGIN_SUCCESSFUL,
      payload: meta,
    });
    if (isAdmin) {
      yield put(profileActionCreators.fetchAllProviders());
    }
  }
}

export default authSaga;
