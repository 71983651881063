import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { AppointmentCard } from '@confidant-health/lib/ui/templates/appointment-card';
import { DashboardBox } from '@confidant-health/lib/ui/templates/dashboard-box';
import { BaseLayout } from 'layouts/base';

import { conversationActionCreators } from 'redux/modules/conversation';
import { getAppointments } from 'services/appointment/appointment.service';
import { getUserTimeZone } from 'utils/dayjs';
import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import history from 'utils/history';

import {
  getAppointmentsMetrics,
  getFilteredAppointmentsMetrics,
} from 'services/elasticSearch/elasticSearch.service';

import {
  AppointmentsFilterDates,
  AppointmentsFilterStatus,
  DashboardAppointmentThisMonthSectionOrder,
  DashboardTopSectionOrder,
} from 'constants/CommonConstants';
import { SideUserDataT } from 'pages/provider/chats/Chats.types';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import ReportMonth from './components/report-month';
import Appointments from './components/appointments';
import Messages from './components/messages';
import { Upcoming } from './components/upcoming';
import { useStyles } from './Dashboard.styles';

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [, setIsLoading] = useState(false);
  const [appointmentMetrics, setAppointmentMetrics] = useState([]);
  const [appointmentThisMonthMetrics, setAppointmentThisMonthMetrics] = useState([]);
  const [filteredAppointmentMetrics, setFilteredAppointmentMetrics] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [chartDays, setChartDays] = useState<string[]>([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [nextAppointment, setNextAppointment] = useState(null);
  const { isAdmin, meta } = useSelector(getAuth);

  const { chats, userGroups } = useSelector(getProfile);
  const providerSchedule = useSelector(selectProviderSchedule);

  const getChartData = (chartDataList: any[]) => {
    const completedCount = [];
    const cancelledCount = [];
    const noShowCount = [];
    const days: string[] = [];

    chartDataList?.forEach(eachChartData => {
      days.push(eachChartData.dateInfo);
      let fulfilled = 0;
      let canceled = 0;
      let noshow = 0;

      eachChartData?.dayMetrics?.forEach(dayMetric => {
        if (dayMetric?.status === 'FULFILLED') {
          fulfilled = dayMetric?.count || 0;
        } else if (dayMetric?.status === 'CANCELLED') {
          canceled = dayMetric?.count || 0;
        } else if (dayMetric?.status === 'NO_SHOW') {
          noshow = dayMetric?.count || 0;
        }
      });
      completedCount.push(fulfilled);
      cancelledCount.push(canceled);
      noShowCount.push(noshow);
    });

    const chartSeriesList = [
      {
        name: 'Completed',
        data: completedCount,
      },
      {
        name: 'Cancelled',
        data: cancelledCount,
      },
      {
        name: 'No shows',
        data: noShowCount,
      },
    ];

    setChartDays(days);
    setChartSeries(chartSeriesList);
  };
  /**
   * @Name getAppointmentsMetricsOnScreen
   * @description This method is used get appointment Metrics from BE
   */
  const getAppointmentsMetricsOnScreen = () => {
    setIsLoading(true);
    getAppointmentsMetrics({ providerId: meta?.userId })
      .then(appointmentMetricsResponse => {
        if (appointmentMetricsResponse?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList = appointmentMetricsResponse?.data?.metrics || [];

          setAppointmentMetrics(
            responseList
              ?.map(metr => ({
                ...metr,
                order: DashboardTopSectionOrder.indexOf(metr?.status),
              }))
              ?.sort((a, b) => (a.order > b.order ? 1 : -1)),
          );
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  };

  /**
   * @Name getAppointmentsMetricsOnScreen
   * @description This method is used get filtered appointment Metrics from BE
   */
  const getFilteredAppointmentsMetricsOnScreen = (filterDetails: any, applyFilter: boolean) => {
    setIsLoading(true);

    getFilteredAppointmentsMetrics({
      days: filterDetails?.selectedNumberOfDays || 30,
      providerId: meta?.userId,
      status: filterDetails?.status,
    })
      .then(filteredAppointmentMetricsResponse => {
        if (filteredAppointmentMetricsResponse?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList = filteredAppointmentMetricsResponse?.data || [];
          if (applyFilter) {
            setFilteredAppointmentMetrics(responseList?.metrics);
          } else {
            console.log(responseList?.metrics);
            setAppointmentThisMonthMetrics(
              responseList?.metrics
                ?.filter(filterMetr => filterMetr?.status !== 'TOTAL')
                ?.map(metric => ({
                  ...metric,
                  order: DashboardAppointmentThisMonthSectionOrder?.indexOf(metric?.status),
                })),
            );
            setFilteredAppointmentMetrics(responseList?.metrics);
          }
          getChartData(responseList?.breakdown);
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
        setIsLoading(false);
      });
  };
  /**
   * @Name getAppointmentsMetricsOnScreen
   * @description This method is used get filtered appointment Metrics from BE
   */
  const getCurrentAppointments = () => {
    getAppointments({
      type: 'CURRENT',
      timezone: providerSchedule?.timezone || getUserTimeZone(),
      size: 30,
      page: 0,
    })
      .then(currentAppointmentResponse => {
        if (currentAppointmentResponse?.status !== 200) {
          throw new Error('Whoops ! Something went wrong . Please try later');
        } else {
          const responseList =
            currentAppointmentResponse?.data?.appointments?.filter(app => app.status === 'BOOKED') || [];
          if (responseList?.length > 0) {
            const nextAppt = responseList?.[0];
            setNextAppointment({
              ...nextAppt,
              thumbnail: nextAppt?.participantImage,
            });

            setUpcomingAppointments(
              responseList?.slice(0, 3).map(appt => ({
                ...appt,
                appointmentId: appt?.appointmentId,
                name: appt?.participantName,
                fullName: appt?.participantName,
                time: appt?.startTime,
                startTime: appt?.startTime,
                endTime: appt?.endTime,
                duration: appt?.serviceDuration,
                thumbnail: appt?.participantImage,
                status: appt?.status,
                description: appt?.serviceName,
              })),
            );
          }
        }
      })
      .catch(error => {
        console.log('Whoops ! Something went wrong . Please try later', error);
      });
  };

  /**
   * @function useEffect
   * @description This method is used to call get Appointments Metrics method first time
   */
  useEffect(() => {
    getAppointmentsMetricsOnScreen();
    getFilteredAppointmentsMetricsOnScreen(
      {
        selectedNumberOfDays: AppointmentsFilterDates?.[2]?.value,
        type: AppointmentsFilterStatus?.[0].value,
      },
      false,
    );

    if (!isAdmin) getCurrentAppointments();
  }, []);

  const onClickViewAllItems = () => {
    history.push(`/provider/appointments/current`);
  };

  const onViewAllMessages = () => {
    history.push(`/provider/chats`);
  };

  const onStartAppointmentClick = (appointment: any) => {
    history.push(`/provider/appointments/current/${appointment?.appointmentId}/session`);
  };

  /**
   * @Name getConversationsBySelectedChatType
   * @description This method is used to get conversation list by selected chat type
   */
  useEffect(() => {
    dispatch(conversationActionCreators.fetchChats({ userId: meta?.userId }));
  }, []);

  /**
   * @Name getConversationsBySelectedChatType
   * @description This method is used to get conversation list by chat type
   */
  const getConversationsBySelectedChatType = () => {
    const connections = [];
    [
      ...chats.members?.connections,
      ...chats.providers?.connections,
      ...chats.careTeam?.connections,
      ...userGroups,
    ].forEach(activeConnection => {
      connections.push({
        ...activeConnection,
        id: activeConnection?.connectionId,
        type: 'user',
        role: activeConnection?.type,
        messages: activeConnection?.messages,
        lastMessage: activeConnection?.lastMessage,
        lastUpdated: activeConnection?.lastModified,
        nickName: activeConnection?.nickName,
        fullName: activeConnection?.name,
        thumbnail: activeConnection?.profilePicture,
        channelUrl: activeConnection?.channelUrl,
        colorCode: activeConnection?.colorCode,
      } as SideUserDataT);
    });
    return connections;
  };

  const filteredConversations = getConversationsBySelectedChatType();
  const unReadMessages = filteredConversations
    .filter(connection => connection.lastMessageUnread)
    ?.map(connectionInner => ({
      id: connectionInner?.connectionId,
      text: connectionInner?.lastMessage,
      time: connectionInner?.lastModified,
      thumbnail: connectionInner?.thumbnail,
      name: connectionInner?.name,
    }));

  console.log({ appointmentThisMonthMetrics });

  const getTopSectionCardText = (label: string) => {
    if (label === 'TOTAL' || label === 'CANCELLED' || label === 'FULFILLED' || label === 'BOOKED') {
      return `${label} Appointments`;
    }
    if (label === 'NO_SHOW') {
      return 'NO SHOWS';
    }
    return label;
  };

  return (
    <BaseLayout>
      <div className={classes.root}>
        <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
          Dashboard
        </Heading>
        <div className={classes.boxes}>
          {appointmentMetrics?.map((box, index) => (
            <DashboardBox
              key={index}
              className=""
              label={getTopSectionCardText(box?.status)}
              value={box?.count}
              type={box?.status}
              badge={box?.percentage}
              isIncrease={box?.status === 'TOTAL' ? null : false}
              totalAppointments={appointmentMetrics[0]?.count}
              isDashBoardTopSection
            />
          ))}
        </div>
        {!isAdmin && upcomingAppointments?.length > 0 && (
          <div className={classes.upcoming}>
            <AppointmentCard
              appointment={nextAppointment}
              onStartSessionClick={onStartAppointmentClick}
              isNextAppointment
            />
            <Box sx={{ height: 32 }} />
            <Upcoming upcomingAppointments={upcomingAppointments} onClickViewAllItems={onClickViewAllItems} />
          </div>
        )}
        {unReadMessages?.length > 0 && (
          <div className={classes.messages}>
            <Messages title="New messages" items={unReadMessages} onViewAll={onViewAllMessages} />
            {/* <Messages title="New notifications" items={NotificaitonItemsMock} onViewAll={onViewAll} /> */}
          </div>
        )}
        {appointmentThisMonthMetrics?.length > 0 && <ReportMonth items={appointmentThisMonthMetrics} />}

        <Appointments
          reportMonth={filteredAppointmentMetrics}
          chartSeries={chartSeries}
          chartDays={chartDays}
          getFilteredAppointmentsMetricsOnScreen={getFilteredAppointmentsMetricsOnScreen}
        />
      </div>
    </BaseLayout>
  );
};

export { Dashboard };
