import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFormik } from 'formik';

import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  TextError,
} from '@confidant-health/lib/ui/atoms/typography';
import { Button, btnType } from '@confidant-health/lib/ui/atoms/button';
import { Textarea } from '@confidant-health/lib/ui/atoms/textarea';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';
import { RadioGroup, radioGroupType } from '@confidant-health/lib/ui/molecules/radio-group';
import { tz } from 'moment-timezone';

// schema
import { ProfileSchema } from './edit.schema';
// styles
import { useStyles } from './edit.styles';
// constants
import { roles, specialities } from './edit.constants';
import { IEditGeneralInfoProps, IProfile } from './edit.types';

const defaultValues = {
  name: '',
  role: '',
  description: '',
  specialities: [],
  signOffRole: '',
  activeInApp: false,
  timezone: '',
  twoFAEnabled: false,
  phoneNumber: '',
  designation: '',
};

const EditGeneralInfo: React.FC<IEditGeneralInfoProps> = ({
  isOpen,
  onClose,
  profile,
  isSaving,
  error,
  onSubmit,
}) => {
  const classes = useStyles();
  const { errors, values, handleChange, handleSubmit, touched, setFieldValue, ...rest } = useFormik({
    initialValues: { ...defaultValues },
    validationSchema: ProfileSchema,
    enableReinitialize: true,
    onSubmit: (payload: IProfile) => {
      const bodyRequest = {
        fullName: payload?.name,
        providerRole: payload?.role,
        active: payload?.activeInApp,
        bio: payload?.description,
        speciality: payload?.specialities,
        timezone: payload?.timezone,
      };
      onSubmit && onSubmit(bodyRequest);
    },
  });

  const [savingButton, setSavingButton] = useState(false);

  const onUpdateButtonClick = () => {
    setSavingButton(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setSavingButton(false);
    }
  }, [isOpen]);

  // Re enable update button when there are errors
  useEffect(() => {
    if (Object.keys(errors).length >= 1) {
      setSavingButton(false);
    }
  }, [errors, savingButton]);

  useEffect(() => {
    if (profile && !isSaving) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      Object.keys(profile).forEach(async key => {
        if (key === 'specialities') {
          const items = profile.specialities?.map(item => ({ title: item, value: item }));
          await setFieldValue(key, items || []);
        } else {
          await setFieldValue(key, profile[key]);
        }
      });
    }
  }, [profile, isSaving]);

  useEffect(() => {
    if (!isOpen) {
      rest.handleReset({ ...defaultValues });
      rest.setErrors({});
    }
  }, [isOpen]);

  const setTouched = (name: string) => async () => {
    await rest.setTouched({ ...touched, [name]: true });
  };

  const onChangeTags = async (_, val) => {
    await setFieldValue('specialities', val);
  };

  const renderLabel = (label: string) => (
    <Heading level={headingLevel.S} className={classes.label} weight={fontWeight.BOLD}>
      {label}
    </Heading>
  );

  return (
    <Drawer open={isOpen} onClose={onClose} variant={drawerType.NORMAL} className={classes.drawer}>
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Text className={classes.headerTitle}>Edit general information</Text>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.formContent}>
            {error && (
              <Box display="flex" justifyContent="center" paddingY={2}>
                <TextError errorMsg={error} />
              </Box>
            )}
            <Box className={classes.section}>
              {renderLabel('Name')}
              <Input
                value={values.name}
                name="name"
                placeholder="Enter name"
                onChange={handleChange}
                size={inputSize.M}
                onBlur={setTouched('name')}
                fullWidth
              />
              <TextError errorMsg={touched.name ? errors.name?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Role')}
              <Select
                options={roles}
                value={values.role}
                placeholder="Select a role"
                name="role"
                onChange={handleChange}
                variant={selectType.SECONDARY}
                fullWidth
              />
              <TextError errorMsg={touched.role ? errors.role?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Time zone')}
              <Select
                options={tz.names().map(item => ({ label: item, value: item }))}
                value={values.timezone}
                placeholder="Select a timezone"
                name="timezone"
                onChange={handleChange}
                displayEmpty
                emptyText="Select a timezone"
                variant={selectType.SECONDARY}
                fullWidth
              />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Specialities')}
              <Input
                value={values.specialities}
                name="specialities"
                variant={inputType.TAGS}
                placeholder="Enter an speciality"
                onChange={onChangeTags}
                options={specialities.filter(item => {
                  const foundItem = values.specialities?.find(itm => itm === item.value);
                  if (foundItem) {
                    return false;
                  }
                  return item;
                })}
                size={inputSize.M}
                onBlur={setTouched('specialities')}
                fullWidth
              />
              <TextError errorMsg={touched.specialities ? errors.specialities?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('About')}
              <Textarea
                value={values.description}
                name="description"
                onChange={handleChange}
                onBlur={setTouched('description')}
                minRows={5}
                placeholder="Enter about"
              />
              <TextError errorMsg={touched.description ? errors.description?.toString() : null} />
            </Box>
            <Box className={classes.section}>
              {renderLabel('Active in app?')}
              <RadioGroup
                value={String(values.activeInApp)}
                name="activeInApp"
                variant={radioGroupType.HORIZONTAL}
                onChange={handleChange}
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
              />
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Button variant={btnType.TEXT} onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onUpdateButtonClick();
                handleSubmit();
              }}
              disabled={savingButton}
            >
              {savingButton ? 'Saving...' : 'Update'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default EditGeneralInfo;
