import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { useDispatch, useSelector } from 'react-redux';
import { TodoVariant } from './TodosList/TodoList.types';
import TodoList from './TodosList';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { getConversation } from '../../../../redux/modules/conversation/selectors';
import { getAppointment } from '../../../../redux/modules/appointment/selectors';
import { formatDateAndStatus, getProfileFullName, getDesignation } from '../../../../utils';
import { getProfile } from '../../../../redux/modules/profile/selectors';
import history from '../../../../utils/history';
import { profileActionCreators } from '../../../../redux/modules/profile';
import { appointmentActionCreators } from '../../../../redux/modules/appointment';

export const CompletedTodos: FC = () => {
  const { meta } = useSelector(getAuth);
  const {
    chats: { channels },
  } = useSelector(getConversation);
  const {
    isLoading,
    appointments: appointmentsState = [],
    total: totalAppointments,
  } = useSelector(getAppointment);

  const [appointmentTableParams, setAppointmentTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 6 },
    sorter: { direction: 'asc', column: '' },
  });
  const [chatTableParams, setChatTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 6 },
    sorter: { direction: 'asc', column: '' },
  });
  const [evaluationTableParams, setEvaluationTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 6 },
    sorter: { direction: 'asc', column: '' },
  });
  const [notesTableParams, setNotesTableParams] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 6 },
    sorter: { direction: 'asc', column: '' },
  });
  const { todos, evaluationTodos, profile, notesTodos, todosStats } = useSelector(getProfile);
  const dispatch = useDispatch();

  const handleToDoClicked = type => id => {
    if (type === 'notes') {
      const todoItem = notesTodos.find(item => item.id === id);
      // if (todoItem.status === true) {
      //   // Completed Todo items should not navigate anywhere.
      //   return;
      // }
      const noteId = todoItem.contentIdentifier;
      const member = todoItem.memberId;
      history.push(`/provider/members/${member}?name=notes`, { noteId });
    }
    if (type === 'chat') {
      const todoItem = todos.find(item => item.id === id);
      // if (todoItem.status === true) {
      //   // Completed Todo items should not navigate anywhere.
      //   return;
      // }
      const channelUrl = todoItem.channelId;
      const messageId = todoItem.contentIdentifier;
      const chatChannel = channels.find(ch => ch.url === channelUrl);
      let tabType = 'providers';
      let chatId = channelUrl;
      if (chatChannel) {
        tabType = chatChannel.isDistinct ? 'providers' : 'groups';
        if (tabType === 'providers') {
          const otherMember = chatChannel.members.find(member => member.userId !== meta.userId);
          chatId = otherMember.userId;
        }
        if (todoItem.sourceSubtitle === 'Care Team') {
          history.push(`/provider/chats?userId=${todoItem?.memberId}&type=all&isDirectCall=true`);
        } else {
          history.push('/provider/chats', { tabType, chatId, messageId });
        }
      }
    }
    if (type === 'evaluation') {
      const todoItem = evaluationTodos.find(item => item.id === id);
      const { memberId, appointmentId, evaluationId, contentIdentifier } = todoItem;
      history.push(
        `/provider/members/${memberId}/appointments/${appointmentId}/evaluation?evaluationId=${evaluationId}&contentIdentifier=${contentIdentifier}`,
      );
    }
    if (type === 'sign-off') {
      const appointment = appointmentsState.find(item => item.appointmentId === id);
      if (appointment) {
        history.push(`/provider/appointments/current/${appointment.appointmentId}/session-signoff/v2`);
      }
    }
  };

  const handleToDoCheckUnCheck = checked => id => {
    dispatch(
      profileActionCreators.todoCompleted({
        data: {
          status: checked,
          id,
        },
        status: true,
        getAll: false,
      }),
    );
  };

  const payload = (type, pageNumber) => {
    return {
      assignedTo: profile.providerId,
      pageSize: 6,
      pageNumber,
      type,
      status: true,
      refresh: true,
    };
  };

  const multiselectFilters = appointmentTableParams.search?.multiSelectFilter;
  const fetchAppointments = () => {
    const queryParams: any = {
      orderBy: 'asc',
      page: appointmentTableParams.pagination.currentPage - 1,
      sortBy: 'startTime',
      type: 'PAST',
      size: 6,
      searchQuery: appointmentTableParams.search.searchKey || '',
      statuses: 'FULFILLED',
    };

    if (multiselectFilters && Object.keys(multiselectFilters)?.length > 0) {
      if (multiselectFilters.State?.length > 0) {
        queryParams.states = multiselectFilters.State?.toString() ?? '';
      }
      queryParams.vbcFlag = multiselectFilters?.VBC?.length > 0 || false;
    }
    dispatch(
      appointmentActionCreators.fetchAppointments({
        queryParams,
      }),
    );
  };

  useEffect(() => {
    fetchAppointments();
  }, [appointmentTableParams.pagination]);

  useEffect(() => {
    dispatch(profileActionCreators.fetchTodo(payload('CHAT', chatTableParams.pagination.currentPage - 1)));
  }, [chatTableParams.pagination]);

  useEffect(() => {
    dispatch(
      profileActionCreators.fetchEvaluationTodo(
        payload('EVALUATION', evaluationTableParams.pagination.currentPage - 1),
      ),
    );
  }, [evaluationTableParams.pagination]);

  useEffect(() => {
    dispatch(
      profileActionCreators.fetchNotesTodo(payload('NOTES', notesTableParams.pagination.currentPage - 1)),
    );
  }, [notesTableParams.pagination]);

  const filtersApplied = multiselectFilters && Object.keys(multiselectFilters)?.length > 0;
  const searchApplied = appointmentTableParams.search.searchKey !== '';
  const preventEmptyView = (filtersApplied || searchApplied) && appointmentsState.length === 0;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <TodoList
        open
        heading="Completed appointments"
        isLoading={isLoading}
        preventEmptyView={preventEmptyView}
        count={totalAppointments}
        setValue={setAppointmentTableParams}
        value={appointmentTableParams}
        items={appointmentsState.map(item => {
          return {
            text: item.serviceName,
            date: dayjs(item.startTime).format('MM/DD/YYYY - h:mm A'),
            dueDate: formatDateAndStatus(item.startTime),
            patientUuid: item.patientUuid,
            profileId: item.participantId,
            assignedByPhoto: item.participantImage,
            assignedByProfilePhotoText: `${item.patientFirstName || ''} ${item.patientLastName || ''}`,
            assignedByFullName: `${item.patientFirstName || ''} ${item.patientLastName || ''}`,
            appointmentId: item.appointmentId,
            participantId: item.participantId,
            isAi: false,
            id: '',
          };
        })}
        emptyTodosIcon="sign-off-todo"
        emptyTodosHeading="No completed appointments yet"
        emptyTodosText="Once you'll sign-off an appointment, you will see it here."
        onItemUnchecked={handleToDoCheckUnCheck(false)}
        onItemChecked={handleToDoCheckUnCheck(true)}
        onItemClicked={handleToDoClicked('sign-off')}
        isCompleted
        variant={TodoVariant.SECONDARY}
      />
      <TodoList
        open
        heading="Chat mentions"
        count={todosStats.completedTodoStats?.chatCount}
        setValue={setChatTableParams}
        value={chatTableParams}
        items={todos.map(item => {
          return {
            text: item.message,
            dueDate: formatDateAndStatus(item.dueDate),
            profileId: item.assignedBy,
            date: dayjs(item.createdDate).format('MM/DD/YYYY'),
            assignedByPhoto: item.assignedByAvatar,
            assignedByProfilePhotoText: item.practitionerFullName,
            checked: item.status,
            id: item.id,
            assignedByFullName: getProfileFullName(item),
            designation: getDesignation(item),
          };
        })}
        emptyTodosIcon="chat-mentions-todo"
        emptyTodosHeading="No chat mentions yet"
        emptyTodosText="Once you'll complete a chat mention to-do, you will see it here."
        onItemUnchecked={handleToDoCheckUnCheck(false)}
        onItemChecked={handleToDoCheckUnCheck(true)}
        onItemClicked={handleToDoClicked('chat')}
        isCompleted
      />
      <TodoList
        open
        heading="Evaluation mentions"
        count={todosStats.completedTodoStats?.evaluationsCount}
        setValue={setEvaluationTableParams}
        value={evaluationTableParams}
        items={evaluationTodos.map(item => {
          return {
            text: item.message,
            dueDate: formatDateAndStatus(item.dueDate),
            profileId: item.assignedBy,
            date: dayjs(item.createdDate).format('MM/DD/YYYY'),
            assignedByPhoto: item.assignedByAvatar,
            assignedByProfilePhotoText: item.practitionerFullName,
            checked: item.status,
            id: item.id,
            assignedByFullName: getProfileFullName(item),
            designation: getDesignation(item),
          };
        })}
        emptyTodosIcon="chat-mentions-todo"
        emptyTodosHeading="No evaluation mentions yet"
        emptyTodosText="Once you'll complete an evaluation mention to-do, you will see it here."
        onItemUnchecked={handleToDoCheckUnCheck(false)}
        onItemChecked={handleToDoCheckUnCheck(true)}
        onItemClicked={handleToDoClicked('evaluation')}
        isCompleted
      />
      <TodoList
        open
        heading="Case notes"
        count={todosStats.completedTodoStats?.notesCount}
        value={notesTableParams}
        setValue={setNotesTableParams}
        items={notesTodos.map(item => {
          return {
            text: item.message,
            dueDate: formatDateAndStatus(item.dueDate),
            profileId: item.assignedBy,
            date: dayjs(item.createdDate).format('MM/DD/YYYY'),
            assignedByPhoto: item.practitionerProfileImage,
            assignedByProfilePhotoText: item.memberFullName,
            checked: item.status,
            id: item.id,
            assignedByFullName: getProfileFullName(item),
            designation: getDesignation(item),
          };
        })}
        emptyTodosIcon="lottie-case-notes"
        emptyTodosHeading="No completed case note to-dos"
        emptyTodosText="Once you'll complete a case note to-do, you will see it here."
        onItemUnchecked={handleToDoCheckUnCheck(false)}
        onItemChecked={handleToDoCheckUnCheck(true)}
        onItemClicked={handleToDoClicked('notes')}
        isCompleted
      />
    </Box>
  );
};
